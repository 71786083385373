import { createSlice } from "@reduxjs/toolkit";
import { createUserApi, deleteUserApi, getUsersApi, getUsersByRoleAndProjectApi, updateUserApi } from "./userService";

const initialState = {
	userList: [],
	loading: "idle",
	currentRequestId: undefined,
	error: null,
};

export const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		// add your non-async reducers here
		logout: (state) => {
			state.userList = [];
			state.loading = "idle";
			state.currentRequestId = undefined;
			state.error = null;
		},
	},
	extraReducers: (builder) => {
		// extraReducers handles asynchronous requests, which is our main focus.
		// GET
		builder
			.addCase(getUsersApi.pending, (state, action) => {
				if (state.loading === "idle") {
					state.loading = "pending";
					state.currentRequestId = action.meta.requestId;
				}
			})
			.addCase(getUsersApi.fulfilled, (state, action) => {
				const { requestId } = action.meta;
				if (state.loading === "pending" && state.currentRequestId === requestId) {
					state.loading = "idle";
					state.userList.length = 0;
					state.userList.push(...action.payload);
					state.currentRequestId = undefined;
				}
			})
			.addCase(getUsersApi.rejected, (state, action) => {
				const { requestId } = action.meta;
				if (state.loading === "pending" && state.currentRequestId === requestId) {
					state.loading = "idle";
					// state.error = action.error;
					state.currentRequestId = undefined;
					if (action.payload) {
						state.error = action.payload.errorMessage;
					} else {
						state.error = action.error.message;
					}
				}
			});

		// POST
		builder
			.addCase(createUserApi.pending, (state, action) => {
				if (state.loading === "idle") {
					state.loading = "pending";
					state.currentRequestId = action.meta.requestId;
				}
			})
			.addCase(createUserApi.fulfilled, (state, action) => {
				const { requestId } = action.meta;
				if (state.loading === "pending" && state.currentRequestId === requestId) {
					state.loading = "idle";
					state.userList.unshift(action.payload);
					state.currentRequestId = undefined;
				}
			})
			.addCase(createUserApi.rejected, (state, action) => {
				const { requestId } = action.meta;
				if (state.loading === "pending" && state.currentRequestId === requestId) {
					state.loading = "idle";
					// state.error = action.error;
					state.currentRequestId = undefined;
					if (action.payload) {
						state.error = action.payload.errorMessage;
					} else {
						state.error = action.error.message;
					}
				}
			});

		// PUT
		builder
			.addCase(updateUserApi.pending, (state, action) => {
				if (state.loading === "idle") {
					state.loading = "pending";
					state.currentRequestId = action.meta.requestId;
				}
			})
			.addCase(updateUserApi.fulfilled, (state, action) => {
				const { requestId } = action.meta;
				if (state.loading === "pending" && state.currentRequestId === requestId) {
					const updateItem = action.payload;
					const index = state.userList.findIndex((item) => item.id === updateItem.id);
					if (index > -1) {
						state.userList[index] = updateItem;
					}
					state.loading = "idle";
					state.currentRequestId = undefined;
				}
			})
			.addCase(updateUserApi.rejected, (state, action) => {
				const { requestId } = action.meta;
				if (state.loading === "pending" && state.currentRequestId === requestId) {
					state.loading = "idle";
					// state.error = action.error;
					state.currentRequestId = undefined;
					if (action.payload) {
						state.error = action.payload.errorMessage;
					} else {
						state.error = action.error.message;
					}
				}
			});

		// DELETE
		builder
			.addCase(deleteUserApi.pending, (state, action) => {
				if (state.loading === "idle") {
					state.loading = "pending";
					state.currentRequestId = action.meta.requestId;
				}
			})
			.addCase(deleteUserApi.fulfilled, (state, action) => {
				const { requestId } = action.meta;
				if (state.loading === "pending" && state.currentRequestId === requestId) {
					const index = state.userList.findIndex((item) => item.id === action.payload);
					if (index > -1) {
						state.userList.splice(index, 1);
					}
					state.loading = "idle";
					state.currentRequestId = undefined;
				}
			})
			.addCase(deleteUserApi.rejected, (state, action) => {
				const { requestId } = action.meta;
				if (state.loading === "pending" && state.currentRequestId === requestId) {
					state.loading = "idle";
					// state.error = action.error;
					state.currentRequestId = undefined;
					if (action.payload) {
						state.error = action.payload.errorMessage;
					} else {
						state.error = action.error.message;
					}
				}
			});

		// GET USERS BY ROLE AND PROJECT
		builder
			.addCase(getUsersByRoleAndProjectApi.pending, (state, action) => {
				if (state.loading === "idle") {
					state.loading = "pending";
					state.currentRequestId = action.meta.requestId;
				}
			})
			.addCase(getUsersByRoleAndProjectApi.fulfilled, (state, action) => {
				const { requestId } = action.meta;
				if (state.loading === "pending" && state.currentRequestId === requestId) {
					state.loading = "idle";
					state.filteredUserList = action.payload;
					state.currentRequestId = undefined;
				}
			})
			.addCase(getUsersByRoleAndProjectApi.rejected, (state, action) => {
				const { requestId } = action.meta;
				if (state.loading === "pending" && state.currentRequestId === requestId) {
					state.loading = "idle";
					state.currentRequestId = undefined;
					state.error = action.payload ? action.payload.errorMessage : action.error.message;
				}
			});
	},
});

// Action creators are generated for each case reducer function
export const { logout } = userSlice.actions;

export default userSlice.reducer;
