import { SmileOutlined } from "@ant-design/icons";
import { Button, Table, Tooltip } from "antd";
import _ from "lodash";
import { FaEdit } from "react-icons/fa";
import { IoMdTrash } from "react-icons/io";
import { toast } from "react-toastify";

const ProviderUserTable = ({
	providerList,
	searchQuery,
	setEditStatus,
	setEditData,
	setDeleteStatus,
	setDeleteData,
	setCurrentPage,
	setPageSize,
	currentPage,
	pageSize,
	totalUsers,
}) => {
	const editPress = (id) => {
		if (!id) return toast.warning("Invalid id!");
		const findRole = _.find(providerList, { id: id });
		if (!findRole) return toast.warning("Invalid selected data!");
		setEditStatus(true);
		setEditData(findRole);
	};

	const deletePress = (id) => {
		if (!id) return toast.warning("Invalid id!");
		const findRole = _.find(providerList, { id: id });
		if (!findRole) return toast.warning("Invalid selected data!");
		setDeleteStatus(true);
		setDeleteData(findRole);
	};

	const columns = [
		{
			title: "User Name",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "Email",
			dataIndex: "email",
			key: "email",
		},
		{
			title: "User Role",
			dataIndex: "access",
			key: "access",
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
		},
		{
			title: "Account Lock Status",
			dataIndex: "account",
			key: "account",
		},
		{
			title: "Time Zone",
			dataIndex: "timeZone",
			key: "timeZone",
		},
		{
			title: "Actions Menu",
			key: "action",
			align: "center",
			render: (_, record) => (
				<div className="flex w-full items-center justify-center">
					<Tooltip title="Modify User Information">
						<Button type="button" onClick={() => editPress(record.id)}>
							<FaEdit className="cursor-pointer text-blue-500 text-lg" />
						</Button>
					</Tooltip>
					<Tooltip title="Delete">
						<Button type="button" className="ml-2" onClick={() => deletePress(record.id)}>
							<IoMdTrash className="cursor-pointer text-red-500 text-xl" />
						</Button>
					</Tooltip>
				</div>
			),
		},
	];

	// const data = providerList?.filter(item =>
	//   (item?.firstName?.toString()?.toLowerCase()?.indexOf(searchQuery?.toString()?.toLowerCase()) !== -1) ||
	//   (item?.lastName?.toString()?.toLowerCase()?.indexOf(searchQuery?.toString()?.toLowerCase()) !== -1) ||
	//   (item?.email?.toString()?.toLowerCase()?.indexOf(searchQuery?.toString()?.toLowerCase()) !== -1)
	// ).map(({ id, firstName, lastName, email, password, access, status, timeZone, accountLocked }) => ({
	//   "key": id, "id": id, "name": `${firstName} ${lastName}`, "email": email, "password": password, "access": access === "VIEW" ? "Visitor Viewer" : "Report Manager", "status": status, "timeZone": timeZone?.name, "account": accountLocked ? "LOCKED" : "ACTIVE"
	// }));

	const data = providerList
		?.filter((item) => {
			const fullName = `${item.firstName} ${item.lastName}`.toLowerCase();
			const email = item?.email?.toLowerCase();
			const access = item?.access?.toLowerCase();
			const search = searchQuery?.toLowerCase();
			return (
				fullName.includes(search) || // Match full name
				email.includes(search) || // Match email
				(access === "view" && /vis|visi|visit|visito|viewer|visitor|view/i.test(search)) || // Match Visitor Viewer
				(access === "download" &&
					/re|rep|repor|do|dow|down|downl|downlo|downloa|ma|mana|manag|manage|manager|report|download/i.test(search))
			);
		})
		.map(({ id, firstName, lastName, email, password, access, status, timeZone, accountLocked }) => ({
			key: id,
			id: id,
			name: `${firstName} ${lastName}`,
			email: email,
			password: password,
			access: access === "VIEW" ? "Visitor Viewer" : "Report Manager",
			status: status,
			timeZone: timeZone?.name,
			account: accountLocked ? "LOCKED" : "ACTIVE",
		}));

	const handleTableChange = (pagination, filters) => {
		// setFilteredProject(filters.project ? filters.project[0] : null);
		// setFilteredBatchNo(filters.batchNo ? filters.batchNo[0] : null);
		setCurrentPage(pagination.current);
		setPageSize(pagination.pageSize);
	};

	return (
		<div className="mb-3 rounded-md border shadow-md">
			<div className="custom-ant-table-design no-scrollbar">
				<Table
					columns={columns}
					dataSource={data}
					pagination={{
						current: currentPage,
						pageSize: pageSize,
						total: totalUsers,
					}}
					onChange={handleTableChange}
					size="small"
					locale={{
						emptyText: (
							<div style={{ textAlign: "center" }}>
								<SmileOutlined style={{ fontSize: 20 }} />
								<p>Data Not Found</p>
							</div>
						),
					}}
					scroll={{ y: 400 }}
				/>
			</div>
		</div>
	);
};

export default ProviderUserTable;
