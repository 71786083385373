import { createAsyncThunk } from "@reduxjs/toolkit";
import { addUser, deleteUser, getUsers, getUsersByRoleAndProject, updateUser } from "../../api/user/user";

export const getUsersApi = createAsyncThunk("user/get", async (data, { rejectWithValue }) => {
	try {
		const response = await getUsers();
		return response.data;
	} catch (error) {
		if (!error.response) {
			throw error;
		}
		return rejectWithValue(error.response.data);
	}
});

export const createUserApi = createAsyncThunk("user/create", async (data, { rejectWithValue }) => {
	try {
		const response = await addUser(data);
		return response.data;
	} catch (error) {
		if (!error.response) {
			throw error;
		}
		return rejectWithValue(error.response.data);
	}
});

export const updateUserApi = createAsyncThunk("user/update", async (data, { rejectWithValue }) => {
	const { updateId, items } = data;
	try {
		const response = await updateUser(updateId, items);
		return response.data;
	} catch (error) {
		if (!error.response) {
			throw error;
		}
		return rejectWithValue(error.response.data);
	}
});

export const deleteUserApi = createAsyncThunk("user/delete", async (deleteId, { rejectWithValue }) => {
	try {
		const { status } = await deleteUser(deleteId);
		if (status === 202) return deleteId;
	} catch (error) {
		if (!error.response) {
			throw error;
		}
		return rejectWithValue(error.response.data);
	}
});

// New Thunk for fetching users by role and project
export const getUsersByRoleAndProjectApi = createAsyncThunk(
	"user/getByRoleAndProject",
	async ({ roleName, projectId }, { rejectWithValue }) => {
		try {
			const response = await getUsersByRoleAndProject(roleName, projectId);
			return response.data;
		} catch (error) {
			if (!error.response) {
				throw error;
			}
			return rejectWithValue(error.response.data);
		}
	},
);
