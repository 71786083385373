import { Col, Row } from "antd";
import { Button, DatePicker, Form, Input, Select } from "antd";
import dayjs from "dayjs";
import _ from "lodash";
import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { InsuranceCardDelivered, Multiplan, PortalAvailability, States } from "../../assets/DispCodes";
import { updateChartApi } from "../../redux/chart/chartService";
import { setLoading } from "../../redux/loading/loadingSlice";
import { axiosSubmitErrorHandling, getIpAddress, isEmptyObject, isObject, trimObjValues } from "../../utils/utility";

const { TextArea } = Input;
const dateFormat = "MM/DD/YYYY";

const InsuranceDetails = ({
	chartDetail,
	setInsuranceDetailsSaved,
	setActiveSubTab,
	defaultSize,
	formRef,
	charts,
	setCharts,
	setChartDetailView,
	documentViewer,
	setEditPatientData,
	responsiveLayout,
}) => {
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const [showInsuranceDateField, setShowInsuranceDateField] = useState(false);
	const [insuranceDate, setInsuranceDate] = useState([]); // Initialize insuranceDate as an array

	const role = useSelector((state) => state.userDetails.role);
	const userId = useSelector((state) => state.userDetails.userId);
	const EditAccess = role === "PROVIDER" || role === "USER" || role === "CODING" || role === "GUEST" ? false : true;

	const oldChartDetailView = useSelector((state) => state.chartViewManagement.chartDetailView);

	const colProps = documentViewer
		? { xl: 24, lg: 12, md: 12, sm: 12, xs: 12 } // Adjust layout for documentViewer
		: responsiveLayout
			? { span: 24 } // Full width for dataCapture
			: { xl: 8, lg: 8, md: 8, sm: 12, xs: 24 }; // Regular layout

	const containerClass = responsiveLayout ? "my-2 overflow-auto max-h-[70vh] no-scrollbar" : "";

	useEffect(() => {
		const insuranceInitialValue = {
			/* Insurance Detail Initial Values */
			portalAvailability: chartDetail?.portalAvailability,
			insuranceCard: chartDetail?.insuranceCard,
			multiplan: chartDetail?.multiplan,
			icPayerID: chartDetail?.icPayerID,
			primaryInsurance: chartDetail?.primaryInsurance,
			primaryInsuranceState: chartDetail?.primaryInsuranceState,
			primaryInsurancePlan: chartDetail?.primaryInsurancePlan,
			primaryInsuranceGroupNumber: chartDetail?.primaryInsuranceGroupNumber,
			secondaryInsurance: chartDetail?.secondaryInsurance,
			secondaryInsuranceState: chartDetail?.secondaryInsuranceState,
			secondaryInsurancePlan: chartDetail?.secondaryInsurancePlan,
			secondaryInsuranceNumber: chartDetail?.secondaryInsuranceNumber,
			secondaryInsuranceGroupNumber: chartDetail?.secondaryInsuranceGroupNumber,
		};

		// if (chartDetail?.insuranceCardReceivedOn && chartDetail?.insuranceCardReceivedOn !== "Invalid Date" && chartDetail?.insuranceCardReceivedOn !== "U/A" && chartDetail?.insuranceCardReceivedOn !== "N/A") {
		//   const putInsuranceDate = chartDetail?.insuranceCardReceivedOn ? formattedDate(chartDetail?.insuranceCardReceivedOn) : ''
		//   setInsuranceDate(putInsuranceDate);
		//   form.setFieldsValue({
		//     insuranceCardReceivedOn: putInsuranceDate,
		//   });
		//   setShowInsuranceDateField(true);
		// }

		if (
			chartDetail?.insuranceCardReceivedOn === "Invalid Date" ||
			chartDetail?.insuranceCardReceivedOn === "U/A" ||
			chartDetail?.insuranceCardReceivedOn === "N/A"
		) {
			setShowInsuranceDateField(false);
			setInsuranceDate(null);
		}

		if (
			chartDetail?.insuranceCardReceivedOn &&
			chartDetail?.insuranceCardReceivedOn !== "Invalid Date" &&
			chartDetail?.insuranceCardReceivedOn !== "U/A" &&
			chartDetail?.insuranceCardReceivedOn !== "N/A"
		) {
			// Ensure chartDetail.insuranceCardReceivedOn is split into an array and parsed correctly
			const dateArray = chartDetail.insuranceCardReceivedOn.split(",").map((date) => dayjs(date, dateFormat));
			setInsuranceDate(dateArray);
			form.setFieldsValue({
				insuranceCardReceivedOn: dateArray,
			});

			setShowInsuranceDateField(true);
		}

		form.setFieldsValue(insuranceInitialValue);
	}, [chartDetail, form]);

	//Save Insurance Information
	const onFinish = async (values) => {
		try {
			const values = await form.validateFields();
			const items = { ...values };
			items.userRole = role;

			if (insuranceDate === "Invalid Date") {
				items.insuranceCardReceivedOn = "U/A";
			}

			if (!showInsuranceDateField && !insuranceDate) {
				items.insuranceCardReceivedOn = "N/A";
			}

			if (insuranceDate && insuranceDate !== "Invalid Date") {
				if (insuranceDate && typeof insuranceDate === "object" && !Array.isArray(insuranceDate)) {
					// Handle case where insuranceDate is an object
					items.insuranceCardReceivedOn = dayjs(insuranceDate).format("MM/DD/YYYY");
				} else if (Array.isArray(insuranceDate)) {
					const conDate = insuranceDate
						.filter((date) => date !== null) // Filter out null values
						.map((date) => dayjs(date).format("MM/DD/YYYY")) // Format each date
						.join(","); // Join into a single string

					items.insuranceCardReceivedOn = conDate;
				} else {
					items.insuranceCardReceivedOn = insuranceDate;
				}
			}

			/* check is object */
			if (!isObject(items)) {
				return toast.warning("This is not object data!");
			}

			/* check is empty object */
			if (isEmptyObject(items)) {
				return toast.warning("Empty object cannot accept!");
			}

			/* trim values */
			trimObjValues(items);
			items.userId = userId;
			const ipAddress = await getIpAddress();
			items.ipAddress = ipAddress;
			dispatch(setLoading(true));
			setActiveSubTab("3");

			const updateId = chartDetail.id || chartDetail?._id;
			dispatch(setInsuranceDetailsSaved(true));
			const res = await dispatch(updateChartApi({ updateId, items })).unwrap();
			const rowIndex = _.findIndex(charts, { id: updateId });

			if (rowIndex > -1) {
				const updatedCharts = [...charts];
				const updatedItem = { ...updatedCharts[rowIndex], ...res };
				updatedCharts[rowIndex] = updatedItem;
				setCharts(updatedCharts);
			}
			if (typeof setEditPatientData === "function") {
				setEditPatientData((prevData) => ({
					...prevData,
					...res, // Update editPatientData with the latest data from the API
				}));
			}

			if (typeof setChartDetailView === "function") {
				// Access the current ChartDetailView from the Redux store

				// Merge the old data with the new data
				const updatedData = {
					...oldChartDetailView.data, // Existing data
					...res, // New response data
				};

				// Dispatch the updated state
				dispatch(
					setChartDetailView({
						selected: true,
						data: updatedData, // Merged data
					}),
				);
			}
			dispatch(setLoading(false));
			toast.success("Insurance details updated successfully!");
		} catch (error) {
			dispatch(setLoading(false));
			return axiosSubmitErrorHandling(error);
		}
	};
	const disabledDate = (current) => {
		// Get the current date and year
		const currentDate = dayjs();
		const currentYear = currentDate.year();

		// Disable dates after the current year or after the current date
		return current && (dayjs(current).year() > currentYear || dayjs(current).isAfter(currentDate, "day"));
	};
	const onFinishFailed = (errorInfo) => {};

	const onChange = (value) => {};

	const onSearch = (value) => {};

	const stateFilter = (input, option) => {
		const inputValue = input.trim().toLowerCase();
		const optionValue = option?.value.toLowerCase();
		const optionLabel = option?.label.toLowerCase();

		return (
			(inputValue.length === 2 && optionValue === inputValue) ||
			(inputValue.length > 2 && optionLabel.includes(inputValue))
		);
	};
	const filterOption = (input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

	const onChangeDatePicker = (dateArray) => {
		if (dateArray) {
			const formattedDates = dateArray.map((date) => dayjs(date).format(dateFormat));
			setInsuranceDate(formattedDates);
		} else {
			setInsuranceDate([]); // Make sure to reset it to an empty array
		}
	};

	const handleRemoveInsuranceDate = () => {
		setInsuranceDate([]); // Reset the state to an empty array
		form.setFieldsValue({ insuranceCardReceivedOn: "" });
	};

	const handleAddInsuranceDate = () => {
		setInsuranceDate(null);
		setShowInsuranceDateField(true);
	};

	const onValuesChange = (changedValues, allValues) => {
		if (changedValues.secondaryInsurance !== undefined) {
			if (allValues.secondaryInsurance === "NONE") {
				form.setFieldsValue({
					secondaryInsuranceState: "NONE",
					secondaryInsurancePlan: "NONE",
					secondaryInsuranceNumber: "NONE",
					secondaryInsuranceGroupNumber: "NONE",
				});
			}
		}
	};

	return (
		<div className={containerClass}>
			<Form
				ref={formRef}
				layout="vertical"
				form={form}
				onFinish={onFinish}
				onFinishFailed={onFinishFailed}
				onValuesChange={onValuesChange}
				autoComplete="off"
				size={defaultSize === "dataCapture" ? "small" : ""}
			>
				<Row gutter={16}>
					<Col
						{...colProps}
						// xl={8} lg={8} md={8} sm={12} xs={24}
					>
						<Form.Item
							className="mb-2"
							label="Portal Availability"
							name="portalAvailability"
							rules={[{ required: true, message: "Please select the portal availability!" }]}
							style={!EditAccess ? { pointerEvents: "none" } : {}}
						>
							<Select
								showSearch
								allowClear
								placeholder="Select a portal availability"
								optionFilterProp="children"
								onChange={onChange}
								onSearch={onSearch}
								filterOption={filterOption}
								options={PortalAvailability}
								style={!EditAccess ? { pointerEvents: "none" } : {}}
							/>
						</Form.Item>
						{showInsuranceDateField ? (
							<Form.Item className="mb-2" label="Insurance Card Received On" name="insuranceCardReceivedOn">
								<DatePicker
									className="w-full"
									multiple
									onChange={onChangeDatePicker}
									disabledDate={disabledDate}
									format={dateFormat}
									value={insuranceDate?.length ? insuranceDate?.map((date) => dayjs(date, dateFormat)) : null}
									// value={insuranceDate ? (insuranceDate) : null}
									style={!EditAccess ? { pointerEvents: "none" } : {}}
								/>
								<Button type="link" onClick={handleRemoveInsuranceDate} disabled={!EditAccess}>
									Remove Insurance Card Received On
								</Button>
							</Form.Item>
						) : (
							<Button type="link" onClick={handleAddInsuranceDate} disabled={!EditAccess}>
								Add Insurance Card Received On
							</Button>
						)}
						<Form.Item
							className="mb-2"
							label="Insurance Card"
							name="insuranceCard"
							style={!EditAccess ? { pointerEvents: "none" } : {}}
						>
							<Select
								showSearch
								allowClear
								placeholder="Select a insurance card"
								optionFilterProp="children"
								onChange={onChange}
								onSearch={onSearch}
								filterOption={filterOption}
								options={InsuranceCardDelivered}
								style={!EditAccess ? { pointerEvents: "none" } : {}}
							/>
						</Form.Item>
						<Form.Item
							className="mb-2"
							label="Multiplan"
							name="multiplan"
							style={!EditAccess ? { pointerEvents: "none" } : {}}
						>
							<Select
								showSearch
								allowClear
								placeholder="Select a multiplan"
								optionFilterProp="children"
								onChange={onChange}
								onSearch={onSearch}
								filterOption={filterOption}
								options={Multiplan}
								style={!EditAccess ? { pointerEvents: "none" } : {}}
							/>
						</Form.Item>
						<Form.Item className="mb-2" label="IC Payer ID" name="icPayerID">
							<Input
								onInput={(e) => {
									let inputValue = e.target.value.toUpperCase();
									// Allow only alphanumeric characters, and partial or complete "U/A" or "N/A"
									if (
										/^[A-Za-z0-9]*$/.test(inputValue) ||
										inputValue === "U/" ||
										inputValue === "N/" ||
										inputValue === "U/A" ||
										inputValue === "N/A"
									) {
										e.target.value = inputValue;
									} else {
										// Remove invalid characters
										inputValue = inputValue.replace(/[^A-Za-z0-9]/g, "");
										if (inputValue.length <= 16) {
											e.target.value = inputValue;
										} else {
											e.target.value = inputValue.slice(0, 16); // Ensure max length is 16 characters
										}
									}
								}}
								readOnly={!EditAccess}
							/>
						</Form.Item>
					</Col>
					<Col
						{...colProps}
						// xl={8} lg={8} md={8} sm={12} xs={24}
					>
						<Form.Item className="mb-2" label="Primary Insurance" name="primaryInsurance">
							<Input
								onInput={(e) => {
									const inputValue = e.target.value.toUpperCase();
									e.target.value = inputValue;
								}}
								readOnly={!EditAccess}
							/>
						</Form.Item>
						<Form.Item
							className="mb-2"
							label="Primary Insurance State"
							name="primaryInsuranceState"
							style={!EditAccess ? { pointerEvents: "none" } : {}}
						>
							<Select
								showSearch
								allowClear
								placeholder="Select a primary insurance state"
								optionFilterProp="children"
								onChange={onChange}
								onSearch={onSearch}
								filterOption={stateFilter}
								options={States}
								style={!EditAccess ? { pointerEvents: "none" } : {}}
							/>
						</Form.Item>
						<Form.Item className="mb-2" label="Primary Insurance Plan" name="primaryInsurancePlan">
							<TextArea
								rows={1}
								onInput={(e) => {
									const inputValue = e.target.value.toUpperCase();
									e.target.value = inputValue;
								}}
								readOnly={!EditAccess}
							/>
						</Form.Item>

						<Form.Item className="mb-2" label="Primary Insurance Group Number" name="primaryInsuranceGroupNumber">
							<Input
								onInput={(e) => {
									const inputValue = e.target.value.toUpperCase();
									e.target.value = inputValue;
								}}
								readOnly={!EditAccess}
							/>
						</Form.Item>

						<Form.Item
							className="mb-2"
							label="Secondary Insurance"
							name="secondaryInsurance"
							style={!EditAccess ? { pointerEvents: "none" } : {}}
						>
							<Input
								onInput={(e) => {
									const inputValue = e.target.value.toUpperCase();
									e.target.value = inputValue;
								}}
								readOnly={!EditAccess}
							/>
						</Form.Item>
					</Col>
					<Col
						{...colProps}
						// xl={8} lg={8} md={8} sm={12} xs={24}
					>
						<Form.Item
							className="mb-2"
							label="Secondary Insurance State"
							name="secondaryInsuranceState"
							style={!EditAccess ? { pointerEvents: "none" } : {}}
						>
							<Select
								showSearch
								allowClear
								placeholder="Select a secondary insurance state"
								optionFilterProp="children"
								onChange={onChange}
								onSearch={onSearch}
								filterOption={stateFilter}
								options={States}
								style={!EditAccess ? { pointerEvents: "none" } : {}}
							/>
						</Form.Item>
						<Form.Item className="mb-2" label="Secondary Insurance Plan" name="secondaryInsurancePlan">
							<TextArea
								rows={1}
								onInput={(e) => {
									const inputValue = e.target.value.toUpperCase();
									e.target.value = inputValue;
								}}
								readOnly={!EditAccess}
							/>
						</Form.Item>
						<Form.Item className="mb-2" label="Secondary Insurance Number" name="secondaryInsuranceNumber">
							<Input
								onInput={(e) => {
									const inputValue = e.target.value.toUpperCase();
									e.target.value = inputValue;
								}}
								readOnly={!EditAccess}
							/>
						</Form.Item>
						<Form.Item className="mb-2" label="Secondary Insurance Group Number" name="secondaryInsuranceGroupNumber">
							<Input
								onInput={(e) => {
									const inputValue = e.target.value.toUpperCase();
									e.target.value = inputValue;
								}}
								readOnly={!EditAccess}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col
						// {...colProps}
						xl={12}
						lg={12}
						md={12}
						sm={12}
						xs={12}
					>
						<Form.Item className="mb-2">
							{/* <Button
              className='w-fit mt-2 font-medium'
            >
              Cancel
            </Button> */}
						</Form.Item>
					</Col>
					<Col
						xl={12}
						lg={12}
						md={12}
						sm={12}
						xs={12}
						// xl={12} lg={12} md={12} sm={12} xs={12}
					>
						<Form.Item className="mb-2">
							<Button
								className="float-right mt-2 w-fit bg-blue-400 font-medium"
								type="primary"
								htmlType="submit"
								disabled={!EditAccess}
							>
								Save
							</Button>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</div>
	);
};

export default memo(InsuranceDetails);
