import { createSlice } from "@reduxjs/toolkit";
import {
	createChartDocumentApi,
	deleteChartDocumentApi,
	getChartDocumentsApi,
	updateChartDocumentApi,
} from "./chartDocumentService";

const initialState = {
	chartDocumentList: [],
	newDocument: [],
	chartId: "",
	chartDocViewUpload: false,
	loading: "idle",
	documentData: null,
	currentRequestId: undefined,
	error: null,
};

export const chartDocumentSlice = createSlice({
	name: "document",
	initialState,
	reducers: {
		// add your non-async reducers here
		logout: (state) => {
			state.chartDocumentList = [];
			state.loading = "idle";
			state.currentRequestId = undefined;
			state.error = null;
		},
		setChartDocViewUpload: (state, action) => {
			state.chartDocViewUpload = action.payload;
		},
		setNewDocument: (state, action) => {
			state.newDocument = action.payload;
		},
		setChartId: (state, action) => {
			state.chartId = action.payload;
		},
		setDocumentData: (state, action) => {
			state.documentData = action.payload;
		},
	},
	extraReducers: (builder) => {
		// extraReducers handles asynchronous requests, which is our main focus.
		// GET
		builder
			.addCase(getChartDocumentsApi.pending, (state, action) => {
				if (state.loading === "idle") {
					state.loading = "pending";
					state.currentRequestId = action.meta.requestId;
				}
			})
			.addCase(getChartDocumentsApi.fulfilled, (state, action) => {
				const { requestId } = action.meta;
				if (state.loading === "pending" && state.currentRequestId === requestId) {
					state.loading = "idle";
					state.chartDocumentList.length = 0;
					state.chartDocumentList.push(...action.payload);
					state.currentRequestId = undefined;
				}
			})
			.addCase(getChartDocumentsApi.rejected, (state, action) => {
				const { requestId } = action.meta;
				if (state.loading === "pending" && state.currentRequestId === requestId) {
					state.loading = "idle";
					// state.error = action.error;
					state.currentRequestId = undefined;
					if (action.payload) {
						state.error = action.payload.errorMessage;
					} else {
						state.error = action.error.message;
					}
				}
			});

		// POST
		builder
			.addCase(createChartDocumentApi.pending, (state, action) => {
				if (state.loading === "idle") {
					state.loading = "pending";
					state.currentRequestId = action.meta.requestId;
				}
			})
			.addCase(createChartDocumentApi.fulfilled, (state, action) => {
				const { requestId } = action.meta;
				if (state.loading === "pending" && state.currentRequestId === requestId) {
					state.loading = "idle";
					state.chartDocumentList.unshift(action.payload);
					state.currentRequestId = undefined;
				}
			})
			.addCase(createChartDocumentApi.rejected, (state, action) => {
				const { requestId } = action.meta;
				if (state.loading === "pending" && state.currentRequestId === requestId) {
					state.loading = "idle";
					// state.error = action.error;
					state.currentRequestId = undefined;
					if (action.payload) {
						state.error = action.payload.errorMessage;
					} else {
						state.error = action.error.message;
					}
				}
			});

		// PUT
		builder
			.addCase(updateChartDocumentApi.pending, (state, action) => {
				if (state.loading === "idle") {
					state.loading = "pending";
					state.currentRequestId = action.meta.requestId;
				}
			})
			.addCase(updateChartDocumentApi.fulfilled, (state, action) => {
				const { requestId } = action.meta;
				if (state.loading === "pending" && state.currentRequestId === requestId) {
					const updateItem = action.payload;
					const index = state.chartDocumentList.findIndex((item) => item.id === updateItem.id);
					if (index > -1) {
						state.chartDocumentList[index] = updateItem;
					}
					state.loading = "idle";
					state.currentRequestId = undefined;
				}
			})
			.addCase(updateChartDocumentApi.rejected, (state, action) => {
				const { requestId } = action.meta;
				if (state.loading === "pending" && state.currentRequestId === requestId) {
					state.loading = "idle";
					// state.error = action.error;
					state.currentRequestId = undefined;
					if (action.payload) {
						state.error = action.payload.errorMessage;
					} else {
						state.error = action.error.message;
					}
				}
			});

		// DELETE
		builder
			.addCase(deleteChartDocumentApi.pending, (state, action) => {
				if (state.loading === "idle") {
					state.loading = "pending";
					state.currentRequestId = action.meta.requestId;
				}
			})
			.addCase(deleteChartDocumentApi.fulfilled, (state, action) => {
				const { requestId } = action.meta;
				if (state.loading === "pending" && state.currentRequestId === requestId) {
					const index = state.chartDocumentList.findIndex((item) => item.id === action.payload);
					if (index > -1) {
						state.chartDocumentList.splice(index, 1);
					}
					state.loading = "idle";
					state.currentRequestId = undefined;
				}
			})
			.addCase(deleteChartDocumentApi.rejected, (state, action) => {
				const { requestId } = action.meta;
				if (state.loading === "pending" && state.currentRequestId === requestId) {
					state.loading = "idle";
					// state.error = action.error;
					state.currentRequestId = undefined;
					if (action.payload) {
						state.error = action.payload.errorMessage;
					} else {
						state.error = action.error.message;
					}
				}
			});
	},
});

// Action creators are generated for each case reducer function
export const { logout, setChartDocViewUpload, setNewDocument, setChartId, setDocumentData } =
	chartDocumentSlice.actions;

export default chartDocumentSlice.reducer;
