import { Breadcrumb, Col, Row } from "antd";
import { useSelector } from "react-redux";

import { LuGanttChartSquare } from "react-icons/lu";
import { MdScheduleSend } from "react-icons/md";

import HomeEntryText from "./HomeEntryText";
import InfoCard from "./InfoCard";

const Home = ({ breadcrumbItems }) => {
	const { firstName, role } = useSelector((state) => state.userDetails);
	return (
		<>
			<div>
				{breadcrumbItems && (
					<Breadcrumb>
						{breadcrumbItems.map((item) => (
							<Breadcrumb.Item key={item.key}>{item.title}</Breadcrumb.Item>
						))}
					</Breadcrumb>
				)}
				<div className="flex h-full max-h-[77vh] w-full flex-col overflow-auto p-[5%]">
					<div className="flex h-full w-full flex-col items-center justify-center">
						<HomeEntryText
							text={
								<>
									<div className="text-center">
										<span className="font-medium font-poppins text-[4vw] italic">Hi There, {firstName}!</span>
									</div>
									<div className="mt-[-1.2rem] text-center">
										<span className="font-thin text-[1.5vw] xs:text-[2.5vw] italic">
											Let's get started, Select a feature to begin exploring.
										</span>
									</div>
								</>
							}
							type="paragraph"
						/>
					</div>
					<div className="h-full w-full flex-row items-center justify-center">
						<Row gutter={[16, 16]} justify="center" align="middle">
							<Col xs={24} sm={12} md={12} lg={8} xl={6} className="flex justify-center">
								<InfoCard
									icon={<LuGanttChartSquare />}
									label="Dashboard"
									tooltipText="Find a patient using their name, ID, or related details"
									buttonText="Search By Patient"
								/>
							</Col>
							{role !== "VERIFICATION" &&
							role !== "VERIFICATION CALLER" &&
							role !== "AUDIT" &&
							role !== "CODING" &&
							role !== "AUDIT CALLER" ? (
								<>
									<Col xs={24} sm={12} md={12} lg={8} xl={6} className="flex justify-center">
										<InfoCard
											icon={<MdScheduleSend />}
											label="Report Repository"
											tooltipText="Access, download, and manage your saved reports easily"
											buttonText="Access Report Repository"
										/>
									</Col>
								</>
							) : null}
						</Row>
					</div>
				</div>
			</div>
		</>
	);
};

export default Home;
