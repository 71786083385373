import ReactDOM from "react-dom/client";
import "./index.css";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react"; // Import PersistGate
import App from "./App";
import { persistor, store } from "./redux/store"; // Import persistor from the updated store
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			{" "}
			{/* Add PersistGate */}
			<App />
		</PersistGate>
	</Provider>,
);

reportWebVitals();
