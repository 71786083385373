// It is used in dashboard for filters

import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getFilteredCharts } from "../api/chart/chart";
import { setLoading } from "../redux/loading/loadingSlice";

const useFilteredCharts = (
	initialFilters = {
		callingCompletedOn: "",
		calledBy: "",
		Status: "",
		State: "",
		Product: "",
		Insurance: "",
		Range: "",
		internalFtpDateOn: "",
		deliveredOn: "",
	},
	initialSearchTerm = "",
	projectId = "",
) => {
	const dispatch = useDispatch();
	const [charts, setCharts] = useState([]);
	const [isWeek, setIsWeek] = useState(true);
	const [error, setError] = useState(null);
	const [searchTerm, setSearchTerm] = useState(initialSearchTerm);

	const [chartFilters, setFilters] = useState(initialFilters);

	const fetchCharts = useCallback(async () => {
		dispatch(setLoading(true));
		try {
			const response = await getFilteredCharts(chartFilters, searchTerm, projectId);
			setIsWeek(response?.data?.isWeek || false);
			setCharts(response?.data?.charts || []);
		} catch (error) {
			setError(error);
		} finally {
			dispatch(setLoading(false));
		}
	}, [dispatch, chartFilters, searchTerm, projectId]);

	// Function to refresh charts
	const refreshCharts = useCallback(() => {
		fetchCharts(); // Calls the function to fetch charts
	}, [fetchCharts]);

	useEffect(() => {
		fetchCharts();
	}, [fetchCharts]);

	const handleFiltersChange = (newFilters) => {
		setFilters((prevFilters) => ({
			...prevFilters,
			...newFilters,
		}));
	};

	const handleSearchChange = (newSearchTerm) => {
		setSearchTerm(newSearchTerm);
	};

	return {
		charts,
		setCharts,
		error,
		refreshCharts,
		setFilters: handleFiltersChange,
		setSearchTerm: handleSearchChange,
		isWeek,
	};
};

export default useFilteredCharts;
