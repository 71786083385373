import api from "../../api";

const baseUrl = "/api/project";

export const getProjects = () => {
	return api.get(baseUrl);
};

export const getProjectsPagination = ({ page = 1, pageSize = 10 }) => {
	return api.get(`${baseUrl}/pagination`, {
		params: {
			page,
			pageSize,
		},
		withCredentials: true, // Ensure credentials are included
	});
};

export const addProject = (postData) => {
	return api.post(baseUrl, postData, { withCredentials: true });
};

export const updateProject = (updateId, putData) => {
	return api.put(`${baseUrl}/${updateId}`, putData, { withCredentials: true });
};

export const deleteProject = (deleteId) => {
	return api.delete(`${baseUrl}/${deleteId}`, { withCredentials: true });
};
