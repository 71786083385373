import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import chartReducer from "./chart/chartSlice";
import documentReducer from "./document/documentSlice";
import loadingReducer from "./loading/loadingSlice";
import projectReducer, { projectListenerMiddleware } from "./project/projectSlice";
import roleReducer, { roleListenerMiddleWare } from "./role/roleSlice";
import subsequentDocumentReducer from "./subsequentDocument/subsequentDocumentSlice";
import useCharReducer from "./useCharts/useChartsSlice";
import userReducer from "./user/userSlice";

import chartViewManagementReducer from "./ChartViewManagement/ChartViewManagementSlice";
import exclusionReducer from "./Exclusion/exclusionSlice";
import reportRepositoryReducer from "./ReportRepository/reportRepositorySlice";
import authReducer from "./auth/authSlice";
import chartDocumentReducer from "./chartDocument/chartDocumentSlice";
import chartSearchHistorySlice from "./chartSearchHistory/chartSearchHistorySlice";
import notificationReducer from "./notification/notificationSlice";
import providerReducer from "./provider/providerSlice";
import recentHistorySliceReducer from "./recentHistory/recentHistorySlice";
import scheduleSliceReducer from "./scheduleSend/scheduleSendSlice";
import timeZoneReducer from "./timeZone/timeZoneSlice";
import userDetailsReducer from "./userDetails/userDetailsSlice";
import createShareChartReducer from "./ShareChartStateManagement/ShareChartStateManagementSlice"

// Function to generate a unique key and store it in sessionStorage
const getPersistKey = () => {
	const persistKey = sessionStorage.getItem("persistKey");
	if (!persistKey) {
		const newPersistKey = `persistedState-${new Date().getTime()}`;
		sessionStorage.setItem("persistKey", newPersistKey);
		return newPersistKey;
	}
	return persistKey;
};

const persistConfig = {
	key: getPersistKey(),
	version: 1,
	storage,
	// blacklist is for rehydrate
	blacklist: [
		"loading",
		"notification",
		"chartDocument",
		"scheduleSend",
		"chart",
		"document",
		"project",
		"role",
		"user",
		"exclusion",
		"timeZone",
		"listenerActive",
	], // Add slices that don’t need persistence
	// whitelist is for hydrate
	whitelist: ["userDetails", "provider"],
};

const rootReducer = combineReducers({
	loading: loadingReducer,
	project: projectReducer,
	role: roleReducer,
	user: userReducer,
	document: documentReducer,
	chart: chartReducer,
	notification: notificationReducer,
	chartDocument: chartDocumentReducer,
	scheduleSend: scheduleSliceReducer,
	userDetails: userDetailsReducer,
	provider: providerReducer,
	exclusion: exclusionReducer,
	timeZone: timeZoneReducer,
	reportRepository: reportRepositoryReducer,
	recentHistory: recentHistorySliceReducer,
	searchHistory: chartSearchHistorySlice,
	chartViewManagement: chartViewManagementReducer,
	auth: authReducer,
	useCharts: useCharReducer,
	subsequentDocument: subsequentDocumentReducer,
	createShare: createShareChartReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
	reducer: persistedReducer,
	devTools: true,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
			immutableCheck: false,
		}).prepend(projectListenerMiddleware.middleware, roleListenerMiddleWare.middleware), // Register middleware
});

export const persistor = persistStore(store);
