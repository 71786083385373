import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isShareModalVisible: false,
    emails: [],
    errorMessage:"",
    copySuccess: false,
    shareLink: "",
};

export const shareChartStateManagementSlice = createSlice({
    name: "createShare",
    initialState,
    reducers: {
        setShareModalVisible: (state, action) => {
            state.isShareModalVisible = action.payload;
            if (!action.payload) {
                state.errorMessage = ""; 
                state.copySuccess = false;
                state.shareLink = "";
            }
        },
        addEmail: (state, action) => {
          const email = action.payload;
          if (!state.emails.includes(email)) {
              state.emails.push(email);
              state.errorMessage = "";
          } else {
              state.errorMessage = "Email already added!";
          }
        },
        removeEmail: (state, action) => {
            state.emails = state.emails.filter(email => email !== action.payload);
        },
        resetShareState: (state) => {
            state.isShareModalVisible = false;
            state.emails = [];
            state.errorMessage = "";
            state.copySuccess = false;
            state.shareLink = "";
        },
        setErrorMessage: (state, action) => {
          state.errorMessage = action.payload;
        },
        setCopySuccess: (state, action) => {
            state.copySuccess = action.payload;
        },
        setShareLink: (state, action) => {
            state.shareLink = action.payload;
        }
    },
});

export const {
   setShareModalVisible, 
   addEmail, 
   removeEmail, 
   resetShareState,
   setErrorMessage, 
   setCopySuccess,
   setShareLink,
} = shareChartStateManagementSlice.actions;

export default shareChartStateManagementSlice.reducer;
