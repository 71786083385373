import { createAsyncThunk } from "@reduxjs/toolkit";
import {
	deleteScheduleList,
	getScheduleList,
	scheduleSend,
	updateScheduleList,
} from "../../api/scheduleSend/scheduleSend.js";
export const scheduleSendApi = createAsyncThunk("scheduleSend/create", async (data, { rejectWithValue }) => {
	try {
		const response = await scheduleSend(data);
		return response.data;
	} catch (error) {
		if (!error.response) {
			throw error;
		}
		return rejectWithValue(error.response.data);
	}
});

export const getScheduleListApi = createAsyncThunk("scheduleSend/get", async (data, { rejectWithValue }) => {
	try {
		const response = await getScheduleList();
		return response.data;
	} catch (error) {
		if (!error.response) {
			throw error;
		}
		return rejectWithValue(error.response.data);
	}
});

export const updateScheduleApi = createAsyncThunk("scheduleSend/update", async (data, { rejectWithValue }) => {
	const { updateId, items } = data;
	try {
		const response = await updateScheduleList(updateId, items);
		return response.data;
	} catch (error) {
		if (!error.response) {
			throw error;
		}
		return rejectWithValue(error.response.data);
	}
});

export const deleteScheduleApi = createAsyncThunk("scheduleSend/delete", async (deleteId, { rejectWithValue }) => {
	try {
		const response = await deleteScheduleList(deleteId);
		return response.data;
	} catch (error) {
		if (!error.response) {
			throw error;
		}
		return rejectWithValue(error.response.data);
	}
});
