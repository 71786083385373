import { createSlice } from "@reduxjs/toolkit";
import { getSearchHistoryApi, saveSearchToHistoryApi } from "./chartSearchHistoryService";

// Initial state
const initialState = {
	data: [], // Array to hold search history
	loading: "idle", // Tracks loading state
	error: null, // Tracks errors
};

const searchHistorySlice = createSlice({
	name: "searchHistory",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// Fetch search history
		builder
			.addCase(getSearchHistoryApi.pending, (state) => {
				if (state.loading === "idle") {
					state.loading = "pending";
					state.error = null;
				}
			})
			.addCase(getSearchHistoryApi.fulfilled, (state, action) => {
				if (state.loading === "pending") {
					state.loading = "idle";
					state.data = action.payload || []; // Populate state with fetched search history
				}
			})
			.addCase(getSearchHistoryApi.rejected, (state, action) => {
				if (state.loading === "pending") {
					state.loading = "idle";
					state.error = action.payload || "Failed to fetch search history";
				}
			});

		// Save search query to history
		builder
			.addCase(saveSearchToHistoryApi.pending, (state) => {
				if (state.loading === "idle") {
					state.loading = "pending";
					state.error = null;
				}
			})
			.addCase(saveSearchToHistoryApi.fulfilled, (state, action) => {
				if (state.loading === "pending") {
					state.loading = "idle";
					const newRecord = action.payload;
					const index = state.data.findIndex((item) => item.id === newRecord.id);

					if (index >= 0) {
						// Update existing record
						state.data[index] = newRecord;
					} else {
						// Add new record
						state.data.push(newRecord);
					}
				}
			})
			.addCase(saveSearchToHistoryApi.rejected, (state, action) => {
				if (state.loading === "pending") {
					state.loading = "idle";
					state.error = action.payload || "Failed to save search to history";
				}
			});
	},
});

// Selectors
export const selectSearchHistoryData = (state) => state.searchHistory.data;
export const selectSearchHistoryLoading = (state) => state.searchHistory.loading;
export const selectSearchHistoryError = (state) => state.searchHistory.error;

// Export the actions and reducer
export { getSearchHistoryApi, saveSearchToHistoryApi };
export default searchHistorySlice.reducer;
