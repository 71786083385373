import { createAsyncThunk } from "@reduxjs/toolkit";
import {
	addNotification,
	deleteNotification,
	getNotifications,
	getTodayNotifications,
	markNotificationAsRead,
	updateNotification,
} from "../../api/notification/notification";

export const getNotificationsApi = createAsyncThunk("notification/get", async (data, { rejectWithValue }) => {
	try {
		const response = await getNotifications();
		return response.data;
	} catch (error) {
		if (!error.response) {
			throw error;
		}
		return rejectWithValue(error.response.data);
	}
});

export const getTodayNotificationsApi = createAsyncThunk("notification/get", async (queryText, { rejectWithValue }) => {
	try {
		const response = await getTodayNotifications(queryText);
		return response.data;
	} catch (error) {
		if (!error.response) {
			throw error;
		}
		return rejectWithValue(error.response.data);
	}
});

export const markNotificationAsReadApi = createAsyncThunk("notification/update", async (data, { rejectWithValue }) => {
	try {
		const response = await markNotificationAsRead(data);
		return response.data;
	} catch (error) {
		if (!error.response) {
			throw error;
		}
		return rejectWithValue(error.response.data);
	}
});

export const createNotificationApi = createAsyncThunk("notification/create", async (data, { rejectWithValue }) => {
	try {
		const response = await addNotification(data);
		return response.data;
	} catch (error) {
		if (!error.response) {
			throw error;
		}
		return rejectWithValue(error.response.data);
	}
});

export const updateNotificationApi = createAsyncThunk("notification/update", async (data, { rejectWithValue }) => {
	const { updateId, items } = data;
	try {
		const response = await updateNotification(updateId, items);
		return response.data;
	} catch (error) {
		if (!error.response) {
			throw error;
		}
		return rejectWithValue(error.response.data);
	}
});

export const deleteNotificatiApi = createAsyncThunk("notification/delete", async (deleteId, { rejectWithValue }) => {
	try {
		const { status } = await deleteNotification(deleteId);
		if (status === 202) return deleteId;
	} catch (error) {
		if (!error.response) {
			throw error;
		}
		return rejectWithValue(error.response.data);
	}
});
