import { createAsyncThunk } from "@reduxjs/toolkit";
import {
	deleteRepositorySchedule,
	getRepositoryScheduleList,
	getScheduleListPagination,
	repositorySend,
	updateRepositorySchedule,
} from "../../api/reportRepository/reportRepository.js";
export const scheduleSendApi = createAsyncThunk("scheduleSend/create", async (data, { rejectWithValue }) => {
	try {
		const response = await repositorySend(data);
		return response.data;
	} catch (error) {
		if (!error.response) {
			throw error;
		}
		return rejectWithValue(error.response.data);
	}
});

export const getScheduleListPaginationApi = createAsyncThunk(
	"scheduleSend/pagination",
	async ({ page = 1, pageSize = 10 }, { rejectWithValue }) => {
		try {
			const response = await getScheduleListPagination({ page, pageSize });
			return response.data;
		} catch (error) {
			if (!error.response) {
				throw error;
			}
			return rejectWithValue(error.response.data);
		}
	},
);

export const getScheduleListApi = createAsyncThunk("scheduleSend/get", async (data, { rejectWithValue }) => {
	try {
		const response = await getRepositoryScheduleList();
		return response.data;
	} catch (error) {
		if (!error.response) {
			throw error;
		}
		return rejectWithValue(error.response.data);
	}
});

export const updateScheduleApi = createAsyncThunk("scheduleSend/update", async (data, { rejectWithValue }) => {
	const { updateId, items } = data;
	try {
		const response = await updateRepositorySchedule(updateId, items);
		return response.data;
	} catch (error) {
		if (!error.response) {
			throw error;
		}
		return rejectWithValue(error.response.data);
	}
});

export const deleteScheduleApi = createAsyncThunk("scheduleSend/delete", async (deleteId, { rejectWithValue }) => {
	try {
		const response = await deleteRepositorySchedule(deleteId);
		return response.data;
	} catch (error) {
		if (!error.response) {
			throw error;
		}
		return rejectWithValue(error.response.data);
	}
});
