import { Col, Row } from "antd";
import { useState } from "react";
import RoleHeader from "./RoleHeader";
import RoleTable from "./RoleTable";

const Role = () => {
	const [searchQuery, setSearchQuery] = useState("");
	// eslint-disable-next-line no-unused-vars
	const [searchTerm, setSearchTerm] = useState("");

	const roleList = [
		{
			id: 1,
			name: "Provider",
		},
		{
			id: 2,
			name: "VIEW",
		},
		{
			id: 3,
			name: "DOWNLOAD",
		},
	];

	return (
		<div className="h-full w-full p-2">
			<Row gutter={[16, 16]} justify="center" align="middle">
				<Col xs={24} sm={24} md={12} lg={12} xl={12}>
					<RoleHeader searchQuery={searchQuery} setSearchQuery={setSearchQuery} setSearchTerm={setSearchTerm} />
				</Col>
			</Row>
			<div className="no-scrollbar mt-2 max-h-[61vh] overflow-auto">
				<div className="min-w-[600px] sm:min-w-full">
					<Row gutter={[16, 16]} justify="center" align="middle">
						<Col xs={24} sm={24} md={12} lg={12} xl={12}>
							<RoleTable roleList={roleList} searchQuery={searchQuery} />
						</Col>
					</Row>
				</div>
			</div>
		</div>
	);
};

export default Role;
