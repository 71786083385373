import { createSlice } from "@reduxjs/toolkit";
import { createProviderApi, deleteProviderApi, getProvidersApi, updateProviderApi } from "./providerService";

const initialState = {
	providerList: [],
	loading: "idle",
	currentRequestId: undefined,
	error: null,
};

export const providerSlice = createSlice({
	name: "provider",
	initialState,
	reducers: {
		// add your non-async reducers here
		logout: (state) => {
			state.providerList = [];
			state.loading = "idle";
			state.currentRequestId = undefined;
			state.error = null;
		},
	},
	extraReducers: (builder) => {
		// extraReducers handles asynchronous requests, which is our main focus.
		// GET
		builder
			.addCase(getProvidersApi.pending, (state, action) => {
				if (state.loading === "idle") {
					state.loading = "pending";
					state.currentRequestId = action.meta.requestId;
				}
			})
			.addCase(getProvidersApi.fulfilled, (state, action) => {
				const { requestId } = action.meta;
				if (state.loading === "pending" && state.currentRequestId === requestId) {
					state.loading = "idle";
					state.providerList.length = 0;
					state.providerList.push(...action.payload);
					state.currentRequestId = undefined;
				}
			})
			.addCase(getProvidersApi.rejected, (state, action) => {
				const { requestId } = action.meta;
				if (state.loading === "pending" && state.currentRequestId === requestId) {
					state.loading = "idle";
					// state.error = action.error;
					state.currentRequestId = undefined;
					if (action.payload) {
						state.error = action.payload.errorMessage;
					} else {
						state.error = action.error.message;
					}
				}
			});

		// POST
		builder
			.addCase(createProviderApi.pending, (state, action) => {
				if (state.loading === "idle") {
					state.loading = "pending";
					state.currentRequestId = action.meta.requestId;
				}
			})
			.addCase(createProviderApi.fulfilled, (state, action) => {
				const { requestId } = action.meta;
				if (state.loading === "pending" && state.currentRequestId === requestId) {
					state.loading = "idle";
					state.providerList.unshift(action.payload);
					state.currentRequestId = undefined;
				}
			})
			.addCase(createProviderApi.rejected, (state, action) => {
				const { requestId } = action.meta;
				if (state.loading === "pending" && state.currentRequestId === requestId) {
					state.loading = "idle";
					// state.error = action.error;
					state.currentRequestId = undefined;
					if (action.payload) {
						state.error = action.payload.errorMessage;
					} else {
						state.error = action.error.message;
					}
				}
			});

		// PUT
		builder
			.addCase(updateProviderApi.pending, (state, action) => {
				if (state.loading === "idle") {
					state.loading = "pending";
					state.currentRequestId = action.meta.requestId;
				}
			})
			.addCase(updateProviderApi.fulfilled, (state, action) => {
				const { requestId } = action.meta;
				if (state.loading === "pending" && state.currentRequestId === requestId) {
					const updateItem = action.payload;
					const index = state.providerList.findIndex((item) => item.id === updateItem.id);
					if (index > -1) {
						state.providerList[index] = updateItem;
					}
					state.loading = "idle";
					state.currentRequestId = undefined;
				}
			})
			.addCase(updateProviderApi.rejected, (state, action) => {
				const { requestId } = action.meta;
				if (state.loading === "pending" && state.currentRequestId === requestId) {
					state.loading = "idle";
					// state.error = action.error;
					state.currentRequestId = undefined;
					if (action.payload) {
						state.error = action.payload.errorMessage;
					} else {
						state.error = action.error.message;
					}
				}
			});

		// DELETE
		builder
			.addCase(deleteProviderApi.pending, (state, action) => {
				if (state.loading === "idle") {
					state.loading = "pending";
					state.currentRequestId = action.meta.requestId;
				}
			})
			.addCase(deleteProviderApi.fulfilled, (state, action) => {
				const { requestId } = action.meta;
				if (state.loading === "pending" && state.currentRequestId === requestId) {
					const index = state.providerList.findIndex((item) => item.id === action.payload);
					if (index > -1) {
						state.providerList.splice(index, 1);
					}
					state.loading = "idle";
					state.currentRequestId = undefined;
				}
			})
			.addCase(deleteProviderApi.rejected, (state, action) => {
				const { requestId } = action.meta;
				if (state.loading === "pending" && state.currentRequestId === requestId) {
					state.loading = "idle";
					// state.error = action.error;
					state.currentRequestId = undefined;
					if (action.payload) {
						state.error = action.payload.errorMessage;
					} else {
						state.error = action.error.message;
					}
				}
			});
	},
});

// Action creators are generated for each case reducer function
export const { logout } = providerSlice.actions;

export default providerSlice.reducer;
