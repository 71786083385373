import { Col, Row } from "antd";
import { Button, DatePicker, Form, Input, Select } from "antd";
import dayjs from "dayjs";
// import TextArea from 'antd/es/input/TextArea';
import _ from "lodash";
import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
	BenefitsDME,
	CalendarMonth,
	CallingAssignedReason,
	ConsultationStatus,
	NetworkStatus,
	SsReceivedEquipment,
	VFCategory,
} from "../../assets/DispCodes";
import { updateChartApi } from "../../redux/chart/chartService";
import { setLoading } from "../../redux/loading/loadingSlice";
import { fetchPaginatedDocumentsApi } from "../../redux/subsequentDocument/subsequentDocumentService";
import {
	axiosSubmitErrorHandling,
	formattedDate,
	getIpAddress,
	isEmptyObject,
	isObject,
	trimObjValues,
} from "../../utils/utility";

const { TextArea } = Input;
const dateFormat = "MM/DD/YYYY";

const CoverageDetails = ({
	chartDetail,
	setSubmitChartStatus,
	setSubmitChartData,
	setCoverageDetailsSaved,
	isPatientDetailsSaved,
	isInsuranceDetailsSaved,
	isProductDetailsSaved,
	isCoverageDetailsSaved,
	setActiveSubTab,
	callerSpaceToggle,
	setCallerSpaceToggle,
	callerSpaceVisible,
	setCallerSpaceVisible,
	isFromUpdate,
	defaultSize,
	formRef,
	charts,
	setCharts,
	setChartDetailView,
	documentViewer,
	setEditPatientData,
	responsiveLayout,
	isSubsequent,
}) => {
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const [showCoverageStartField, setShowCoverageStartField] = useState(false);
	const [coverageStartDate, setCoverageStartDate] = useState(null);
	const [showCoverageEndField, setShowCoverageEndField] = useState(false);
	const [deliveredOnField, setDeliveredOnField] = useState(false);
	const [coverageEndDate, setCoverageEndDate] = useState(null);
	const [isVariesSelected, setIsVariesSelected] = useState(false); // Track if "VARIES" is selected
	const role = useSelector((state) => state.userDetails.role);
	const userId = useSelector((state) => state.userDetails.userId);
	const EditAccess = role === "PROVIDER" || role === "USER" || role === "CODING" || role === "GUEST" ? false : true;
	const { currentPage, pageSize, searchTerm, selectedDate } = useSelector((state) => state.subsequentDocument);
	const project = useSelector((state) => state.userDetails.project);

	const oldChartDetailView = useSelector((state) => state.chartViewManagement.chartDetailView);

	//state for Calling Added ON
	const [showCallingAddedField, setShowCallingAddedField] = useState(false);
	const [callingAddedDate, setCallingAddedDate] = useState(null);

	// const colProps = documentViewer
	//   ? { xl: 24, lg: 12, md: 12, sm: 12, xs: 12 }
	//   : { xl: 6, lg: 6, md: 8, sm: 12, xs: 24 }; // Adjust based on your preferred layout

	const colProps = documentViewer
		? { xl: 24, lg: 12, md: 12, sm: 12, xs: 12 } // Adjust layout for documentViewer
		: responsiveLayout
			? { span: 24 } // Full width for dataCapture
			: { xl: 6, lg: 6, md: 8, sm: 12, xs: 24 }; // Regular layout

	const containerClass = responsiveLayout ? "my-2 overflow-auto max-h-[70vh] no-scrollbar" : "";

	useEffect(() => {
		if (!chartDetail) {
			return;
		}

		const coverageInitialValue = {
			/* Coverage Detail Initial Values */
			calendarMonth: chartDetail?.calendarMonth || null,
			networkStatus: chartDetail?.networkStatus || null,
			innBenefits: chartDetail?.innBenefits || null,
			innDedCalendar: chartDetail?.innDedCalendar || null,
			innDedMetAmount: chartDetail?.innDedMetAmount || null,
			innOopCalendar: chartDetail?.innOopCalendar || null,
			innDedBalanceAmount: chartDetail?.innDedBalanceAmount || null,
			innCoins: chartDetail?.innCoins || null,
			innOopMetAmount: chartDetail?.innOopMetAmount || null,
			onnBenefits: chartDetail?.onnBenefits || null,
			oonDedCalendar: chartDetail?.oonDedCalendar || null,
			oonDedMetAmount: chartDetail?.oonDedMetAmount || null,
			ooDedBalanceAmount: chartDetail?.ooDedBalanceAmount || null,
			oonCoins: chartDetail?.oonCoins || null,
			oonOopCalendar: chartDetail?.oonOopCalendar || null,
			oonOopMet: chartDetail?.oonOopMet || null,
			authRequired: chartDetail?.authRequired || null,
			authVariesDetails: chartDetail?.authRequired === "VARIES" ? chartDetail?.authVariesDetails || "" : null,
			vfCategory: chartDetail?.vfCategory || null,
			ssReceived: chartDetail?.ssReceived || null,
			consultationStatus: chartDetail?.consultationStatus || null,
			vfComments: chartDetail?.vfComments || "",
			subsequentScanDate: chartDetail?.subsequentScanDate
				? chartDetail?.subsequentScanDate !== "N/A"
					? formattedDate(chartDetail.subsequentScanDate)
					: null
				: null,
			subsequentQuarter: chartDetail?.subsequentQuarter || null,
			transaction: chartDetail?.transaction || null,
			callingAssigned: chartDetail?.callingAssigned || null,
			callingAdded: chartDetail?.callingAdded ? dayjs(chartDetail.callingAdded) : null,
			deliveryStatus: chartDetail?.deliveryStatus || null,
			deliveredOn: chartDetail?.deliveredOn ? formattedDate(chartDetail.deliveredOn) : null,
			internalNotes: chartDetail?.internalNotes ? chartDetail.internalNotes : "",
		};

		if (!callerSpaceVisible) {
			if (
				coverageInitialValue?.vfCategory?.toLowerCase() === "VF In-Process"?.toLowerCase() ||
				coverageInitialValue?.hasOwnProperty("callingBy") ||
				coverageInitialValue?.vfCategory?.toLowerCase() === "Missing Demo/Insurance Info"?.toLowerCase() ||
				coverageInitialValue?.vfCategory?.toLowerCase() === "In-Active"?.toLowerCase() ||
				coverageInitialValue?.vfCategory?.toLowerCase() === "Subsequent VF In-Process"?.toLowerCase()
			) {
				dispatch(setCallerSpaceToggle(true));
				dispatch(setCallerSpaceVisible(true));
				//  setCallerSpaceToggle(true);
			} else if (coverageInitialValue?.hasOwnProperty("callingBy")) {
				// dispatch(setCallerSpaceToggle(true));
				dispatch(setCallerSpaceVisible(true));
				//  setCallerSpaceToggle(true);
			}
		}

		if (
			chartDetail?.coverageStartDate &&
			chartDetail?.coverageStartDate !== "Invalid Date" &&
			chartDetail?.coverageStartDate !== "U/A" &&
			chartDetail?.coverageStartDate !== "N/A"
		) {
			const putCoverageStartDate = chartDetail?.coverageStartDate ? dayjs(chartDetail.coverageStartDate) : "";
			setCoverageStartDate(putCoverageStartDate);
			setShowCoverageStartField(true);
		} else {
			setShowCoverageStartField(false);
			setCoverageStartDate(null);
		}

		if (
			chartDetail?.coverageEndDate &&
			chartDetail?.coverageEndDate !== "Invalid Date" &&
			chartDetail?.coverageEndDate !== "U/A" &&
			chartDetail?.coverageEndDate !== "N/A"
		) {
			const putCoverageEndDate = chartDetail?.coverageEndDate ? dayjs(chartDetail.coverageEndDate) : "";
			setCoverageEndDate(putCoverageEndDate);
			setShowCoverageEndField(true);
		} else {
			setShowCoverageEndField(false);
			setCoverageEndDate(null);
		}

		if (!callingAddedDate) {
			if (chartDetail?.callingAdded) {
				const formattedDate = dayjs(chartDetail.callingAdded).isValid()
					? dayjs(chartDetail.callingAdded).format(dateFormat)
					: null;
				setCallingAddedDate(formattedDate);
				setShowCallingAddedField(true);
			} else {
				setCallingAddedDate(null);
				setShowCallingAddedField(false);
			}
		}

		if (chartDetail?.deliveryStatus && chartDetail?.deliveryStatus === "YES") {
			const putDeliveredOn = chartDetail?.deliveredOn ? formattedDate(chartDetail.deliveredOn) : null;
			form.setFieldsValue({
				deliveredOn: putDeliveredOn,
			});
			setDeliveredOnField(true);
		} else {
			form.setFieldsValue({
				deliveredOn: null,
			});
			setDeliveredOnField(false);
		}
		setIsVariesSelected(chartDetail?.authRequired === "VARIES"); // Set `isVariesSelected` state setIsVariesSelected(chartDetail?.authRequired === 'VARIES'); // Set `isVariesSelected` state
		form.setFieldsValue(coverageInitialValue);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [callerSpaceVisible, chartDetail, dispatch, form, setCallerSpaceToggle, setCallerSpaceVisible]);

	const onValuesChange = (changedValues, allValues) => {
		if (changedValues.innDedCalendar !== undefined) {
			if (allValues.innDedCalendar === "N/A") {
				form.setFieldsValue({
					innDedMetAmount: "N/A",
					innDedBalanceAmount: "N/A",
				});
			} else {
				const innDedCalendar = Number.parseFloat(allValues.innDedCalendar) || 0;
				const innDedMetAmount = Number.parseFloat(allValues.innDedMetAmount) || 0;
				const innDedBalanceAmount = innDedCalendar - innDedMetAmount;
				form.setFieldsValue({
					innDedBalanceAmount: innDedBalanceAmount,
				});
			}
		} else if (changedValues.innDedMetAmount !== undefined) {
			const innDedCalendar = Number.parseFloat(allValues.innDedCalendar) || 0;
			const innDedMetAmount = Number.parseFloat(allValues.innDedMetAmount) || 0;
			const innDedBalanceAmount = innDedCalendar - innDedMetAmount;
			form.setFieldsValue({
				innDedBalanceAmount: innDedBalanceAmount,
			});
		}

		// Handle oonDedCalendar logic
		if (changedValues.oonDedCalendar !== undefined) {
			if (allValues.oonDedCalendar === "N/A") {
				form.setFieldsValue({
					oonDedMetAmount: "N/A",
					ooDedBalanceAmount: "N/A",
				});
			} else {
				const oonDedCalendar = Number.parseFloat(allValues.oonDedCalendar) || 0;
				const oonDedMetAmount = Number.parseFloat(allValues.oonDedMetAmount) || 0;
				const oonDedBalanceAmount = oonDedCalendar - oonDedMetAmount;
				form.setFieldsValue({
					ooDedBalanceAmount: oonDedBalanceAmount,
				});
			}
		} else if (changedValues.oonDedMetAmount !== undefined) {
			const oonDedCalendar = Number.parseFloat(allValues.oonDedCalendar) || 0;
			const oonDedMetAmount = Number.parseFloat(allValues.oonDedMetAmount) || 0;
			const oonDedBalanceAmount = oonDedCalendar - oonDedMetAmount;
			form.setFieldsValue({
				ooDedBalanceAmount: oonDedBalanceAmount,
			});
		}

		// Handle Delivery Status logic
		if (changedValues.deliveryStatus !== undefined) {
			if (allValues.deliveryStatus === "YES") {
				form.setFieldsValue({
					deliveredOn: chartDetail?.deliveredOn ? formattedDate(chartDetail.deliveredOn) : null, // Set to current date
				});
				setDeliveredOnField(true);
			} else if (allValues.deliveryStatus === "NO") {
				form.setFieldsValue({
					deliveredOn: null, // Set to null
				});
				setDeliveredOnField(false);
			}
		}
	};

	//Save Coverage Summary
	const onFinish = async (values) => {
		try {
			const items = { ...values };

			// Ensure subsequentScanDate array is converted to a comma-separated string
			if (Array.isArray(items.subsequentScanDate)) {
				items.subsequentScanDate = items.subsequentScanDate
					?.filter((date) => date !== null) // Filter out null values
					?.map((date) => dayjs(date).format("MM/DD/YYYY")) // Format each date
					?.join(","); // Join into a single string
			} else {
				if (
					items.subsequentScanDate &&
					typeof items.subsequentScanDate === "object" &&
					!Array.isArray(items.subsequentScanDate)
				) {
					// Handle case where insuranceDate is an object
					items.subsequentScanDate = dayjs(items.subsequentScanDate).format("MM/DD/YYYY");
				}
			}

			// Reset the subsequent cycle if subsequent date was entered by verification Caller
			// if (role === "VERIFICATION CALLER" && items?.subsequentScanDate /* && values.vfCategory?.toLowerCase() === "VF Held"?.toLowerCase() */) {
			//   items.subsequentVerificationStatus = "Recheck";
			//   items.subsequentCallerVerificationStatus = "Recheck";

			// }

			if (items?.subsequentScanDate) {
				items.subsequentCheck = true;
				items.subsequentVerificationStatus = "Pending";
				items.duplicateSubsequentScanDate = items?.subsequentScanDate; // Update the duplicate key if a value exists
			} else {
				items.duplicateSubsequentScanDate = chartDetail?.duplicateSubsequentScanDate || null; // Retain the existing value if no new value is provided
			}

			if (
				values.vfCategory?.toLowerCase() === "Subsequent VF In-Process"?.toLowerCase() &&
				chartDetail?.subsequentCheck
			) {
				items.subsequentCallerVerificationStatus = "Recheck";
			}

			if (!callerSpaceVisible) {
				if (
					values.vfCategory?.toLowerCase() === "VF In-Process"?.toLowerCase() ||
					values.vfCategory?.toLowerCase() === "Missing Demo/Insurance Info"?.toLowerCase() ||
					values.vfCategory?.toLowerCase() === "Subsequent VF In-Process"?.toLowerCase() ||
					values.vfCategory?.toLowerCase() === "In-Active"?.toLowerCase()
				) {
					items.callerSpaceVisible = true;
				}
			}

			items.userRole = role;

			if (coverageStartDate === "Invalid Date") {
				items.coverageStartDate = "U/A";
			}

			if (!showCoverageStartField && !coverageStartDate) {
				items.coverageStartDate = "N/A";
			}

			//onFinish for Calling Added On

			if (callingAddedDate === "Invalid Date") {
				items.callingAdded = "U/A";
			}

			if (!showCallingAddedField && !callingAddedDate) {
				items.callingAdded = "N/A";
			}

			if (callingAddedDate) {
				items.callingAdded = callingAddedDate;
				setShowCallingAddedField(true);
			} else {
				items.callingAdded = null;
				setShowCallingAddedField(false);
			}

			if (coverageStartDate && coverageStartDate !== "Invalid Date") {
				items.coverageStartDate = coverageStartDate;
			}

			if (coverageEndDate === "Invalid Date") {
				items.coverageEndDate = "U/A";
			}

			if (!showCoverageEndField && !coverageEndDate) {
				items.coverageEndDate = "N/A";
			}

			if (coverageEndDate && coverageEndDate !== "Invalid Date") {
				items.coverageEndDate = coverageEndDate;
			}

			if (Array.isArray(items.deliveredOn)) {
				items.deliveredOn = items.deliveredOn
					.filter((date) => date !== null) // Filter out null values
					.map((date) => dayjs(date).format("MM/DD/YYYY")) // Format each date
					.join(","); // Join into a single string
			} else {
				items.deliveredOn = null;
			}

			if (chartDetail?.deliveryStatus === "YES" && !items.deliveredOn) {
				items.deliveredOn = chartDetail?.deliveredOn || null;
			}

			items.userId = userId;
			const ipAddress = await getIpAddress();
			items.ipAddress = ipAddress;
			if (coverageStartDate && typeof coverageStartDate === "object" && !Array.isArray(coverageStartDate)) {
				// Handle case where insuranceDate is an object
				items.coverageStartDate = dayjs(coverageStartDate).format("MM/DD/YYYY");
			} else {
				items.coverageStartDate = coverageStartDate;
			}
			if (coverageEndDate && typeof coverageEndDate === "object" && !Array.isArray(coverageEndDate)) {
				// Handle case where insuranceDate is an object
				items.coverageEndDate = dayjs(coverageEndDate).format("MM/DD/YYYY");
			} else {
				items.coverageEndDate = coverageEndDate;
			}

			if (!isObject(items)) {
				return toast.warning("This is not object data!");
			}

			if (isEmptyObject(items)) {
				return toast.warning("Empty object cannot accept!");
			}

			trimObjValues(items);
			dispatch(setLoading(true));

			const updateId = chartDetail?.id || chartDetail?._id;
			dispatch(setCoverageDetailsSaved(true));
			if (role === "VERIFICATION CALLER" || role === "AUDIT CALLER") {
				setActiveSubTab("5");
			}
			if (callerSpaceVisible) {
				setActiveSubTab("5");
				// setActiveSubTab('6');
			}

			const res = await dispatch(updateChartApi({ updateId, items })).unwrap();
			const rowIndex = _.findIndex(charts, { id: updateId });
			if (rowIndex > -1) {
				const updatedCharts = [...charts];
				const updatedItem = { ...updatedCharts[rowIndex], ...res };
				updatedCharts[rowIndex] = updatedItem;
				setCharts(updatedCharts);
			}

			if (typeof setEditPatientData === "function") {
				setEditPatientData((prevData) => ({
					...prevData,
					...res, // Update editPatientData with the latest data from the API
				}));
			}

			if (isSubsequent && isSubsequent) {
				dispatch(setLoading(true));
				dispatch(
					fetchPaginatedDocumentsApi({
						page: currentPage,
						pageSize,
						searchTerm,
						project,
						userId,
						role,
						date: selectedDate,
					}),
				);
				dispatch(setLoading(false));
			}

			if (typeof setChartDetailView === "function") {
				// Access the current ChartDetailView from the Redux store

				// Merge the old data with the new data
				const updatedData = {
					...oldChartDetailView.data, // Existing data
					...res, // New response data
				};

				// Dispatch the updated state
				dispatch(
					setChartDetailView({
						selected: true,
						data: updatedData, // Merged data
					}),
				);
			}

			dispatch(setLoading(false));
			toast.success("Coverage details updated successfully!");
		} catch (error) {
			dispatch(setLoading(false));
			return axiosSubmitErrorHandling(error);
		}
	};

	const disabledDate = (current) => {
		// Get the current date and year
		const currentDate = dayjs();
		const currentYear = currentDate.year();

		// Disable dates after the current year or after the current date
		return current && (dayjs(current).year() > currentYear || dayjs(current).isAfter(currentDate, "day"));
	};

	//Submit whole chart
	const handleSubmitChart = async () => {
		if (isPatientDetailsSaved && isInsuranceDetailsSaved && isProductDetailsSaved && isCoverageDetailsSaved) {
			const updateId = chartDetail?.id || chartDetail?._id;
			setSubmitChartStatus(true);
			setSubmitChartData(updateId);
		} else if (!isPatientDetailsSaved) {
			toast.warning("Please save the Patient Details!");
		} else if (!isInsuranceDetailsSaved) {
			toast.warning("Please save the Insurance Information!");
		} else if (!isProductDetailsSaved) {
			toast.warning("Please save the Order Summary!");
		} else if (!isCoverageDetailsSaved) {
			toast.warning("Please save the Coverage Summary!");
		}
	};

	const onFinishFailed = (errorInfo) => {};

	const onChange = (value) => {};

	const onChangeVFStatus = (value) => {
		if (!callerSpaceVisible) {
			if (
				value?.toLowerCase() === "VF In-Process"?.toLowerCase() ||
				role === "AUDIT CALLER" ||
				role === "VERIFICATION CALLER" ||
				chartDetail?.hasOwnProperty("callingBy") ||
				value?.toLowerCase() === "Missing Demo/Insurance Info"?.toLowerCase() ||
				value?.toLowerCase() === "In-Active"?.toLowerCase() ||
				value?.toLowerCase() === "Subsequent VF In-Process"?.toLowerCase()
			) {
				dispatch(setCallerSpaceToggle(true));
				dispatch(setCallerSpaceVisible(true));
				// setCallerSpaceToggle(true);
			} else {
				dispatch(setCallerSpaceVisible(false));
				dispatch(setCallerSpaceToggle(false));
				// setCallerSpaceToggle(false);
			}
		}
	};

	const onSearch = (value) => {};

	const onChangeCallingAddedDatePicker = (date, dateString) => {
		const validDate = date && dayjs(date).isValid() ? dayjs(date).format(dateFormat) : null;
		setCallingAddedDate(validDate); // Update state with the formatted date or null
		form.setFieldsValue({ callingAdded: validDate });
	};

	const filterOption = (input, option) => (option?.label ?? "")?.toLowerCase()?.includes(input?.toLowerCase());

	const onChangeDatePicker = (date, dateString) => {
		if (date) {
			const month = dayjs(date).month() + 1; // month() is 0-indexed, so add 1
			let quarter = "";

			if (month >= 1 && month <= 3) {
				quarter = "Q1";
			} else if (month >= 4 && month <= 6) {
				quarter = "Q2";
			} else if (month >= 7 && month <= 9) {
				quarter = "Q3";
			} else if (month >= 10 && month <= 12) {
				quarter = "Q4";
			}

			form.setFieldsValue({ subsequentQuarter: quarter });
		} else {
			form.setFieldsValue({ subsequentQuarter: null });
		}
	};

	const onChangeCoverageStartDatePicker = (date, dateString) => {
		if (date) {
			const month = dayjs(date).month() + 1; // month() is 0-indexed, so add 1
			let calendarMonth = "";

			switch (month) {
				case 1:
					calendarMonth = "JANUARY";
					break;
				case 2:
					calendarMonth = "FEBRUARY";
					break;
				case 3:
					calendarMonth = "MARCH";
					break;
				case 4:
					calendarMonth = "APRIL";
					break;
				case 5:
					calendarMonth = "MAY";
					break;
				case 6:
					calendarMonth = "JUNE";
					break;
				case 7:
					calendarMonth = "JULY";
					break;
				case 8:
					calendarMonth = "AUGUST";
					break;
				case 9:
					calendarMonth = "SEPTEMBER";
					break;
				case 10:
					calendarMonth = "OCTOBER";
					break;
				case 11:
					calendarMonth = "NOVEMBER";
					break;
				case 12:
					calendarMonth = "DECEMBER";
					break;
				default:
					calendarMonth = "";
			}

			form.setFieldsValue({ calendarMonth });
		} else {
			form.setFieldsValue({ calendarMonth: null });
		}
		setCoverageStartDate(dayjs(date).format("MM/DD/YYYY"));
	};

	//handler functions to show and hide Calling Added On field

	const handleAddCallingAdded = () => {
		setCallingAddedDate(null);
		setShowCallingAddedField(true);
	};

	const handleRemoveCallingAdded = () => {
		setShowCallingAddedField(false);
		setCallingAddedDate(null);
		form.setFieldsValue({ callingAdded: "" }); // Clear the form field value
	};

	const onChangeCoverageEndDatePicker = (date, dateString) => {
		setCoverageEndDate(dayjs(date).format("MM/DD/YYYY"));
	};

	const handleRemoveCoverageStart = () => {
		setShowCoverageStartField(false);
		setCoverageStartDate(null);
		form.setFieldsValue({ coverageStartDate: "" });
	};

	const handleAddCoverageStart = () => {
		setCoverageStartDate(null);
		setShowCoverageStartField(true);
	};

	const handleRemoveCoverageEnd = () => {
		setShowCoverageEndField(false);
		setCoverageEndDate(null);
		form.setFieldsValue({ coverageEndDate: "" });
	};

	const handleAddCoverageEnd = () => {
		setCoverageEndDate(null);
		setShowCoverageEndField(true);
	};

	const disableOutOfCurrentYear = (current) => {
		const currentYear = dayjs().year(); // Get the current year using Day.js
		const startOfYear = dayjs(`${currentYear}-01-01`);
		const endOfYear = dayjs(`${currentYear}-12-29`);

		// Disable dates outside of January 1 to December 29 of the current year
		return current && (current?.isBefore(startOfYear) || current?.isAfter(endOfYear));
	};

	const handleAuthRequiredChange = (value) => {
		setIsVariesSelected(value === "VARIES"); // Show `authVariesDetails` if `authRequired` is 'VARIES'

		if (value !== "VARIES") {
			form.setFieldsValue({ authVariesDetails: null }); // Reset the `authVariesDetails` field if no longer 'VARIES'
		}
	};

	return (
		<div className={containerClass}>
			<Form
				ref={formRef}
				layout="vertical"
				form={form}
				onFinish={onFinish}
				onFinishFailed={onFinishFailed}
				autoComplete="off"
				onValuesChange={onValuesChange}
				size={defaultSize === "dataCapture" ? "small" : ""}
			>
				<Row gutter={16}>
					<Col
						{...colProps}
						// xl={6} lg={6} md={8} sm={12} xs={24}
					>
						<Form.Item
							className="mb-2"
							label="Calendar Month"
							name="calendarMonth"
							style={!EditAccess ? { pointerEvents: "none" } : {}}
						>
							<Select
								showSearch
								allowClear
								placeholder="Select a calendar month"
								optionFilterProp="children"
								onChange={onChange}
								onSearch={onSearch}
								filterOption={filterOption}
								options={CalendarMonth}
								style={!EditAccess ? { pointerEvents: "none" } : {}}
							/>
						</Form.Item>
						{showCoverageStartField ? (
							<Form.Item
								className="mb-2"
								label="Coverage Start Date"
								name="coverageStartDate"
								style={!EditAccess ? { pointerEvents: "none" } : {}}
							>
								<DatePicker
									className="w-full"
									value={coverageStartDate ? dayjs(coverageStartDate) : null}
									onChange={onChangeCoverageStartDatePicker}
									format={dateFormat}
									disabledDate={disabledDate}
									style={!EditAccess ? { pointerEvents: "none" } : {}}
								/>
								<Button type="link" onClick={handleRemoveCoverageStart} disabled={!EditAccess}>
									Remove Coverage Start Date
								</Button>
							</Form.Item>
						) : (
							<Button type="link" onClick={handleAddCoverageStart} disabled={!EditAccess}>
								Add Coverage Start Date
							</Button>
						)}
						{showCoverageEndField ? (
							<Form.Item className="mb-2" label="Coverage End Date" name="coverageEndDate">
								<DatePicker
									className="w-full"
									value={coverageEndDate ? dayjs(coverageEndDate) : null}
									onChange={onChangeCoverageEndDatePicker}
									format={dateFormat}
									style={!EditAccess ? { pointerEvents: "none" } : {}}
								/>
								<Button type="link" onClick={handleRemoveCoverageEnd} disabled={!EditAccess}>
									Remove Coverage End Date
								</Button>
							</Form.Item>
						) : (
							<Button type="link" onClick={handleAddCoverageEnd} disabled={!EditAccess}>
								Add Coverage End Date
							</Button>
						)}
						<Form.Item
							className="mb-2"
							label="Network Status"
							name="networkStatus"
							style={!EditAccess ? { pointerEvents: "none" } : {}}
						>
							<Select
								showSearch
								allowClear
								placeholder="Select a network status"
								optionFilterProp="children"
								onChange={onChange}
								onSearch={onSearch}
								filterOption={filterOption}
								options={NetworkStatus}
								style={!EditAccess ? { pointerEvents: "none" } : {}}
							/>
						</Form.Item>
						<Form.Item
							className="mb-2"
							label="INN Benefit's For DME"
							name="innBenefits"
							style={!EditAccess ? { pointerEvents: "none" } : {}}
						>
							<Select
								showSearch
								allowClear
								placeholder="Select a inn benefits for dme"
								optionFilterProp="children"
								onChange={onChange}
								onSearch={onSearch}
								filterOption={filterOption}
								options={BenefitsDME}
								style={!EditAccess ? { pointerEvents: "none" } : {}}
							/>
						</Form.Item>
						<Form.Item className="mb-2" label="INN Ded Calendar/Actual Amount" name="innDedCalendar">
							<Input
								type="text"
								onInput={(e) => {
									e.target.value = e.target.value.replace(/[^0-9.UuNn/Aa]/g, "").toUpperCase();
								}}
								prefix="$"
								readOnly={!EditAccess}
							/>
						</Form.Item>

						<Form.Item className="mb-2" label="INN Ded Met Amount" name="innDedMetAmount">
							<Input
								type="text"
								onInput={(e) => {
									e.target.value = e.target.value.replace(/[^0-9.UuNn/Aa]/g, "").toUpperCase();
								}}
								prefix="$"
								readOnly={!EditAccess}
							/>
						</Form.Item>

						<Form.Item className="mb-2" label="INN Ded Balance Amount" name="innDedBalanceAmount">
							<Input type="text" readOnly prefix="$" />
						</Form.Item>
					</Col>
					<Col
						{...colProps}
						// xl={6} lg={6} md={8} sm={12} xs={24}
					>
						<Form.Item className="mb-2" label="INN Coins" name="innCoins">
							<Input
								type="text"
								onInput={(e) => {
									e.target.value = e.target.value.replace(/[^0-9.UuNn/Aa]/g, "").toUpperCase();
								}}
								readOnly={!EditAccess}
								suffix="%"
							/>
						</Form.Item>
						<Form.Item className="mb-2" label="INN OOP Calendar/Actual Amount" name="innOopCalendar">
							<Input
								type="text"
								onInput={(e) => {
									e.target.value = e.target.value.replace(/[^0-9.UuNn/Aa]/g, "").toUpperCase();
								}}
								prefix="$"
								readOnly={!EditAccess}
							/>
						</Form.Item>

						<Form.Item className="mb-2" label="INN OOP Met Amount" name="innOopMetAmount">
							<Input
								type="text"
								onInput={(e) => {
									e.target.value = e.target.value.replace(/[^0-9.UuNn/Aa]/g, "").toUpperCase();
								}}
								prefix="$"
								readOnly={!EditAccess}
							/>
						</Form.Item>

						<Form.Item
							className="mb-2"
							label="OON Benefits For DME"
							name="onnBenefits"
							style={!EditAccess ? { pointerEvents: "none" } : {}}
						>
							<Select
								showSearch
								allowClear
								placeholder="Select a oon benefits for dme"
								optionFilterProp="children"
								onChange={onChange}
								onSearch={onSearch}
								filterOption={filterOption}
								options={BenefitsDME}
								style={!EditAccess ? { pointerEvents: "none" } : {}}
							/>
						</Form.Item>
						<Form.Item className="mb-2" label="OON Ded Calendar/Actual Amount" name="oonDedCalendar">
							<Input
								type="text"
								onInput={(e) => {
									e.target.value = e.target.value.replace(/[^0-9.UuNn/Aa]/g, "").toUpperCase();
								}}
								prefix="$"
								readOnly={!EditAccess}
							/>
						</Form.Item>

						<Form.Item className="mb-2" label="OON Ded Met Amount" name="oonDedMetAmount">
							<Input
								type="text"
								onInput={(e) => {
									e.target.value = e.target.value.replace(/[^0-9.UuNn/Aa]/g, "").toUpperCase();
								}}
								prefix="$"
								readOnly={!EditAccess}
							/>
						</Form.Item>

						<Form.Item className="mb-2" label="OON Ded Balance Amount" name="ooDedBalanceAmount">
							<Input
								type="text"
								onInput={(e) => {
									e.target.value = e.target.value.replace(/[^0-9.UuNn/Aa]/g, "").toUpperCase();
								}}
								prefix="$"
								readOnly
							/>
						</Form.Item>
					</Col>
					<Col
						{...colProps}
						// xl={6} lg={6} md={8} sm={12} xs={24}
					>
						<Form.Item className="mb-2" label="OON Coins" name="oonCoins">
							<Input
								type="text"
								suffix="%"
								onInput={(e) => {
									e.target.value = e.target.value.replace(/[^0-9.UuNn/Aa]/g, "").toUpperCase();
								}}
								readOnly={!EditAccess}
							/>
						</Form.Item>

						<Form.Item className="mb-2" label="OON OOP Calendar/Actual Amount" name="oonOopCalendar">
							<Input
								type="text"
								prefix="$"
								onInput={(e) => {
									e.target.value = e.target.value.replace(/[^0-9.UuNn/Aa]/g, "").toUpperCase();
								}}
								readOnly={!EditAccess}
							/>
						</Form.Item>

						<Form.Item className="mb-2" label="OON OOP Met Amount" name="oonOopMet">
							<Input
								type="text"
								prefix="$"
								onInput={(e) => {
									e.target.value = e.target.value.replace(/[^0-9.UuNn/Aa]/g, "").toUpperCase();
								}}
								readOnly={!EditAccess}
							/>
						</Form.Item>

						<Form.Item
							className="mb-2"
							label={<span className="text-red-500">Auth Required</span>}
							name="authRequired"
							style={!EditAccess ? { pointerEvents: "none" } : {}}
						>
							<Select
								showSearch
								allowClear
								placeholder="Select a auth required"
								optionFilterProp="children"
								// onChange={onChange}
								onChange={handleAuthRequiredChange}
								onSearch={onSearch}
								filterOption={filterOption}
								options={[
									{
										value: "YES",
										label: "YES",
									},
									{
										value: "NO",
										label: "NO",
									},
									{
										value: "VARIES",
										label: "VARIES",
									},
									{
										value: "N/A",
										label: "N/A",
									},
									{
										value: "U/A",
										label: "U/A",
									},
								]}
								style={!EditAccess ? { pointerEvents: "none" } : {}}
							/>
						</Form.Item>

						{isVariesSelected && (
							<Form.Item
								label="Additional Details for VARIES"
								name="authVariesDetails"
								rules={[
									{
										required: true,
										message: "Please provide details for VARIES!",
									},
								]}
							>
								<Input.TextArea placeholder="Enter additional details" maxLength={500} rows={3} />
							</Form.Item>
						)}

						<Form.Item
							className="mb-2"
							label={<span className="text-red-500">Chart Status</span>}
							name="vfCategory"
							rules={[{ required: true, message: "Please select the Chart Status!" }]}
							style={!EditAccess ? { pointerEvents: "none" } : {}}
						>
							<Select
								showSearch
								allowClear
								placeholder="Select a Chart Status"
								optionFilterProp="children"
								onChange={onChangeVFStatus}
								onSearch={onSearch}
								filterOption={filterOption}
								options={VFCategory}
								style={!EditAccess ? { pointerEvents: "none" } : {}}
							/>
						</Form.Item>
						<Form.Item
							className="mb-2"
							label="S&S Received Equipment In the Last 6 Months?"
							name="ssReceived"
							style={!EditAccess ? { pointerEvents: "none" } : {}}
						>
							<Select
								showSearch
								allowClear
								placeholder="Select a s and s received equipment in the last six months"
								optionFilterProp="children"
								onChange={onChange}
								onSearch={onSearch}
								filterOption={filterOption}
								options={SsReceivedEquipment}
								style={!EditAccess ? { pointerEvents: "none" } : {}}
							/>
						</Form.Item>
						<Form.Item
							className="mb-2"
							label="Prescription Availability"
							name="consultationStatus"
							style={!EditAccess ? { pointerEvents: "none" } : {}}
						>
							<Select
								showSearch
								allowClear
								placeholder="Select a Prescription Availability"
								optionFilterProp="children"
								onChange={onChange}
								onSearch={onSearch}
								filterOption={filterOption}
								options={ConsultationStatus}
								style={!EditAccess ? { pointerEvents: "none" } : {}}
							/>
						</Form.Item>
						<Form.Item className="mb-2" label="VF Comments" name="vfComments">
							<TextArea maxLength={1000} readOnly={!EditAccess} />
						</Form.Item>
					</Col>
					<Col
						{...colProps}
						// xl={6} lg={6} md={8} sm={12} xs={24}
					>
						<Form.Item
							className="mb-2"
							label="Subsequent VF Date"
							name="subsequentScanDate"
							style={!EditAccess ? { pointerEvents: "none" } : {}}
						>
							<DatePicker
								className="w-full"
								onChange={onChangeDatePicker}
								format={dateFormat}
								style={!EditAccess ? { pointerEvents: "none" } : {}}
								disabledDate={disableOutOfCurrentYear}
							/>
						</Form.Item>
						<Form.Item
							className="mb-2"
							label="Subsequent Quarter To Verify"
							name="subsequentQuarter"
							style={!EditAccess ? { pointerEvents: "none" } : {}}
						>
							<Select
								showSearch
								allowClear
								placeholder="Select Subsequent Quarter To Verify"
								optionFilterProp="children"
								onChange={onChange}
								onSearch={onSearch}
								filterOption={filterOption}
								options={[
									{
										value: "Q1",
										label: "Q1",
									},
									{
										value: "Q2",
										label: "Q2",
									},
									{
										value: "Q3",
										label: "Q3",
									},
									{
										value: "Q4",
										label: "Q4",
									},
								]}
								style={{ pointerEvents: "none" }}
							/>
						</Form.Item>
						<Form.Item className="mb-2" label="Transaction ID/SSA" name="transaction">
							<Input readOnly={!EditAccess} />
						</Form.Item>

						<Form.Item
							className="mb-2"
							label="Calling Assigned Reason"
							name="callingAssigned"
							style={!EditAccess ? { pointerEvents: "none" } : {}}
						>
							<Select
								showSearch
								allowClear
								placeholder="Select a calling assigned reason"
								optionFilterProp="children"
								onChange={onChange}
								onSearch={onSearch}
								filterOption={filterOption}
								options={CallingAssignedReason}
								style={!EditAccess ? { pointerEvents: "none" } : {}}
							/>
						</Form.Item>
						{showCallingAddedField ? (
							<Form.Item
								className="mb-2"
								label="Calling Added On"
								name="callingAdded"
								style={!EditAccess ? { pointerEvents: "none" } : {}}
							>
								<DatePicker
									className="w-full"
									value={callingAddedDate ? dayjs(callingAddedDate, dateFormat) : null}
									onChange={onChangeCallingAddedDatePicker}
									format={dateFormat}
									disabledDate={disabledDate}
									style={!EditAccess ? { pointerEvents: "none" } : {}}
								/>{" "}
								<Button type="link" onClick={handleRemoveCallingAdded} disabled={!EditAccess}>
									Remove Calling Added On
								</Button>
							</Form.Item>
						) : (
							<Button type="link" onClick={handleAddCallingAdded} disabled={!EditAccess}>
								Add Calling Added On
							</Button>
						)}
						<Form.Item
							className="mb-2"
							label="Delivery Status"
							name="deliveryStatus"
							style={!EditAccess ? { pointerEvents: "none" } : {}}
						>
							<Select
								showSearch
								placeholder="Select a delivery status"
								optionFilterProp="children"
								onChange={onChange}
								onSearch={onSearch}
								filterOption={filterOption}
								options={[
									{
										value: "YES",
										label: "YES",
									},
									{
										value: "NO",
										label: "NO",
									},
								]}
								style={!EditAccess ? { pointerEvents: "none" } : {}}
							/>
						</Form.Item>
						{deliveredOnField && (
							<Form.Item
								className="mb-2"
								label="Delivered On"
								name="deliveredOn"
								style={!EditAccess ? { pointerEvents: "none" } : {}}
							>
								<DatePicker
									multiple
									className="w-full"
									format={dateFormat}
									style={!EditAccess ? { pointerEvents: "none" } : {}}
								/>
							</Form.Item>
						)}
						<Form.Item className="mb-2" label="Internal Notes Space Column" name="internalNotes">
							<TextArea maxLength={1000} readOnly={!EditAccess} />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col
						// {...colProps}
						xl={12}
						lg={12}
						md={12}
						sm={12}
						xs={12}
					>
						<Form.Item className="mb-2">{/* <Button className="w-fit mt-2 font-medium">Cancel</Button> */}</Form.Item>
					</Col>
					<Col
						// {...colProps}
						xl={12}
						lg={12}
						md={12}
						sm={12}
						xs={12}
					>
						<Form.Item className="mb-2">
							{role !== "VERIFICATION CALLER" && role !== "AUDIT CALLER" && !isFromUpdate && !callerSpaceVisible && (
								<Button
									className="float-right mt-2 mr-2 w-fit bg-green-400 font-medium"
									type="primary"
									onClick={handleSubmitChart}
								>
									{role === "AUDIT" ? "Sign Off" : "Submit Chart"}
								</Button>
							)}
							<Button
								className="float-right mt-2 mr-2 w-fit bg-blue-400 font-medium"
								type="primary"
								htmlType="submit"
								disabled={!EditAccess}
							>
								Save
							</Button>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</div>
	);
};

export default memo(CoverageDetails);
