import { Col, Row, Table } from "antd";
import dayjs from "dayjs";
import { memo } from "react";
import CountUp from "react-countup";
import divIcon from "../../assets/svg_images/Icon.svg";

const ChartsSummary = memo(({ getUserProject, isWeek, datas }) => {
	const columns = [
		{
			title: <span className="text-center font-bold font-poppins text-xs">Verification Status</span>,
			dataIndex: "age",
			width: "25%",
			responsive: ["xs", "sm"],
			render: (text) => <span className="font-medium font-poppins text-xs">{text}</span>,
		},
		{
			title: <span className="text-center font-bold font-poppins text-xs">Record Count</span>,
			dataIndex: "address",
			width: "25%",
			responsive: ["xs", "sm"],
			render: (text) => <span className="font-medium font-poppins text-xs">{text}</span>,
		},
	];

	return (
		<Col
			xs={24}
			sm={24}
			md={24}
			lg={24}
			xl={12}
			className="h-[100%] rounded-xl border border-white p-2 shadow-lg"
			style={{
				boxShadow: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
			}}
		>
			<Row className="mq450:flex mq450:w-[300px] mq450:flex-col mq450:justify-center p-2">
				<Col span={8}>
					<div className="mq450:ms-2 mb-5 flex h-[215px] mq450:w-[200px] w-[100%] items-center justify-center rounded-2xl bg-[#458ff6] bg-opacity-10">
						<div className="p-4 text-center ">
							<img src={divIcon} alt="Card Icon" className="mb-2" />
							<div className="font-poppins text-[#458ff6]">
								<div className="font-extrabold text-[1.928rem]">
									<CountUp end={getUserProject?.length} duration={2} />
								</div>
								{isWeek && <small>( # of Charts this Week )</small>}
								<div className="font-semibold mq450:text-sm text-sm">Grand Total</div>
								<div className="overflow-hidden text-ellipsis whitespace-normal font-light mq450:text-sm text-sm">
									In Verification Repository {dayjs()?.format("YYYY")}
								</div>
							</div>
						</div>
					</div>
				</Col>

				<Col span={16}>
					<div className="custom-table ml-[5%] mq450:w-[100%] max-w-[100%]">
						<div className="rounded-lg border">
							<div className="custom-ant-table-design mq450:w-[200px]">
								<Table
									columns={columns}
									dataSource={datas}
									pagination={false}
									scroll={{ y: 170, x: "max-content" }}
									className="max-h-[100%]"
									size="small"
									rowHoverable={false}
									locale={{
										emptyText: (
											<div style={{ textAlign: "center" }}>
												<div>Data Not Found</div>
											</div>
										),
									}}
								/>
							</div>
						</div>
					</div>
				</Col>
			</Row>
		</Col>
	);
});

export default ChartsSummary;
