import { InfoCircleOutlined, SmileOutlined } from "@ant-design/icons";
import { Badge, Button, Col, DatePicker, Form, Row, Select, Table, Tooltip } from "antd";
import dayjs from "dayjs";
import _ from "lodash";
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useState } from "react";
import Highlighter from "react-highlight-words";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { VFCategory } from "../../assets/DispCodes";
import ModalBox from "../../components/ModalBox";
import useSearchBasedCharts from "../../pagination/useSearchBasedCharts";
import { resetChartViewState, setChartDetailView } from "../../redux/ChartViewManagement/ChartViewManagementSlice";
import { getUsersByRoleAndProjectApi } from "../../redux/user/userService";
import { axiosErrorHandling } from "../../utils/utility";
import TabPanel from "../SuperAdmin/Dashboard/ChartDetails/TabPanel";
import { FiShare2 } from "react-icons/fi";
import ChartInfo from "../../components/ChartInfo";
import CreateShare from "../Share/CreateShare";
import {setShareModalVisible } from "../../redux/ShareChartStateManagement/ShareChartStateManagementSlice";


const { RangePicker } = DatePicker;

const useQueryParams = () => {
	const { search } = useLocation();
	return useMemo(() => Object.fromEntries(new URLSearchParams(search)), [search]);
};

const ChartSearchResultsTable = () => {
	// Initialize useSearchBasedCharts hook
	const [form] = Form.useForm();
	const queryParams = useQueryParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const projectId = useSelector((state) => state.userDetails.project);
	const role = useSelector((state) => state.userDetails.role);
	const [selectedChartData, setSelectedChartData] = useState(null);

	const [calledByOptions, setCalledByOptions] = useState([]);
	const [verifyByOptions, setVerifyByOptions] = useState([]);

	const {
		Status = "",
		Insurance = "",
		Range = "",
		AssignedUser = "",
		callingBy = "",
		verifyBy = "",
		deliveryStatus = "",
		deliveredOn = "",
		prescriptionReceivedOn = "",
		additionalClinicalInfoIncluded = "",
		resolvable = "",
	} = queryParams;

	const {
		charts: data,
		chartsLoading: loading,
		pageSize,
		totalCharts,
		currentPage,
		searchTerm,
		setSearchTerm,
		setCurrentPage,
		setPageSize,
		setCharts,
		setFilters,
	} = useSearchBasedCharts({
		page: Number(queryParams.page) || 1,
		limit: Number(queryParams.limit) || 10,
		search: queryParams.q || "",
		filters: {
			Status: Status ? Status.split(",") : [],
			Insurance: Insurance ? Insurance.split(",") : [],
			Range: Range ? Range.split(",") : [],
			AssignedUser: AssignedUser ? AssignedUser.split(",") : [],
			callingBy: callingBy ? callingBy.split(",") : [],
			verifyBy: verifyBy ? verifyBy.split(",") : [],
			deliveryStatus: deliveryStatus ? deliveryStatus : "",
			deliveredOn: deliveredOn ? deliveredOn : "",
			prescriptionReceivedOn: prescriptionReceivedOn ? prescriptionReceivedOn : "",
			resolvable: resolvable ? resolvable : "",
			additionalClinicalInfoIncluded: additionalClinicalInfoIncluded ? additionalClinicalInfoIncluded : "",
		},
		projectId: projectId,
	});

	const [filteredPatientName, setFilteredPatientName] = useState([]);
	const [filteredVfStatus, setFilteredVfStatus] = useState([]);
	// const [filteredStatus, setFilteredStatus] = useState([]);
	// const [filteredInsurance, setFilteredInsurance] = useState([]);
	const [showDetailView, setShowDetailView] = useState(false);
	const ChartDetailView = useSelector((state) => state.chartViewManagement.chartDetailView);
	const [isInfoVisible, setIsInfoVisible] = useState(false);
	const [searchText, setSearchText] = useState("");
	const getContent = () => {
		return (
			<TabPanel
				ChartDetailView={ChartDetailView}
				charts={data}
				setCharts={setCharts}
				setChartDetailView={setChartDetailView}
				form={form}
			/>
		);
	};

	const getBaseURL = (role) => {
		switch (role) {
			case "SUPER ADMIN":
				return "/super-admin";
			case "ADMIN":
				return "/admin";
			case "PROVIDER":
			case "USER":
			case "is":
				return "/provider";
			case "VERIFICATION":
			case "AUDIT":
			case "VERIFICATION CALLER":
			case "AUDIT CALLER":
			case "CODING":
			case "GUEST":
				return "/users";
			default:
				return "/"; // Default fallback, if no role matches
		}
	};

	// Update filters in the URL and trigger API call
	const updateFilters = (updatedFilters) => {
		const baseURL = getBaseURL(role);
		// Start with existing query parameters
		const mergedQueryParams = { ...queryParams };

		// Merge updated filters with existing ones
		Object.entries(updatedFilters).forEach(([filterName, newValue]) => {
			if (newValue !== undefined) {
				let updatedValues;

				// Handle non-string values like pagination (numbers) or arrays
				if (typeof newValue === "string") {
					const existingValues = mergedQueryParams[filterName]?.split(",") || [];
					updatedValues = Array.from(new Set([...existingValues, ...(newValue.split(",") || [])]));
				} else {
					// Directly assign non-string values (e.g., numbers for page/limit)
					updatedValues = newValue;
				}

				mergedQueryParams[filterName] = Array.isArray(updatedValues) ? updatedValues.join(",") : updatedValues;
			} else {
				delete mergedQueryParams[filterName];
			}
		});

		if (!searchTerm.trim()) {
			delete mergedQueryParams.q;
		}

		// Construct sanitized query parameters
		const sanitizedQueryParams = Object.fromEntries(
			Object.entries(mergedQueryParams).filter(([_, val]) => val !== undefined && val !== ""),
		);

		// Update the URL
		navigate(`${baseURL}/search-charts?${new URLSearchParams(sanitizedQueryParams).toString()}`);
	};

	// Handle filter and pagination changes
	const handleFilterChange = useCallback(
		(filterName, value) => {
			// Normalize the value to an array
			const newValues = Array.isArray(value) ? value : typeof value === "string" ? [value] : [];

			// Pass the new filter value as a comma-separated string
			updateFilters({
				[filterName]: newValues.length ? newValues.join(",") : undefined,
			});

			// Update local state for filters
			setFilters((prevFilters) => ({
				...prevFilters,
				[filterName]: newValues,
			}));

			// Clear searchTerm if needed and ensure API call
			if (!searchTerm.trim() && !newValues.length) {
				setSearchTerm("");
			}
		},
		[searchTerm, updateFilters],
	);

	// Fetch Called By and Verify By options dynamically
	useEffect(() => {
		const fetchOptions = async () => {
			try {
				// Fetch users for the "Called By" role
				const calledByResponse = await dispatch(
					getUsersByRoleAndProjectApi({ roleName: "VERIFICATION CALLER", projectId }),
				).unwrap(); // Replace CALLER_ROLE_ID with the actual role ID for "Called By"
				setCalledByOptions(
					calledByResponse.map((user) => ({
						value: user.id,
						label: `${user.firstName || ""} ${user.lastName || ""}`.trim(),
					})),
				);

				// Fetch users for the "Verified By" role
				const verifyByResponse = await dispatch(
					getUsersByRoleAndProjectApi({ roleName: "VERIFICATION", projectId }),
				).unwrap(); // Replace VERIFIER_ROLE_ID with the actual role ID for "Verified By"
				setVerifyByOptions(
					verifyByResponse.map((user) => ({
						value: user.id,
						label: `${user.firstName || ""} ${user.lastName || ""}`.trim(),
					})),
				);
			} catch (error) {
				// console.error("Error fetching user options:", error);
				axiosErrorHandling(error);
			}
		};

		fetchOptions();
	}, [dispatch, projectId]);

	const onDateChange = (date, dateString) => {
		if (!dateString[0] && !dateString[1]) {
			handleFilterChange("Range", undefined);
		} else {
			const selectedRangeArray = dateString ? dateString : [];
			handleFilterChange("Range", selectedRangeArray);
		}
	};

	const disabledEndDate = (current) => {
		// Disable dates for end date beyond today or before the selected start date
		if (!current) return false;
		const today = dayjs().startOf("day");
		return current?.isAfter(today, "day");
	};

	useEffect(() => {
		setSearchTerm(queryParams.q || "");
		setSearchText(queryParams.q || "");
		setPageSize(Number(queryParams.limit) || 10);
		setCurrentPage(Number(queryParams.page) || 1);
	}, [queryParams, setSearchTerm, setPageSize, setCurrentPage]);

	const getUniqueValues = (key, data) => {
		const values = data.map((item) => item[key]);
		return Array.from(new Set(values));
	};

	const uniqueInsurance = getUniqueValues("primaryInsurance", data);
	const uniqueInsuranceOptions = uniqueInsurance
		.filter((insurance) => typeof insurance === "string" && insurance.trim() !== "")
		.map((insurance) => ({
			value: insurance,
			label: insurance,
		}));

	const uniqueStatusOptions = [
		{ value: "YES", label: "YES" },
		{ value: "NO", label: "NO" },
	];

	const handlePatientClick = (id) => {
		if (id) {
			const getContact = _.filter(data, (item) => item.id === id);
			dispatch(
				setChartDetailView({
					selected: true,
					data: getContact[0],
				}),
			);
			setShowDetailView(true);
			setSelectedChartData(getContact[0]);
		}
	};

	const handlePaginationChange = (currentPage, pageSize) => {
		updateFilters({ page: currentPage, limit: pageSize });
	};

	const handleStatusChange = (value) => {
		handleFilterChange("Status", value);
	};

	const handleInsuranceChange = (value) => {
		handleFilterChange("Insurance", value);
	};

	const handleCalledBYChange = (value) => {
		handleFilterChange("callingBy", value);
	};

	const handleVerifyByChange = (value) => {
		handleFilterChange("verifyBy", value);
	};

	const handleDeliveryStatus = (value) => {
		handleFilterChange("deliveryStatus", value);
	};

	const handleResolvableStatus = (value) => {
		handleFilterChange("resolvable", value);
	};

	const handleAdditionalClinicalInfoIncludedStatus = (value) => {
		handleFilterChange("additionalClinicalInfoIncluded", value);
	};

	const handleDeliveredOnChange = (date, dateString) => {
		const selectedDate = dateString ? dateString : [];
		handleFilterChange("deliveredOn", selectedDate);
	};

	const handlePrescriptionReceivedOn = (date, dateString) => {
		const selectedDate = dateString ? dateString : [];
		handleFilterChange("prescriptionReceivedOn", selectedDate);
	};

	const onChange = (pagination, filters) => {
		setCurrentPage(pagination.current); // Updates the page
		setPageSize(pagination.pageSize);
		updateFilters({ ...filters });
		Object.entries(filters).forEach(([key, value]) => {
			handleFilterChange(key, value);
		});
		setFilteredPatientName(filters.patientName || []);
		setFilteredVfStatus(filters.status || []);
	};

	const columns = [
		{
			title: "Patient Recevied On",
			dataIndex: "receivedOn",
			key: "receivedOn",
		},
		{
			title: "Patient Name",
			dataIndex: "patientName",
			key: "patientName",
			render: (text, record) => (
				<Tooltip title="Click to view patient overview">
					<Button
						className="hover:text-blue-bm"
						type="button"
						onClick={(e) => {
							e.preventDefault();
							handlePatientClick(record.id);
						}}
					>
						<Highlighter
							highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
							searchWords={[searchText]}
							autoEscape
							textToHighlight={text ? text.toString() : ""}
						/>
					</Button>
				</Tooltip>
			),
		},
		{
			title: "DOB",
			dataIndex: "dob",
			key: "dob",
		},
		{
			title: "Primary Insurance",
			dataIndex: "primaryInsurance",
			key: "primaryInsurance",
		},
		{
			title: "Chart Status",
			dataIndex: "vfCategory",
			key: "vfCategory",
		},
		{
			title: "Delivered On",
			dataIndex: "deliveredOn",
			key: "deliveredOn",
		},
	];

	return (
		<div data-testid="searchResult-component">
			<Row gutter={[16, 16]} className="mb-4">
				<Col span={6}>
					<RangePicker
						className="custom-range-picker w-full"
						allowClear
						placeholder={["Select Start Date", "Select End Date"]}
						format={"MM/DD/YYYY"}
						onChange={onDateChange}
						disabledDate={disabledEndDate}
					/>
				</Col>
				<Col span={6}>
					<DatePicker
						className="custom-range-picker w-full"
						allowClear
						placeholder="Delivered On"
						onChange={handleDeliveredOnChange}
						format={"MM/DD/YYYY"}
					/>
				</Col>
				<Col span={6}>
					<DatePicker
						className="custom-range-picker w-full"
						allowClear
						placeholder="Prescription Received On"
						onChange={handlePrescriptionReceivedOn}
						format={"MM/DD/YYYY"}
					/>
				</Col>
				<Col span={6}>
					<Select
						mode="multiple"
						allowClear
						placeholder="Primary Insurance"
						onChange={handleInsuranceChange}
						style={{ width: "100%" }}
						options={uniqueInsuranceOptions}
					/>
				</Col>
			</Row>
			<Row gutter={[16, 16]} className="mb-4">
				<Col span={6}>
					<Select
						mode="multiple"
						allowClear
						placeholder="Chart Status"
						onChange={handleStatusChange}
						style={{ width: "100%" }}
						options={VFCategory}
					/>
				</Col>

				<Col span={6}>
					<Select
						allowClear
						placeholder="Delivery Status"
						onChange={handleDeliveryStatus}
						style={{ width: "100%" }}
						options={uniqueStatusOptions}
					/>
				</Col>

				<Col span={6}>
					<Select
						allowClear
						placeholder="Resolvable Status"
						onChange={handleResolvableStatus}
						style={{ width: "100%" }}
						options={uniqueStatusOptions}
					/>
				</Col>
				<Col span={6}>
					<Select
						allowClear
						placeholder="Additional Clinical Info Status"
						onChange={handleAdditionalClinicalInfoIncludedStatus}
						style={{ width: "100%" }}
						options={uniqueStatusOptions}
					/>
				</Col>
			</Row>

			<Row gutter={[16, 16]} className="mb-4">
				<Col span={6}>
					<Select
						mode="multiple"
						allowClear
						placeholder="Called By"
						onChange={handleCalledBYChange}
						options={calledByOptions}
						style={{ width: "100%" }}
					/>
				</Col>
				<Col span={6}>
					<Select
						mode="multiple"
						allowClear
						placeholder="Verified By"
						onChange={handleVerifyByChange}
						options={verifyByOptions}
						style={{ width: "100%" }}
					/>
				</Col>
			</Row>
			<div className="no-scrollbar mt-2 max-h-[55vh] overflow-auto">
				<div className="min-w-[600px] sm:min-w-full">
					<div className="mb-2 rounded-md border shadow-md">
						<div className="custom-ant-table-design">
							<Table
								size="small"
								columns={columns}
								dataSource={data}
								loading={loading}
								rowKey="id"
								rowHoverable={false}
								pagination={{
									current: currentPage,
									pageSize: pageSize,
									total: filteredPatientName.length > 0 || filteredVfStatus.length > 0 ? data.length : totalCharts,
									showTotal: (total) => `Total ${total} charts`,
									showQuickJumper: true,
									showSizeChanger: true,
									onChange: (page, size) => handlePaginationChange(page, size),
								}}
								onChange={onChange}
								locale={{
									emptyText: (
										<div style={{ textAlign: "center" }}>
											<SmileOutlined style={{ fontSize: 20 }} />
											<p>Data Not Found</p>
										</div>
									),
								}}
								scroll={{ y: 250 }}
							/>
						</div>
					</div>
				</div>
			</div>
			{showDetailView && (
				<ModalBox
					open={showDetailView}
					title={
						<div style={{ display: "flex", alignItems: "center" }}>
							Chart Overview
							<Tooltip title="Chart Status">
								<Badge
									count={ChartDetailView?.data?.vfCategory || ""}
									showZero
									color="#faad14"
									style={{
										marginLeft: 8,
										fontWeight: 500,
										paddingTop: "2px",
										paddingBottom: "2px",
										height: "auto",
										letterSpacing: 2,
										fontSize: 14,
										borderRadius: 5,
									}}
								/>
							</Tooltip>
							<Tooltip title="View Chart Info">
								<InfoCircleOutlined
									style={{ marginLeft: 8, cursor: "pointer" }}
									onClick={() => {
										setIsInfoVisible(!isInfoVisible);
									}}
								/>
							</Tooltip>
							{/* <Tooltip title="Share">
								<FiShare2
									style={{ marginLeft: 8, cursor: "pointer" }}
									onClick={() => {
										dispatch(setShareModalVisible(true))
									}}
								/>
							</Tooltip> */}
						</div>
					}
					width="xl"
					content={getContent()}
					onCancel={() => {
						dispatch(setChartDetailView({ selected: false, data: [] }));
						dispatch(resetChartViewState()); // Reset all states on close
						setShowDetailView(false);
					}}
				/>
			)}
			{isInfoVisible && (
						<ChartInfo isInfoVisible={isInfoVisible} setIsInfoVisible={setIsInfoVisible} chartData={selectedChartData} />
			)}
			{ 
				<CreateShare/>
			}
		</div>
	);
};

export default ChartSearchResultsTable;
