import api from "../../api";

const baseUrl = "/api/user";

export const getUsers = () => {
	return api.get(baseUrl, { withCredentials: true });
	// return api.get(baseUrl);
};

export const addUser = (postData) => {
	return api.post(baseUrl, postData, { withCredentials: true });
};

export const getPaginatedInternalUsers = (page, limit, searchTerm) => {
	return api.get(`${baseUrl}/users`, {
		params: {
			page,
			limit,
			search: searchTerm,
		},
		withCredentials: true, // Ensure it is inside the second object
	});
};

export const updateUser = (updateId, putData) => {
	return api.put(`${baseUrl}/${updateId}`, putData, { withCredentials: true });
};

export const deleteUser = (deleteId) => {
	return api.delete(`${baseUrl}/${deleteId}`, { withCredentials: true });
};

// Fetch users by role and project.
export const getUsersByRoleAndProject = (roleName, projectId) => {
	return api.get(`${baseUrl}/getUserByRoleAndProject`, {
		params: {
			roleName, // Pass the role ID as a query parameter if provided
			projectId, // Pass the project ID as a query parameter if provided
		},
		withCredentials: true,
	});
};
