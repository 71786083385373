import api from "../../api";

const baseUrl = "/api/notification";

export const getNotifications = () => {
	return api.get(baseUrl, { withCredentials: true });
};

export const getPaginatedNotifications = (
	page,
	limit,
	search = "",
	userId,
	userProject,
	role,
	startDate,
	endDate,
	Status,
) => {
	return api.get(`${baseUrl}`, {
		params: {
			page,
			limit,
			search,
			userId,
			userProject,
			role,
			startDate,
			endDate,
			Status,
		},
		withCredentials: true, // Ensure it is inside the second object
	});
};

export const getTodayNotifications = (queryText) => {
	// Append the query as a query parameter if provided
	const queryParam = queryText
		? `?roleId=${encodeURIComponent(queryText.roleId)}&role=${encodeURIComponent(queryText.role)}&userProject=${encodeURIComponent(queryText.userProject)}&userId=${encodeURIComponent(queryText?.userId?.trim()?.replace(/}$/, ""))}`
		: "";
	return api.get(`${baseUrl}/Today${queryParam}`, {
		withCredentials: true, // Ensure credentials are sent
	});
};

export const markNotificationAsRead = (updateData) => {
	return api.put(
		`/api/notification/${updateData.notificationId}`,
		{
			readStatusId: updateData.readStatusId,
			userId: updateData?.userId,
			role: updateData?.role,
			type: updateData?.type,
		},
		{
			withCredentials: true, // Ensure credentials are sent with the request
		},
	);
};

export const addNotification = (postData) => {
	return api.post(baseUrl, postData, {
		withCredentials: true, // Ensures credentials are sent with the request
	});
};

export const updateNotification = (updateId, putData) => {
	return api.put(`${baseUrl}/${updateId}`, putData, {
		withCredentials: true, // Ensures credentials are sent with the request
	});
};

export const deleteNotification = (deleteId, items) => {
	return api.delete(`${baseUrl}/${deleteId}`, {
		data: { items },
		withCredentials: true, // Ensures credentials are sent with the request
	});
};
