import { createSlice } from "@reduxjs/toolkit";
import { deleteScheduleApi, getScheduleListApi, scheduleSendApi, updateScheduleApi } from "./scheduleSendService.js";
const initialState = {
	scheduleSendList: [],
	loading: "idle",
	currentRequestId: undefined,
	error: null,
};
export const scheduleSlice = createSlice({
	name: "scheduleSend",
	initialState,
	reducers: {
		// add your non-async reducers here
		logout: (state) => {
			state.scheduleSendList = [];
			state.loading = "idle";
			state.currentRequestId = undefined;
			state.error = null;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(scheduleSendApi.pending, (state, action) => {
				if (state.loading === "idle") {
					state.loading = "pending";
					state.currentRequestId = action.meta.requestId;
				}
			})
			.addCase(scheduleSendApi.fulfilled, (state, action) => {
				const { requestId } = action.meta;
				if (state.loading === "pending" && state.currentRequestId === requestId) {
					state.loading = "idle";
					state.scheduleSendList.unshift(action.payload);
					state.currentRequestId = undefined;
				}
			})
			.addCase(scheduleSendApi.rejected, (state, action) => {
				const { requestId } = action.meta;
				if (state.loading === "pending" && state.currentRequestId === requestId) {
					state.loading = "idle";
					// state.error = action.error;
					state.currentRequestId = undefined;
					if (action.payload) {
						state.error = action.payload.errorMessage;
					} else {
						state.error = action.error.message;
					}
				}
			});
		builder
			.addCase(getScheduleListApi.pending, (state, action) => {
				if (state.loading === "idle") {
					state.loading = "pending";
					state.currentRequestId = action.meta.requestId;
				}
			})
			.addCase(getScheduleListApi.fulfilled, (state, action) => {
				const { requestId } = action.meta;
				if (state.loading === "pending" && state.currentRequestId === requestId) {
					state.loading = "idle";
					state.scheduleSendList.length = 0;
					state.scheduleSendList.push(...action.payload);
					state.currentRequestId = undefined;
				}
			})
			.addCase(getScheduleListApi.rejected, (state, action) => {
				const { requestId } = action.meta;
				if (state.loading === "pending" && state.currentRequestId === requestId) {
					state.loading = "idle";
					// state.error = action.error;
					state.currentRequestId = undefined;
					if (action.payload) {
						state.error = action.payload.errorMessage;
					} else {
						state.error = action.error.message;
					}
				}
			});
		builder
			.addCase(updateScheduleApi.pending, (state, action) => {
				if (state.loading === "idle") {
					state.loading = "pending";
					state.currentRequestId = action.meta.requestId;
				}
			})
			.addCase(updateScheduleApi.fulfilled, (state, action) => {
				const { requestId } = action.meta;
				if (state.loading === "pending" && state.currentRequestId === requestId) {
					const updateItem = action.payload;

					const index = state.scheduleSendList.findIndex((item) => item.id === updateItem.id);
					if (index > -1) {
						state.scheduleSendList[index] = updateItem;
					}
					state.loading = "idle";
					state.currentRequestId = undefined;
				}
			})
			.addCase(updateScheduleApi.rejected, (state, action) => {
				const { requestId } = action.meta;
				if (state.loading === "pending" && state.currentRequestId === requestId) {
					state.loading = "idle";
					// state.error = action.error;
					state.currentRequestId = undefined;
					if (action.payload) {
						state.error = action.payload.errorMessage;
					} else {
						state.error = action.error.message;
					}
				}
			});
		builder
			.addCase(deleteScheduleApi.pending, (state, action) => {
				if (state.loading === "idle") {
					state.loading = "pending";
					state.currentRequestId = action.meta.requestId;
				}
			})
			.addCase(deleteScheduleApi.fulfilled, (state, action) => {
				const { requestId } = action.meta;
				if (state.loading === "pending" && state.currentRequestId === requestId) {
					const index = state.scheduleSendList.findIndex((item) => item.id === action.payload?.result?.id);

					if (index > -1) {
						state.scheduleSendList.splice(index, 1);
					}
					state.loading = "idle";
					state.currentRequestId = undefined;
				}
			})
			.addCase(deleteScheduleApi.rejected, (state, action) => {
				const { requestId } = action.meta;
				if (state.loading === "pending" && state.currentRequestId === requestId) {
					state.loading = "idle";
					// state.error = action.error;
					state.currentRequestId = undefined;
					if (action.payload) {
						state.error = action.payload.errorMessage;
					} else {
						state.error = action.error.message;
					}
				}
			});
	},
});
export const { logout } = scheduleSlice.actions;

export default scheduleSlice.reducer;
