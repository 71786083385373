import { InfoCircleOutlined } from "@ant-design/icons";
import { FileTwoTone, PrinterTwoTone } from "@ant-design/icons";
// import { SearchOutlined } from "@ant-design/icons";
import {
	Alert,
	Badge,
	Button,
	Col,
	Dropdown,
	Form,
	Input,
	Modal,
	Radio,
	Row,
	Select,
	Spin,
	Tooltip,
	message,
	notification,
} from "antd";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { getExportFile } from "../../api/exportFile/exportFile";
import { States, VFCategory } from "../../assets/DispCodes";
import fullScreen from "../../assets/svg_images/fullscreen.svg";
import ChartInfo from "../../components/ChartInfo";
import ModalBox from "../../components/ModalBox";
import useFilteredCharts from "../../pagination/useFilteredCharts";
import { createNotificationApi } from "../../redux/notification/notificationService";
import { createOrUpdateRecentHistoryApi } from "../../redux/recentHistory/recentHistoryService";
import { addNumberToObjectArray, convertObjectToArray } from "../../utils/utility";
import BarChartComponent from "./BarChartComponent";
import TabPanel from "./ChartDetails/TabPanel";
import ChartsSummary from "./ChartSummary";
import PiChartComponent from "./PiChartComponent";
import "./CustomPrint.css";

import { resetChartViewState, setChartDetailView } from "../../redux/ChartViewManagement/ChartViewManagementSlice";
import { setChartId } from "../../redux/chartDocument/chartDocumentSlice";

const { RangePicker } = DatePicker;

const Dashboard = () => {
	const dispatch = useDispatch();
	const userProject = useSelector((state) => state.userDetails.userProject);
	const projectId = useSelector((state) => state.userDetails.project);
	const projectName = useSelector((state) => state.userDetails.projectName);
	const role = useSelector((state) => state.userDetails.role);
	const userAccess = useSelector((state) => state.userDetails.userAccess);
	// const [ChartDetailView, setChartDetailView] = useState({ selected: false, data: [] })
	const ChartDetailView = useSelector((state) => state.chartViewManagement.chartDetailView);
	const componentRef = useRef(null);
	const [loadings, setLoadings] = useState(false);
	const [modal1Open, setModal1Open] = useState(false);
	const [modal2Open, setModal2Open] = useState(false);
	const [searchQuery, setSearchQuery] = useState("");
	const [getUserProject, setGetUserProject] = useState([]);
	const { charts, setFilters, setSearchTerm, isWeek } = useFilteredCharts("", "", projectId);
	const { userId } = useSelector((state) => state.userDetails);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [exportType, setExportType] = useState("excel");
	const [selectedFormat, setSelectedFormat] = useState(null);
	const [isInfoVisible, setIsInfoVisible] = useState(false);
	const [form] = Form.useForm();
	const [ChartTabPanelForm] = Form.useForm(); // For the filter form
	const [warningVisible, setWarningVisible] = useState(false);

	const onChangeFormat = useCallback((value) => {
		setSelectedFormat(value);
		setExportType(value);
		setWarningVisible(value === "pdf");
	}, []);

	const handleFormatChange = (e) => {
		const value = e?.target?.value; // Directly get the value from the event object
		onChangeFormat(value);
	};

	const disabledEndDate = (current) => {
		// Disable dates for end date beyond today or before the selected start date
		if (!current) return false;
		const today = dayjs().startOf("day");
		return current?.isAfter(today, "day");
	};

	const onCalendarChange = useCallback((dates) => {}, []);

	const transformInsuranceData = (insuranceData) => {
		const insuranceCount = insuranceData.reduce((acc, item) => {
			if (!acc[item.primaryInsurance]) {
				acc[item.primaryInsurance] = {
					id: item.primaryInsurance,
					label: item.primaryInsurance,
					Completed: 0,
					Pending: 0,
					value: 0, // Initialize the total count
				};
			}
			if (item.auditCompleted) {
				acc[item?.primaryInsurance].Completed += 1;
			} else {
				acc[item?.primaryInsurance].Pending += 1;
			}
			acc[item?.primaryInsurance].value += 1; // Increment the total count
			return acc;
		}, {});

		return Object.keys(insuranceCount)?.map((key, index) => {
			return {
				...insuranceCount[key],
				// color: colorScale(index),
			};
		});
	};

	const transformToBarData = (insuranceData) => {
		const insuranceCount = insuranceData.reduce((acc, item) => {
			if (!acc[item.primaryInsurance]) {
				acc[item.primaryInsurance] = {
					insurance: item.primaryInsurance,
					Completed: 0,
					Pending: 0,
					value: 0, // Initialize the total count
				};
			}
			if (item.auditCompleted) {
				acc[item.primaryInsurance].Completed += 1;
			} else {
				acc[item.primaryInsurance].Pending += 1;
			}
			acc[item.primaryInsurance].value += 1; // Increment the total count
			return acc;
		}, {});

		return Object.keys(insuranceCount).map((key, index) => {
			return {
				insurance: key,
				Completed: insuranceCount[key].Completed,
				Pending: insuranceCount[key].Pending,
				value: insuranceCount[key].value,
				// color: colorScale(index),
			};
		});
	};

	const selectorOptions = getUserProject;

	const extractUniqueValues = (filterChart) => {
		const uniqueValues = new Set(filterChart);
		return Array.from(uniqueValues).map((value) => ({ value, label: value }));
	};

	const extractProductUniqueValues = (filterChart, key) => {
		const uniqueValues = new Set();
		const result = [];

		filterChart.forEach((item) => {
			const value = item[key];
			if (typeof value === "string") {
				// Handle single comma-separated lists
				value
					?.split(",")
					?.map((v) => v?.trim())
					?.forEach((v) => {
						if (v && !uniqueValues?.has(v)) {
							uniqueValues?.add(v);
							result?.push({ value: v, label: v });
						}
					});
			}
		});

		return result;
	};

	const vfRequestedEquipmentModelDetails = selectorOptions?.flatMap((item) => item.vfRequestedEquipmentModelDetails);

	const primaryInsuranceDetails = selectorOptions?.flatMap((item) => item.primaryInsurance);

	const uniqueEquipmentList = useMemo(
		() => extractProductUniqueValues(vfRequestedEquipmentModelDetails, "equipmentName"),
		[vfRequestedEquipmentModelDetails],
	);

	const primaryInsuranceList = useMemo(
		() => extractUniqueValues(primaryInsuranceDetails, "primaryInsurance"),
		[primaryInsuranceDetails],
	);

	useEffect(() => {
		if (userProject !== null) {
			if (role === "SUPER ADMIN") {
				setGetUserProject(charts);
			} else {
				const getFilteredProject = charts?.filter((items) => {
					return items?.project?.name === projectName;
				});

				setGetUserProject(getFilteredProject);
			}
		}
	}, [charts, projectName, role, userProject]);

	const count = {
		"Moved to PA": 0,
		Approved: 0,
		Approved_Closed: 0,
		"Approved_LMN_VF Closed": 0,
		"Approved_POD_VF Closed": 0,
		"In-Active": 0,
		"Missing Demo/Insurance Info": 0,
		"MR_VF Closed": 0,
		"PA Approved": 0,
		"PA Approved_Closed": 0,
		"PA Approved_POD _VF Closed": 0,
		"PA Denied_Closed": 0,
		"PA Held": 0,
		"PA LMN_VF Closed": 0,
		"PA Pending": 0,
		"Subsequent VF In-Process": 0,
		"VF Closed": 0,
		"VF Denied_Closed": 0,
		"VF Held": 0,
		"VF In-Process": 0,
	};

	// eslint-disable-next-line no-unused-vars
	let totalCount = 0;

	getUserProject.forEach((item) => {
		switch (item.vfCategory) {
			case "Moved to PA":
				count["Moved to PA"]++;
				totalCount++;
				break;
			case "Approved":
				count["Approved"]++;
				totalCount++;
				break;
			case "Approved_Closed":
				count["Approved_Closed"]++;
				totalCount++;
				break;
			case "Approved_LMN_VF Closed":
				count["Approved_LMN_VF Closed"]++;
				totalCount++;
				break;
			case "Approved_POD_VF Closed":
				count["Approved_POD_VF Closed"]++;
				totalCount++;
				break;
			case "In-Active":
				count["In-Active"]++;
				totalCount++;
				break;
			case "Missing Demo/Insurance Info":
				count["Missing Demo/Insurance Info"]++;
				totalCount++;
				break;
			case "MR_VF Closed":
				count["MR_VF Closed"]++;
				totalCount++;
				break;
			case "PA Approved":
				count["PA Approved"]++;
				totalCount++;
				break;
			case "PA Approved_Closed":
				count["PA Approved_Closed"]++;
				totalCount++;
				break;
			case "PA Approved_POD _VF Closed":
				count["PA Approved_POD _VF Closed"]++;
				totalCount++;
				break;
			case "PA Denied_Closed":
				count["PA Denied_Closed"]++;
				totalCount++;
				break;
			case "PA Held":
				count["PA Held"]++;
				totalCount++;
				break;
			case "PA LMN_VF Closed":
				count["PA LMN_VF Closed"]++;
				totalCount++;
				break;
			case "PA Pending":
				count["PA Pending"]++;
				totalCount++;
				break;
			case "Subsequent VF In-Process":
				count["Subsequent VF In-Process"]++;
				totalCount++;
				break;
			case "VF Closed":
				count["VF Closed"]++;
				totalCount++;
				break;
			case "VF Denied_Closed":
				count["VF Denied_Closed"]++;
				totalCount++;
				break;
			case "VF Held":
				count["VF Held"]++;
				totalCount++;
				break;
			case "VF In-Process":
				count["VF In-Process"]++;
				totalCount++;
				break;
			default:
				break;
		}
	});

	const datas = Object.entries(count).map(([category, count]) => ({
		key: category,
		age: category,
		address: count,
	}));

	const handleModalOk = async () => {
		try {
			const values = await form.validateFields();
			const { fileName, password } = values;
			handleDownload(fileName, password, exportType);
			setIsModalVisible(false);
		} catch (error) {
			console.error("Validation failed:", error);
		}
	};

	const chartData = {
		/* Patient Details */
		no: "SL.No",
		project: "Facility Name",
		internalFtpDate: "Internal FTP Date",
		receivedOn: "Patient Received On",
		chartId: "UUID",
		patientName: "Patient Name",
		dob: "DOB",
		age: "Age",
		patientPrimaryInsurance: "Primary Insurance #",
		ssnNumber: "SSN #",
		gender: "Gender",
		relationship: "Relationship",
		subscriberName: "Subscriber Name",
		subscriberDob: "Subscriber DOB",
		maritalStatus: "Marital Status",
		receivedAddress: "Address Received in PRF/LMN",
		address: "Address",
		city: "City",
		state: "State",
		zipCode: "Zip",
		patientPhoneNumber: "Home/Phone #",
		patientMailId: "Mail ID",

		/* Insurance Information */

		portalAvailability: "Portal Availability",
		insuranceCardReceivedOn: "Insurance Card Received On",
		insuranceCard: "Insurance Card",
		multiplan: "Multiplan",
		icPayerID: "IC Payer ID",
		primaryInsurance: "Primary Insurance",
		primaryInsuranceState: "Primary Insurance State",
		primaryInsurancePlan: "Primary Insurance Plan",
		primaryInsuranceGroupNumber: "Primary Insurance Group #",
		secondaryInsurance: "Secondary Insurance",
		secondaryInsuranceState: "Secondary Insurance State",
		secondaryInsurancePlan: "Secondary Insurance Plan",
		secondaryInsuranceNumber: "Secondary Insurance #",
		secondaryInsuranceGroupNumber: "Secondary Insurance Group #",

		/* Order Summary */

		vfRequestedEquipmentModel: "VF Requested Equipment Model",
		hcpcsCodes: "HCPCS Codes",
		equipmentName: "Equipment Name",
		babyDueDate: "Baby Due Date",
		weeksDue: "Weeks Due",
		orderDate: "Order Date",
		DxCodesModelDetails: "DX Codes",
		orderingPhysician: "Ordering Physician",
		orderingPhysicianNPI: "Ordering Physician NPI",
		orderingPhysicianAddress: "Ordering Physician Address",
		orderingPhysicianPhoneNumber: "Ordering Physician Phn #",
		orderingPhysicianFaxNumber: "Ordering Physician Fax #",
		orderingPhysicianSpeciality: "Ordering Physician Speciality",
		pcp: "PCP",
		pcpNpiNumber: "PCP NPI #",
		pcpAddress: "PCP Address",
		pcpPhoneNumber: "PCP Phn #",
		pcpFaxNumber: "PCP Fax #",
		pcpSpeciality: "PCP Speciality",
		vfTAT: "VF TAT",
		verifyCompletedOn: "VF Date",
		hcpcsFromPrescription: "HCPCS From Prescription",
		equipmentModelFromPrescription: "Equipment Model From Prescription",
		prescriptionReceivedOn: "LMN Received On",

		// /*LMN Validation*/
		// lmnOrderDate: "LMN Order Date",
		// prescribingPhysician: "Prescribing Physician",
		// npiNumber: "NPI Number",
		// npiVerificationMethod: "NPI Verification Method",
		// physicianSignature: "Physician Signature",
		// physicianPhone: "Physician Phone",
		// physicianFax: "Physician Fax",
		// hcpcsCode: "HCPCS Code",
		// itemEquipmentDescription: "Item/Equipment Description",
		// quantity: "Quantity",
		// diagnosisCode: "Diagnosis Code(s)",
		// lmnStartDate: "Start Date",
		// lmnEndDate: "End Date",
		// orderType: "Order Type",
		// faceToFaceNotes: "Face-to-Face Notes",
		// progressNotesMedicalRecords: "Progress Notes/Medical Records",
		// patientMedicalHistory: "Patient Medical History",
		// sizing: "Sizing",
		// customization: "Customization",
		// accessoriesSupplies: "Accessories/Supplies",
		// hipaaCompliant: "HIPAA Compliant",
		// medicareMedicaidCompliant: "Medicare/Medicaid Compliant",
		// stateRegulationsCompliant: "State Regulations Compliant",
		// additionalNotes: "Additional Notes",
		// validationStatus: "Validation Status",
		// reasonForDenialPending: "Reason for Denial/Pending",
		// validationDate: "Validation Date",

		/* Coverage Summary  */
		calendarMonth: "Calendar Month",
		coverageStartDate: "Coverage Start Date",
		coverageEndDate: "Coverage End Date",
		networkStatus: "Network Status",
		innBenefits: "INN Benefit's For DME",
		innDedCalendar: "INN Ded Calendar/Actual Amt",
		innDedMetAmount: "INN Ded Met Amt",
		innDedBalanceAmount: "INN Ded Bal Amt",
		innCoins: "INN Coins",
		innOopCalendar: "INN OOP Calendar/Actual Amt",
		innOopMetAmount: "INN OOP Met Amt",
		onnBenefits: "OON Benefits For DME",
		oonDedCalendar: "OON Ded Calendar/Actual Amt",
		oonDedMetAmount: "OON Ded Met Amt",
		ooDedBalanceAmount: "OON Ded Bal Amt",
		oonCoins: "OON Coins",
		oonOopCalendar: "OON OOP Calendar/Actual Amt",
		oonOopMet: "OON OOP Met Amt",
		authRequired: "Auth Required",
		vfCategory: "Chart Status",
		ssReceived: "S&S Received Equipment In the Last 6 Months?",
		consultationStatus: "Prescription Availability",
		vfComments: "VF Comments",
		subsequentScanDate: "Subsequent Scan Date",
		subsequentQuarter: "Subsequent Quarter To Verify",
		transaction: "Transaction ID/SSA",
		callingAssigned: "Calling Assigned Reason",
		callingAdded: "Calling Added On",
		deliveryStatus: "Delivery Status",
		deliveredOn: "Delivery On",

		/* Calling Details  */
		insurancePhoneNumber: "Insurance Phn #",
		pcb: "PCB",
		callingComments: "Calling Comments",
		callRefNumber: "Call Ref #",
		callingPayerId: "Calling Payer ID",
		callMode: "Call Mode",
		operator: "Operator",
		startTime: "Start Time",
		endTime: "End Time",
		ach: "ACH",
		callDispositionCode: "Call Disposition Code",
		crDispositionCode: "CR Disposition Code",
	};

	const filteredDataArray = convertObjectToArray(getUserProject);
	addNumberToObjectArray(filteredDataArray);

	const handleDownload = async (fileName, password, format) => {
		try {
			setLoadings(true);
			const response = await getExportFile(filteredDataArray, chartData, fileName, password, format);

			const fileExtension = format === "pdf" ? "pdf" : "xlsx";
			const blob = new Blob([response.data], { type: response.headers["content-type"] });
			const url = window.URL.createObjectURL(blob);
			const a = document.createElement("a");
			a.href = url;
			a.download = `${fileName}.${fileExtension}`;
			document.body.appendChild(a);
			a.click();
			a.remove();
			const notifyObj = {
				title: `Chart File Downloaded`,
				message: `The charts "${fileName}.${fileExtension}" has been successfully downloaded.`,
				type: "System",
				userId: userId,
				role: role,
			};
			await dispatch(createNotificationApi(notifyObj)).unwrap();
			form.resetFields();
			setIsModalVisible(false);
			setLoadings(false);
		} catch (error) {
			const fileExtension = format === "pdf" ? "pdf" : "xlsx"; // Setting a default file extension here

			const notifyObj = {
				title: `Download Error`,
				message: `There was an issue downloading the file "${fileName}.${fileExtension}" . Please try again.`,
				type: "System",
				userId: userId,
				role: role,
			};

			await dispatch(createNotificationApi(notifyObj)).unwrap();
			setLoadings(false);
			console.error("Error downloading the file:", error);
			message.error("An error occurred while downloading the file.");
		}
	};

	const handleExport = (key) => {
		if (key?.key === "1") {
			if (filteredDataArray?.length > 0) {
				setIsModalVisible(true);
			} else {
				return notification.info({
					message: "No data found",
					placement: "topRight",
				});
			}
		} else {
			handlePrint();
		}
	};

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
		documentTitle: `Dashboard ${dayjs().format("YYYY-MM-DD")}`,
	});

	const selectedItems = [
		{
			key: "1",
			label: <span className="text-xs">Export As Excel/PDF</span>,
			icon: <FileTwoTone />,
			onClick: handleExport,
		},
		{
			key: "2",
			label: <span className="text-xs">Print Dashboard</span>,
			icon: <PrinterTwoTone />,
			onClick: handleExport,
		},
	];

	const handleFilterChange = useCallback(
		(filterName, value) => {
			setFilters({ [filterName]: value });
		},
		[setFilters],
	);

	const handleCreateOrUpdate = useCallback(
		(historyData) => {
			dispatch(createOrUpdateRecentHistoryApi(historyData));
		},
		[dispatch],
	);

	const onChange = (date, dateString) => {
		const selectedRangeArray = dateString ? dateString : []; // Convert dateString to an array, or an empty array if dateString is falsy
		handleFilterChange("Range", selectedRangeArray);
	};

	const transformedData = useMemo(() => transformInsuranceData(getUserProject), [getUserProject]);

	const barData = useMemo(() => transformToBarData(getUserProject), [getUserProject]);

	const SearchFilterData = useMemo(() => {
		if (!getUserProject) return [];

		const lowerSearchQuery = searchQuery && typeof searchQuery === "string" ? searchQuery.toLowerCase() : null;
		if (!lowerSearchQuery) return getUserProject;

		const searchTerms = new Set(lowerSearchQuery.split(/\s+/));

		return getUserProject.filter((item) => {
			const name = item.patientName?.toLowerCase() || "";
			const chartId = item.chartId?.toLowerCase() || "";
			const modelPatientDetails = item.phoneMailEquipmentModels || [];
			const phone = modelPatientDetails.map((val) => val.phoneNumber);
			const email = modelPatientDetails.map((val) => val.mailId.toLowerCase());
			const dob = item.dob || "";
			// const gender = item.gender || '';
			const batchNo = item.batchNo || "";
			const ssnNumber = item.ssnNumber || "";
			const prescriptionReceivedOn = item.prescriptionReceivedOn || "";

			const modelDetails = item.vfRequestedEquipmentModelDetails || [];
			const hcpcsCodes = modelDetails.map((val) => val.hcpcsCodes.toLowerCase());
			const equipmentNames = modelDetails.map((val) => val.equipmentName.toLowerCase());

			const fields = [
				name,
				...email,
				...phone,
				...hcpcsCodes,
				...equipmentNames,
				chartId,
				dob,
				batchNo,
				ssnNumber,
				prescriptionReceivedOn,
			];

			return fields.some((field) => Array.from(searchTerms).some((term) => field.includes(term)));
		});
	}, [getUserProject, searchQuery]);

	const handleSearch = debounce((newValue) => {
		setSearchQuery(newValue);
		setSearchTerm(newValue);
	}, 300);

	const handleChange = useCallback(
		(newValue, option) => {
			setSearchQuery(newValue);
			const chartId = option.value;
			handleCreateOrUpdate({ userId, chartId, projectId, isActive: true, timeZone: "UTC" });
			if (option !== undefined) {
				dispatch(
					setChartDetailView({
						selected: true,
						data: option?.data,
					}),
				);
				dispatch(setChartId(option?.data?.id || option?.data?._id));
			}
		},
		[dispatch, handleCreateOrUpdate, projectId, userId],
	);

	const getChartView = () => {
		return (
			<TabPanel ChartDetailView={ChartDetailView} setChartDetailView={setChartDetailView} form={ChartTabPanelForm} />
		);
	};

	// Calculate the total number of completed and pending charts
	const totalCompleted = transformedData.reduce((acc, item) => acc + item.Completed, 0);
	const totalPending = transformedData.reduce((acc, item) => acc + item.Pending, 0);
	const totalCharts = totalCompleted + totalPending;

	// Calculate the percentage of completed charts
	const completedPercentage = (totalCompleted / totalCharts) * 100;

	// const extractUniqueCallerValues = (filterChart) => {
	//   let uniqueValues = {};
	//   filterChart.forEach((item) => {
	//     const value = item?.callingBy?.id;

	//     if (value) {

	//     }
	//     const label = item.callingBy?.name;
	//     if (value && !uniqueValues[value]) {
	//       uniqueValues[value] = label;
	//     }
	//   });

	//   return Object.keys(uniqueValues)
	//     .map((value) => ({ value, label: uniqueValues[value] }));
	// };

	// const calledByList = useMemo(
	//   () => extractUniqueCallerValues(getUserProject),
	//   [getUserProject]
	// );

	const handleExportModal = useCallback(() => {
		if (!loadings) {
			// Prevent closing if loading
			setIsModalVisible(false);
			form.resetFields();
		}
	}, [loadings, form]);

	const handleChartDetailedModal = () => {
		dispatch(resetChartViewState()); // Reset all states on close
		// setChartDetailView({
		//   selected: false,
		//   data: [],
		// });
		setSearchQuery("");
	};

	const handleChartOverViewToggle = () => {
		setIsInfoVisible(!isInfoVisible);
	};

	const handleStateChange = (value) => {
		handleFilterChange("State", value);
	};

	const handleProductChange = (value) => {
		handleFilterChange("Product", value);
	};

	const handleStatusChange = (value) => {
		handleFilterChange("Status", value);
	};

	const handleInsuranceChange = (value) => {
		handleFilterChange("Insurance", value);
	};

	// const handleCalledByChange = (value) => {
	//   handleFilterChange("calledBy", value);
	// };

	const handleCallingCompletedOnChange = (date, dateString) => {
		const selectedDate = dateString ? dateString : "";
		handleFilterChange("callingCompletedOn", selectedDate);
	};

	const handlePrescriptionReceivedOn = (date, dateString) => {
		const selectedDate = dateString ? dateString : "";
		handleFilterChange("prescriptionReceivedOn", selectedDate);
	};

	const handleDeliveredOnChange = (date, dateString) => {
		const selectedDate = dateString ? dateString : "";
		handleFilterChange("deliveredOn", selectedDate);
	};

	const handleSubsequentVFDateChange = (date, dateString) => {
		const selectedDate = dateString ? dateString : "";
		handleFilterChange("subsequentScanDate", selectedDate);
	};

	// const handleInternalFtpDateOnChange = (date, dateString) => {
	//   const selectedDate = dateString ? dateString : "";
	//   handleFilterChange("internalFtpDateOn", selectedDate);
	// };

	const handleInternalFtpDateOnChange = (date, dateString) => {
		if (!dateString) return setFilters({ internalFtpDateOn: [] });

		// Split by comma, trim each part, and filter out empty strings
		const dateArray = dateString
			.split(",")
			.map((date) => date.trim())
			.filter((date) => dayjs(date, "MM/DD/YYYY", true).isValid());

		setFilters({ internalFtpDateOn: dateArray });
	};

	return (
		<div className="my-2 max-h-[78vh] overflow-y-auto overflow-x-hidden px-2">
			<Row ref={componentRef} gutter={[16, 16]}>
				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<Row gutter={[16, 16]}>
						{/* <Col xs={24} sm={24} md={8} lg={8} xl={8} className="mb-[0.5%]">
							<Select
								className="w-full"
								showSearch
								allowClear
								value={ChartDetailView?.data?.patientName || undefined}
								placeholder={<span className="font-poppins text-xs">Search Charts</span>}
								defaultActiveFirstOption={false}
								suffixIcon={<SearchOutlined />}
								filterOption={false}
								onSearch={handleSearch}
								onChange={handleChange}
								notFoundContent={null}
								options={
									SearchFilterData && SearchFilterData?.length > 0
										? SearchFilterData?.map((d) => ({
												value: d?.id,
												label: (
													<>
														<div className="grid grid-cols-2 grid-rows-1 gap-4">
															<div>
																<small>Name: {d.patientName}</small>
																<br />
																<small>Phone No: {d?.phoneMailEquipmentModels[0]?.phoneNumber}</small>
																<br />
																<small>Mail ID: {d?.phoneMailEquipmentModels[0]?.mailId}</small>
																<br />
																<small>HCPCS Codes: {d?.vfRequestedEquipmentModelDetails[0]?.hcpcsCodes}</small>
																<br />
																<small>Equipment Model: {d?.vfRequestedEquipmentModelDetails[0]?.equipmentName}</small>
															</div>
														</div>
													</>
												),
												data: d,
											}))
										: []
								}
							/>
						</Col> */}
						<Col xs={24} sm={24} md={8} lg={8} xl={8} className="mb-[0.5%]">
							<RangePicker
								className="custom-range-picker w-full"
								placeholder={["Select Start Date", "Select End Date"]}
								format={"MM/DD/YYYY"}
								onChange={onChange}
								onCalendarChange={onCalendarChange}
								disabledDate={disabledEndDate}
							/>
						</Col>
						<Col xs={24} sm={24} md={12} lg={8} xl={6} className="mb-[0.5%]">
							<Select
								showSearch
								placeholder={<span className="font-poppins text-[#737791] text-xs">Select State</span>}
								optionFilterProp="label"
								className="custom-select w-[80%] md:w-[100%] lg:mb-[1%] lg:w-[90%] "
								allowClear
								mode="multiple"
								onChange={handleStateChange}
								// onChange={(value) => handleFilterChange("State", value)}
								// onChange={filterOnChange}
								options={States}
							/>
						</Col>
						<Col xs={24} sm={24} md={12} lg={8} xl={6} className="mb-[0.5%]">
							<Select
								showSearch
								placeholder={<span className="font-poppins text-[#737791] text-xs">Select Product</span>}
								optionFilterProp="label"
								className="custom-select mb-[0.5%] w-[80%] lg:mb-[1%] lg:w-[90%]"
								allowClear
								mode="multiple"
								// onChange={(value) => handleFilterChange("Product", value)}
								onChange={handleProductChange}
								options={uniqueEquipmentList}
							/>
						</Col>
					</Row>
					<Row gutter={[16, 16]}>
						<Col xs={24} sm={24} md={24} lg={24} xl={24}>
							<Row>
								<Col xs={24} sm={24} md={24} lg={24} xl={24}>
									<Row>
										<Col xs={24} sm={24} md={12} lg={12} xl={6}>
											<Select
												showSearch
												placeholder={<span className="font-poppins text-[#737791] text-xs">Select Status</span>}
												optionFilterProp="label"
												className="custom-select mb-[0.5%] w-[80%] cursor-pointer lg:mb-[1%] lg:w-[90%]"
												allowClear
												mode="multiple"
												onChange={handleStatusChange}
												options={VFCategory}
											/>
										</Col>
										<Col xs={24} sm={24} md={12} lg={12} xl={6}>
											<Select
												showSearch
												placeholder={<span className="font-poppins text-[#737791] text-xs">Select Insurance</span>}
												optionFilterProp="label"
												className="custom-select w-[80%] lg:mb-[1%] lg:w-[90%] "
												allowClear
												mode="multiple"
												onChange={handleInsuranceChange}
												options={primaryInsuranceList}
											/>
										</Col>
										{/* {
                      (role !== 'PROVIDER' && role !== 'USER') && (
                        <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                          <Select
                            showSearch
                            className="custom-select w-[80%] lg:w-[90%] lg:mb-[1%] mb-[0.5%] "
                            // placeholder={<span className='text-xs text-[#737791] font-poppins'>Called By</span>}
                            placeholder={<span className='text-xs text-[#737791] font-poppins'>Called By</span>}
                            onChange={handleCalledByChange}
                            options={calledByList}
                            mode="multiple"
                            allowClear
                          />
                        </Col>
                      )
                    } */}
										{role !== "PROVIDER" && role !== "USER" && (
											<Col xs={24} sm={24} md={12} lg={12} xl={6}>
												<DatePicker
													size="small"
													className="custom-date-picker custom-range-picker mb-[0.5%] w-[80%] lg:mb-[1%] lg:w-[90%]"
													placeholder={"Prescription Received On"}
													onChange={handlePrescriptionReceivedOn}
													format={"MM/DD/YYYY"}
												/>
											</Col>
										)}
										{role !== "PROVIDER" && role !== "USER" && (
											<Col xs={24} sm={24} md={12} lg={12} xl={6}>
												<DatePicker
													size="small"
													className="custom-date-picker custom-range-picker mb-[0.5%] w-[80%] lg:mb-[1%] lg:w-[90%]"
													// className="w-full"
													// placeholder={<span className='text-xs text-[#737791] font-poppins'>Calling Completed On</span>}
													placeholder={"Calling Completed On"}
													onChange={handleCallingCompletedOnChange}
													format={"MM/DD/YYYY"}
												/>
											</Col>
										)}

										<Col xs={24} sm={24} md={12} lg={12} xl={6}>
											<DatePicker
												size="small"
												className=" custom-date-picker custom-range-picker mb-[0.5%] w-[80%] lg:mb-[1%] lg:w-[90%]"
												// placeholder={<span className='text-xs text-[#737791] font-poppins'>Delivered On</span>}
												placeholder={`Delivered On`}
												onChange={handleDeliveredOnChange}
												format={"MM/DD/YYYY"}
											/>
										</Col>
										<Col xs={24} sm={24} md={12} lg={12} xl={6}>
											<DatePicker
												size="small"
												className="custom-date-picker ] custom-range-picker mb-[0.5% w-[80%] lg:mb-[1%] lg:w-[90%]"
												// placeholder={<span className='text-xs text-[#737791] font-poppins'>Called By</span>}
												placeholder={`Subsequent VF Date`}
												onChange={handleSubsequentVFDateChange}
												format={"MM/DD/YYYY"}
											/>
										</Col>
										<Col xs={24} sm={24} md={12} lg={12} xl={6}>
											<DatePicker
												size="small"
												className="custom-range-picker custom-date-picker w-[80%] lg:mb-[1%] lg:w-[90%] "
												placeholder={"Internal FTP Date"}
												onChange={handleInternalFtpDateOnChange}
												format={"MM/DD/YYYY"}
												// multiple
											/>
										</Col>
										{/* {role !== "PROVIDER" && role !== "USER" ? (
											<>
												{" "}
												<Col xs={24} sm={24} md={12} lg={8} xl={6}></Col>
												<Col xs={24} sm={24} md={12} lg={8} xl={6}></Col>
											</>
										) : null} */}
										{/* <Col xs={24} sm={24} md={12} lg={8} xl={6}></Col>
                    <Col xs={24} sm={24} md={12} lg={8} xl={6}></Col> */}

										<Col
											xs={24}
											sm={24}
											md={14}
											lg={12}
											xl={6}
											className="mq825:mt-5 flex items-center justify-end px-10"
										>
											{userAccess !== "VIEW" && (
												<Dropdown className="w-full" menu={{ items: selectedItems }} placement="bottom" size="small">
													<Button
														size="small"
														// className="w-full font-poppins font-light text-xs px-2 py-2 tracking-widest text-white bg-[#458ff6] rounded-full generate-report-button"
														className={`w-full rounded-full bg-[#458ff6] px-2 py-2 font-light font-poppins text-white text-xs tracking-widest ${role === "PROVIDER" ? "generate-report-button-provider" : "generate-report-button"}`}
													>
														Generate Report
													</Button>
												</Dropdown>
											)}
										</Col>
									</Row>
								</Col>
							</Row>
						</Col>
						<Col xs={24} sm={24} md={24} lg={24} xl={24}>
							<Row className="mx-5 flex h-[250px] mq450:h-[500px] max-h-[250px] mq450:max-h-[500px] w-[100] items-center mq450:justify-center justify-evenly">
								<ChartsSummary isWeek={isWeek} getUserProject={getUserProject} datas={datas} />
								<Col
									xs={24}
									sm={24}
									md={24}
									lg={24}
									xl={12}
									// className="flex justify-center max-h-[100%] w-[100%] relative  mq825:mt-[10%] 1440:mt-[5%]  mq825:justify-center mq675:mt-[20%] mq450:mt-[25%] print-pie-chart"
									className={`print-pie-chart relative 1440:mt-[5%] mq450:mt-[25%] mq675:mt-[20%] mq825:mt-[10%] flex max-h-[100%] w-[100%] justify-center mq825:justify-center ${role === "PROVIDER" ? "move-chart" : ""} ${role === "PROVIDER" ? "mini-chart-move" : ""} ${role === "PROVIDER" ? "micro-chart-move" : ""}`}
								>
									<PiChartComponent
										transformedData={transformedData}
										modal1Open={modal1Open}
										setModal1Open={setModal1Open}
										completedPercentage={completedPercentage}
										fullScreen={fullScreen}
									/>
								</Col>
							</Row>
						</Col>
						<Col
							xs={24}
							sm={24}
							md={24}
							lg={24}
							xl={24}
							className="print-bar-chart relative 1024:mt-[35%] 1366:mt-[5%] mq1050:mt-[27vw] mq450:mt-[90%] mq675:mt-[70%] mq825:mt-[55%] h-[500px] max-h-[300px]"
						>
							<BarChartComponent
								modal2Open={modal2Open}
								setModal2Open={setModal2Open}
								barData={barData}
								fullScreen={fullScreen}
							/>
						</Col>
					</Row>
				</Col>
			</Row>
			<Modal
				title="Export Options"
				open={isModalVisible}
				onOk={handleModalOk}
				onCancel={handleExportModal}
				confirmLoading={loadings}
				maskClosable={!loadings} // Prevent closing by clicking outside
				closable={!loadings} // Hide the close button on the top right
				keyboard={!loadings} // Prevent closing with the ESC key
			>
				<Spin spinning={loadings} tip="Processing your download...">
					<Form form={form} layout="vertical" autoComplete="off">
						<input type="text" name="username" style={{ display: "none" }} />
						<input type="password" name="password" style={{ display: "none" }} />
						<Form.Item name="exportType" label="Select Export Format" initialValue="excel">
							<Radio.Group
								onChange={handleFormatChange} // Use the function directly
								value={selectedFormat}
							>
								<Radio value="excel">Excel</Radio>
								<Radio value="pdf">PDF</Radio>
							</Radio.Group>
							{selectedFormat && (
								<small className="mt-10">
									Current selected format:{" "}
									<span className="font-bold">{selectedFormat === "excel" ? "Excel" : "PDF"}</span>
								</small>
							)}
							{warningVisible && (
								<Alert
									size="small"
									className="mt-1 text-xs"
									message="Note: PDF format may not support all field data"
									type="warning"
									showIcon
								/>
							)}
						</Form.Item>
						<Form.Item
							name="fileName"
							label="File Name"
							rules={[{ required: true, message: "Please enter a file name" }]}
						>
							<Input placeholder="Enter file name" />
						</Form.Item>
						<Form.Item name="password" label="Password (Optional)">
							<Input.Password placeholder="Enter password (optional)" autoComplete="new-password" />
						</Form.Item>
					</Form>
				</Spin>
			</Modal>
			{ChartDetailView?.selected && (
				<ModalBox
					open={ChartDetailView}
					title={
						<div style={{ display: "flex", alignItems: "center" }}>
							Chart Overview
							<Tooltip title="Chart Status">
								<Badge
									count={ChartDetailView?.data?.vfCategory || ""}
									showZero
									color="#faad14"
									style={{
										marginLeft: 8,
										fontWeight: 500,
										paddingTop: "2px",
										paddingBottom: "2px",
										height: "auto",
										letterSpacing: 2,
										fontSize: 14,
										borderRadius: 5,
									}}
								/>
							</Tooltip>
							<Tooltip title="View Chart Info">
								<InfoCircleOutlined style={{ marginLeft: 8, cursor: "pointer" }} onClick={handleChartOverViewToggle} />
							</Tooltip>
						</div>
					}
					width="xl"
					content={getChartView()}
					onCancel={handleChartDetailedModal}
					footer={null}
				/>
			)}
			{isInfoVisible && (
				<ChartInfo
					isInfoVisible={isInfoVisible}
					setIsInfoVisible={setIsInfoVisible}
					chartData={ChartDetailView?.data}
				/>
			)}
		</div>
	);
};

export default Dashboard;
