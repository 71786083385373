import { Tabs } from "antd";
/* eslint-disable no-unused-vars */
import { Suspense, lazy, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SuspenseLoading from "../../../components/SuspenseLoading";
import VersionHistory from "../../../components/VersionHistory";
import {
	setCallerSpaceToggle,
	setCallerSpaceVisible,
	setCallingDetailsSaved,
	setCoverageDetailsSaved,
	setInsuranceDetailsSaved,
	setPatientDetailsSaved,
	setProductDetailsSaved,
} from "../../../redux/ChartViewManagement/ChartViewManagementSlice";
import CallingDetails from "../../Chart/CallingDetails";
import CoverageDetails from "../../Chart/CoverageDetails";
import InsuranceDetails from "../../Chart/InsuranceDetails";
import LmnValidationDetails from "../../Chart/LmnValidationDetails";
import PatientDetails from "../../Chart/PatientDetails";
import ProductDetails from "../../Chart/ProductDetails";
// import DocumentDocViewUpload from "../../Admin/Document/DocumentDocViewUpload"
// import DocumentViewer from '../../Admin/Document/DocumentViewer';

const { TabPane } = Tabs;

const DocumentDocViewUpload = lazy(() => import("../../Admin/Document/DocumentDocViewUpload"));

const DocumentViewer = lazy(() => import("../../Admin/Document/DocumentViewer"));

const TabPanel = ({ ChartDetailView, charts, setCharts, setChartDetailView, documentViewer, form }) => {
	const dispatch = useDispatch();
	const [activeTab, setActiveTab] = useState("1");
	const [batchChanged, setBatchChanged] = useState(false);
	const { chartDocumentList } = useSelector((state) => state.chartDocument);

	const isPatientDetailsSaved = useSelector((state) => state.chartViewManagement.isPatientDetailsSaved);

	const isInsuranceDetailsSaved = useSelector((state) => state.chartViewManagement.isInsuranceDetailsSaved);

	const isProductDetailsSaved = useSelector((state) => state.chartViewManagement.isProductDetailsSaved);

	const isCoverageDetailsSaved = useSelector((state) => state.chartViewManagement.isCoverageDetailsSaved);

	const { role } = useSelector((state) => state.userDetails);

	const callerSpaceToggle = useSelector((state) => state.chartViewManagement.callerSpaceToggle);

	const callerSpaceVisible = ChartDetailView?.data?.hasOwnProperty("callerSpaceVisible")
		? ChartDetailView?.data?.callerSpaceVisible
		: false;

	const isFromUpdate = true;

	const [viewStatus, setViewStatus] = useState(false);
	const [viewData, setViewData] = useState({});

	const patientFormRef = useRef();
	const insuranceFormRef = useRef();
	const productFormRef = useRef();
	const lmnFormRef = useRef();
	const coverageFormRef = useRef();
	const callerFormRef = useRef();

	useEffect(() => {
		if (!callerSpaceVisible) {
			if (
				ChartDetailView?.data?.hasOwnProperty("callingBy") ||
				ChartDetailView?.data?.vfCategory?.toLowerCase() === "VF In-Process"?.toLowerCase() ||
				ChartDetailView?.data?.vfCategory?.toLowerCase() === "Missing Demo/Insurance Info"?.toLowerCase() ||
				ChartDetailView?.data?.vfCategory?.toLowerCase() === "In-Active"?.toLowerCase() ||
				ChartDetailView?.data?.vfCategory?.toLowerCase() === "Subsequent VF In-Process"?.toLowerCase()
			) {
				// setCallerSpaceToggle(true)
				dispatch(setCallerSpaceToggle(true));
				dispatch(setCallerSpaceVisible(true));
			}
		}
	}, [ChartDetailView?.data, callerSpaceVisible, dispatch]);

	const handleNextTab = () => {
		// Define an array of tab keys
		const tabKeys = ["1", "2", "3", "4", "5", "6"]; // Update with your actual tab keys

		// Find the index of the current active tab
		const currentIndex = tabKeys.indexOf(activeTab);

		// Calculate the index of the next tab
		const nextIndex = currentIndex + 1 < tabKeys.length ? currentIndex + 1 : 0;

		// Get the key of the next tab
		const nextTab = tabKeys[nextIndex];

		// Set the next tab as the active tab
		setActiveTab(nextTab);
	};

	const handleSavePatientDetails = async () => {
		if (patientFormRef.current) {
			try {
				await patientFormRef.current.submit();

				// Example new version to add after saving (this is just an example, modify as per your logic)
				const newVersion = {
					versionNumber: ChartDetailView?.data?.versions?.length + 1 || 1,
					updatedAt: new Date().toISOString(),
					updatedBy: "Current User", // Replace with actual user data if necessary
					changes: "Patient Details Updated", // Custom message for version history
				};

				// Update the ChartDetailView with new data and new version history
				setChartDetailView((prevView) => ({
					...prevView,
					data: {
						...prevView.data,
						versions: [...(prevView.data?.versions || []), newVersion], // Append the new version to the version history
					},
				}));

				// Also update the charts state (in case charts is a list of all charts)
				setCharts((prevCharts) =>
					prevCharts.map((chart) =>
						chart.id === ChartDetailView?.data?.id
							? {
									...chart,
									versions: [...(chart.versions || []), newVersion], // Append the new version to the corresponding chart
								}
							: chart,
					),
				);

				// Optionally, display a success message
				// message.success("Patient details saved successfully!");
			} catch (error) {
				// message.error("Error saving patient details.");
			}
		}
	};

	const handleSaveInsuranceDetails = () => {
		if (insuranceFormRef.current) {
			insuranceFormRef.current.submit();
		}
	};

	const handleSaveProductDetails = () => {
		if (productFormRef.current) {
			productFormRef.current.submit();
		}
	};

	const handleSaveLmnDetails = () => {
		if (lmnFormRef.current) {
			lmnFormRef.current.submit();
		}
	};

	const handleSaveCoverageDetails = () => {
		if (coverageFormRef.current) {
			coverageFormRef.current.submit();
		}
	};

	const handleSaveCallerDetails = () => {
		if (callerFormRef.current) {
			callerFormRef.current.submit();
		}
	};

	const handleSaveShortcut = () => {
		if (activeTab === "1") {
			handleSavePatientDetails();
		} else if (activeTab === "2") {
			handleSaveInsuranceDetails();
		} else if (activeTab === "3") {
			handleSaveProductDetails();
		} else if (activeTab === "4") {
			handleSaveLmnDetails();
		} else if (activeTab === "5") {
			handleSaveCoverageDetails();
		} else if (activeTab === "6") {
			handleSaveCallerDetails();
		}
	};

	// useKeyPress(['s'], handleSaveShortcut);

	return (
		<>
			<Tabs activeKey={activeTab} onChange={setActiveTab}>
				<TabPane tab="Patient Details" key="1">
					<PatientDetails
						chartDetail={ChartDetailView?.data}
						onNextTab={handleNextTab}
						ChartDetailView={ChartDetailView}
						setActiveSubTab={setActiveTab}
						setBatchChanged={setBatchChanged}
						setPatientDetailsSaved={setPatientDetailsSaved}
						defaultSize={"dataCapture"}
						formRef={patientFormRef}
						charts={charts}
						setCharts={setCharts}
						setChartDetailView={setChartDetailView}
						documentViewer={documentViewer}
					/>
				</TabPane>
				<TabPane tab="Insurance Information" key="2">
					<InsuranceDetails
						chartDetail={ChartDetailView?.data}
						onNextTab={handleNextTab}
						ChartDetailView={ChartDetailView}
						setActiveSubTab={setActiveTab}
						setInsuranceDetailsSaved={setInsuranceDetailsSaved}
						defaultSize={"dataCapture"}
						formRef={insuranceFormRef}
						charts={charts}
						setCharts={setCharts}
						setChartDetailView={setChartDetailView}
						documentViewer={documentViewer}
					/>
				</TabPane>
				<TabPane tab="Order Summary" key="3">
					<ProductDetails
						chartDetail={ChartDetailView?.data}
						onNextTab={handleNextTab}
						ChartDetailView={ChartDetailView}
						setActiveSubTab={setActiveTab}
						setProductDetailsSaved={setProductDetailsSaved}
						defaultSize={"dataCapture"}
						formRef={productFormRef}
						charts={charts}
						setCharts={setCharts}
						setChartDetailView={setChartDetailView}
						documentViewer={documentViewer}
					/>
				</TabPane>
				<TabPane tab="LMN Validation" key="4">
					<LmnValidationDetails
						chartDetail={ChartDetailView?.data}
						onNextTab={handleNextTab}
						ChartDetailView={ChartDetailView}
						setActiveSubTab={setActiveTab}
						defaultSize={"dataCapture"}
						formRef={lmnFormRef}
						charts={charts}
						setCharts={setCharts}
						setChartDetailView={setChartDetailView}
						documentViewer={documentViewer}
					/>
				</TabPane>
				<TabPane tab="Coverage Summary" key="5">
					<CoverageDetails
						chartDetail={ChartDetailView?.data}
						onNextTab={handleNextTab}
						ChartDetailView={ChartDetailView}
						setActiveSubTab={setActiveTab}
						setCallerSpaceToggle={setCallerSpaceToggle}
						callerSpaceToggle={callerSpaceToggle}
						setCallerSpaceVisible={setCallerSpaceVisible}
						callerSpaceVisible={callerSpaceVisible}
						setCoverageDetailsSaved={setCoverageDetailsSaved}
						isPatientDetailsSaved={isPatientDetailsSaved}
						isInsuranceDetailsSaved={isInsuranceDetailsSaved}
						isProductDetailsSaved={isProductDetailsSaved}
						isCoverageDetailsSaved={isCoverageDetailsSaved}
						isFromUpdate={isFromUpdate}
						defaultSize={"dataCapture"}
						formRef={coverageFormRef}
						charts={charts}
						setCharts={setCharts}
						setChartDetailView={setChartDetailView}
						documentViewer={documentViewer}
					/>
				</TabPane>
				{callerSpaceVisible && (
					<TabPane tab="Caller Space" key="6">
						<CallingDetails
							onNextTab={handleNextTab}
							chartDetail={ChartDetailView?.data}
							ChartDetailView={ChartDetailView}
							callerSpaceToggle={callerSpaceToggle}
							isFromUpdate={isFromUpdate}
							defaultSize={"dataCapture"}
							setCallingDetailsSaved={setCallingDetailsSaved}
							formRef={callerFormRef}
							charts={charts}
							setCharts={setCharts}
							setChartDetailView={setChartDetailView}
							documentViewer={documentViewer}
						/>
					</TabPane>
				)}

				{role !== "PROVIDER" && role !== "USER" && (
					<TabPane tab="Related Documents" key="8">
						<Suspense fallback={<SuspenseLoading />}>
							<DocumentDocViewUpload
								// setChartDocViewUpload={setChartDocViewUpload}
								// chartDocViewUpload={chartDocViewUpload}
								// newDocument={newDocument}
								// setNewDocument={setNewDocument}
								// setRemoveDocumentFileStatus={setRemoveDocumentFileStatus}
								// setSelectedData={setSelectedData}
								// documentData={documentData}
								chartDocumentList={chartDocumentList}
								chartId={ChartDetailView?.data?.id || ChartDetailView?.data?._id}
								setViewStatus={setViewStatus}
								setViewData={setViewData}
								// setDeleteStatus={setDeleteStatus}
								// setDeleteData={setDeleteData}
								chartList={charts}
								isFromTabPanel={true}
								form={form}
							/>
						</Suspense>
					</TabPane>
				)}
				{ChartDetailView?.data?.versions?.length > 0 && (
					<TabPane tab="Version History" key="7">
						<VersionHistory
							currentChart={ChartDetailView?.data}
							documentViewer={documentViewer}
							versions={ChartDetailView?.data?.versions || []}
						/>
					</TabPane>
				)}
			</Tabs>
			{role !== "PROVIDER" && role !== "USER" && (
				<Suspense fallback={<SuspenseLoading />}>
					{viewStatus && (
						<DocumentViewer
							viewData={viewData}
							viewStatus={viewStatus}
							setViewStatus={setViewStatus}
							setViewData={setViewData}
							chartList={charts}
							setCharts={setCharts}
						/>
					)}
				</Suspense>
			)}
		</>
	);
};

export default TabPanel;
