import { createSlice } from "@reduxjs/toolkit";
import { subsequentChartApi } from "./useChartService";

const chartsSlice = createSlice({
	name: "useCharts",
	initialState: {
		chartData: [],
		chartPagination: {
			currentPage: 1,
			pageSize: 10,
			totalDocuments: 0,
		},
		loadingCharts: false,
		error: null,
		selectedDate: null, // Add selectedDate to state
	},
	reducers: {
		resetChartsState: (state) => {
			state.chartData = [];
			state.chartPagination = {
				currentPage: 1,
				pageSize: 10,
				totalDocuments: 0,
			};
			state.loadingCharts = false;
			state.error = null;
		},
		setSelectedDate: (state, action) => {
			state.selectedDate = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(subsequentChartApi.pending, (state) => {
				state.loadingCharts = true;
				state.error = null;
			})
			.addCase(subsequentChartApi.fulfilled, (state, action) => {
				const { documents, totalDocuments, pageSize, currentPage } = action.payload;
				state.chartData = documents;
				state.chartPagination = { currentPage, pageSize, totalDocuments };
				state.loadingCharts = false;
			})
			.addCase(subsequentChartApi.rejected, (state, action) => {
				state.error = action.payload || "Failed to fetch charts";
				state.loadingCharts = false;
			});
	},
});

export const { resetChartsState, setSelectedDate } = chartsSlice.actions;

export default chartsSlice.reducer;
