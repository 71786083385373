import { useRef, useState } from "react";
import { Modal, Input, Button, Tag, notification,Typography } from "antd";
import { CopyOutlined, ShareAltOutlined, CloseCircleOutlined, CheckOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { setShareModalVisible, 
  addEmail, 
  removeEmail, 
  resetShareState,
  setErrorMessage, 
  setCopySuccess 
} from "../../redux/ShareChartStateManagement/ShareChartStateManagementSlice";

const { Text } = Typography;

const CreateShare = () => {
    const dispatch = useDispatch();
    const { 
      isShareModalVisible, 
      emails, 
      errorMessage, 
      copySuccess, 
      shareLink 
    } = useSelector((state) => state.createShare);

    const inputRef = useRef(null);
    const [inputKey, setInputKey] = useState(0);

    const handleAddEmail = (event) => {
      if (event.key === "Enter") {
            const email = event.target.value.trim();

            if (!email) return;

            if (!validateEmail(email)) {
                dispatch(setErrorMessage("Invalid email address!"));
                return;
            }

            dispatch(addEmail(email));
            setInputKey((prevKey) => prevKey + 1);
        }
    };

    const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

    const handleCopyLink = () => {
        if (!shareLink) {
            dispatch(setErrorMessage("No share link available!"));
            return;
        }
        navigator.clipboard.writeText(shareLink).then(() => {
            dispatch(setCopySuccess(true));
            setTimeout(() => dispatch(setCopySuccess(false)), 2000); 
        });
    };

    const handleShare = () => {
        if (emails.length === 0) {
          dispatch(setErrorMessage("Please add at least one email to share!"));
          return;
        }
        notification.success({
          message:`Shared successfully with: ${emails.join(", ")}`,
          placement:"topRight",
        });
        dispatch(resetShareState());
    };

    return (
        <Modal
            open={isShareModalVisible}
            title="Share Content"
            maskClosable={false}
            onCancel={() =>{ 
              dispatch(setShareModalVisible(false));
              dispatch(resetShareState());
            }}
            footer={[
                <Button 
                    key="copyLink"
                    type="primary" 
                    icon={copySuccess ? <CheckOutlined /> : <CopyOutlined />} 
                    onClick={handleCopyLink}
                    disabled={!shareLink}
                  >
                    {copySuccess ? "Copied!" : "Copy Link"}
                </Button>,
                <Button key="share" type="primary" icon={<ShareAltOutlined />} onClick={handleShare}>
                    Share
                </Button>,                
            ]}
            zIndex={1050}
        >
            <Input
                key={inputKey} 
                ref={inputRef}
                type="email"
                placeholder="Enter email and press Enter"
                onKeyDown={handleAddEmail}
                style={{ width: "100%", marginBottom: "7px" }}
            />

            {errorMessage && <Text type="danger">{errorMessage}</Text>}

            <div style={{ margin: "10px 0" }}>
                {emails.map((email) => (
                    <Tag
                        key={email}
                        closable
                        onClose={() => dispatch(removeEmail(email))}
                        icon={<CloseCircleOutlined />}
                        color="blue"
                        style={{ marginBottom: "5px" }}
                    >
                        {email}
                    </Tag>
                ))}
            </div>
        </Modal>
    );
};

export default CreateShare;
