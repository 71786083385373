import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import debounce from "lodash/debounce";
import { memo, useCallback } from "react";

const SearchBar = ({ searchQuery, setSearchQuery, setSearchTerm }) => {
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const debounceSetSearchTerm = useCallback(
		debounce((value) => {
			setSearchTerm(value);
		}, 500),
		[],
	);

	const handleChange = (event) => {
		const targetValue = event.target.value;
		debounceSetSearchTerm(targetValue);
		setSearchQuery(targetValue);
	};

	const handlePaste = (event) => {
		event.preventDefault();
		const pastedText = event.clipboardData.getData("text");
		const trimmedText = pastedText.trim();
		setSearchQuery(trimmedText);
		debounceSetSearchTerm(trimmedText);
	};

	return (
		<div className="w-full">
			<Input
				type="text"
				className="w-full rounded-xl border-2 focus:outline-none"
				value={searchQuery}
				onChange={handleChange}
				onPaste={handlePaste}
				placeholder="Search..."
				allowClear
				prefix={<SearchOutlined />}
			/>
		</div>
	);
};

export default memo(SearchBar);
