import { createAsyncThunk } from "@reduxjs/toolkit";
import { getTimeZones } from "../../api/timeZone/timeZone";

export const getTimeZonesApi = createAsyncThunk("timeZone/get", async (data, { rejectWithValue }) => {
	try {
		const response = await getTimeZones();

		return response.data;
	} catch (error) {
		if (!error.response) {
			throw error;
		}
		return rejectWithValue(error.response.data);
	}
});
