import { notification } from "antd";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

function ProviderProtectedRoute({ children, allowedRoles }) {
	// let userRole = findUser?.access || sample?.role;
	const userRole = useSelector((state) => state.userDetails.role);
	useEffect(() => {
		if (userRole && !allowedRoles?.includes(userRole)) {
			notification.warning({
				message: "Access Denied",
				description: "You do not have the required permissions to access this page.",
				placement: "topRight",
			});
		}
	}, [userRole, allowedRoles]);

	if (allowedRoles?.includes(userRole)) {
		return children;
	}

	return <Navigate to="/" />;
}

export default ProviderProtectedRoute;
