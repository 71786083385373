import { Alert, Checkbox, Col, Form, Input, Row, Typography, notification } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/svg_images/BM.svg";
import topDesign from "../../assets/svg_images/Group 427319164.svg";
import centerImage from "../../assets/svg_images/Group 427319167.svg";
import BottomImage from "../../assets/svg_images/Group 427319169.svg";
import sideImage from "../../assets/svg_images/side bar.svg";
import { setLoading } from "../../redux/loading/loadingSlice.js";
import { openLinkInNewTab } from "../../utils/utility.js";

const SignIn = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const [error, setError] = useState(null);
	const [shake, setShake] = useState(false);
	const { userId } = useSelector((state) => state.userDetails);

	const [showVideo, setShowVideo] = useState(true);
	const [fadeOut, setFadeOut] = useState(false);

	const handleVideoEnd = () => {
		setShowVideo(false); // Hide the video and show the Sign-In page
	};

	const handleTimeUpdate = (e) => {
		const videoElement = e.target;
		if (videoElement.duration - videoElement.currentTime <= 0.5) {
			setFadeOut(true); // Start fade-out animation 0.5 seconds before video ends
		}
	};

	useEffect(() => {
		const checkAuthToken = async () => {
			const token = localStorage.getItem("rememberToken");

			if (token) {
				dispatch(setLoading(true));

				try {
					const { validateToken } = await import("../../api/provider/providerUsers");
					const response = await validateToken(token);

					const result = response?.data;

					if (response.status === 200 && result.isValid) {
						if (result?.user?.remember) {
							form.setFieldsValue({
								email: result?.user?.email,
								password: result?.user?.password,
								remember: result?.user?.remember,
							});
						} else {
							form.resetFields();
						}
					} else {
						notification.warning({
							message: "Invalid token or user not authorized.",
							placement: "topLeft",
						});
						localStorage.removeItem("rememberToken");
						navigate("/");
					}
				} catch (error) {
					console.error("Error:", error);

					notification.warning({
						message: "An error occurred during token validation.",
						placement: "topLeft",
					});

					localStorage.removeItem("rememberToken");
					navigate("/");
				} finally {
					dispatch(setLoading(false));
				}
			} else {
				navigate("/");
			}
		};

		checkAuthToken();
	}, [dispatch, form, navigate]);

	const onFinish = async (values) => {
		try {
			dispatch(setLoading(true));
			const providerUser = {
				email: values.email,
				id: userId,
				password: values.password,
				rememberMe: values.remember ? values.remember : false,
				role: null,
			};
			const { signInApi } = await import("../../api/signIn/signIn.js");

			const { status, data } = await signInApi(providerUser);

			const userData = data;

			if (status === 201 && userData?.isActive && userData?.status !== "SUSPEND") {
				const { setUserDetails } = await import("../../redux/userDetails/userDetailsSlice");

				dispatch(
					setUserDetails({
						userId: userData?.userId,
						role: userData?.isProvider ? "PROVIDER" : "USER",
						project: userData?.project,
						firstName: userData?.firstName,
						lastName: userData?.lastName,
						projectName: userData?.projectName,
						userAccess: userData?.userAccess,
						timeZone: userData?.timeZone,
						avatarKey: userData?.avatarKey,
						avatarUrl: userData?.avatarUrl,
						emailId: userData?.email,
					}),
				);

				// Store token in localStorage if "Remember me" is selected
				if (values.remember) {
					sessionStorage.setItem("rememberToken", data?.token);
				}

				if (userData?.twoFactorEnabled && userData?.isActive) {
					dispatch(setLoading(false));
					localStorage.setItem("cameFromSignIn", "true");

					if (userData?.lastOtpVerified) {
						return navigate("/provider/home");
					} else {
						return navigate("/twoFactor");
					}
				} else {
					dispatch(setLoading(false));

					if (!userData?.findUser) {
						dispatch(setLoading(false));
						const userName = userData?.firstName + " " + userData?.lastName;
						localStorage.setItem("Name", userName);
						localStorage.setItem("timeZones", userData?.timeZone?.value);
						localStorage.setItem("email", userData?.email);
						localStorage.setItem("jobTitle", userData?.jobTitle);
						return navigate(`/policy/${userData?.userId}`);
					}

					return navigate("/provider/home");
				}
			}
			if (status === 201 && (!userData?.isActive || (userData?.isActive && userData?.status === "SUSPEND"))) {
				notification.warning({
					message: "Please contact your administrator as you are unable to log in!",
					placement: "topLeft",
				});
				dispatch(setLoading(false));
			}

			if (data === "No User Found!") {
				setError("Invalid credentials, please verify your email and password");
				setShake(true);
				setTimeout(() => setShake(false), 500);
				dispatch(setLoading(false));
			}
		} catch (error) {
			setError(error?.response?.data);
			setShake(true);
			setTimeout(() => setShake(false), 500);
			dispatch(setLoading(false));
		}
	};

	return (
		<>
			{showVideo ? (
				<div
					className={`flex h-screen w-screen items-center justify-center bg-black ${
						fadeOut ? "opacity-0 transition-opacity duration-500" : "opacity-100"
					}`}
				>
					<video
						src="https://wws-bm-assests.s3.us-east-1.amazonaws.com/WWS_Logo/WWS_BM_Logo_Animation_final.mp4"
						className="h-full w-full object-cover"
						autoPlay
						muted
						onEnded={handleVideoEnd}
						onTimeUpdate={handleTimeUpdate}
					/>
				</div>
			) : (
				<Row gutter={[16, 16]} className="w-full overflow-hidden">
					<Col xs={12} sm={12} md={8} lg={6} xl={8} className="relative">
						<img src={logo} alt="wwsProp" className="absolute left-[10%] h-12 w-9" />
					</Col>
					<Col xs={24} sm={12} md={8} lg={6} xl={8} className="relative">
						<div className="-left-[45%] absolute top-0">
							<img
								src={topDesign}
								alt="wwsProp"
								className="h-auto w-auto xs:translate-x-[20%] md:w-[70%] md:translate-x-[20%] lg:w-[90%] lg:translate-x-[20%] xl:w-[50%]"
								// className="w-auto h-auto md:w-[70%] lg:w-[70%] xl:w-[50%] md:translate-x-[20%] lg:translate-x-[200%] xs:translate-x-[20%]"
							/>
						</div>
					</Col>

					<Col xs={24} sm={12} md={8} lg={3.42} xl={8}></Col>
					<Col xs={24} sm={12} md={8} lg={3.42} xl={8}></Col>
					<Col xs={24} sm={12} md={8} lg={3.42} xl={8}></Col>
					<Col xs={24} sm={12} md={8} lg={3.42} xl={8}></Col>
					<Col xs={24} sm={12} md={8} lg={3.42} xl={8}></Col>
					<Col xs={24} sm={12} md={8} lg={3.42} xl={8}></Col>
					<Col xs={24} sm={12} md={8} lg={3.42} xl={8}></Col>

					<Col xs={24} sm={24} md={12} lg={14} xl={15} rowSpan={3}>
						<img
							src={centerImage}
							alt="wwsProp"
							className="center-image-provider left-1/2 h-auto w-[80%] translate-x-[30%] translate-y-[30%] md:w-[70%] md:translate-y-[20%] lg:w-[80%] xl:w-[50%]"
							// className="w-[80%] md:w-[70%] lg:w-[70%] xl:w-[50%] h-auto translate-y-[30%] md:translate-y-[20%] lg:translate-y-[8%] left-1/2 translate-x-[30%]"
						/>
						<div className="absolute top-[60%] w-[100%] md:top-[50%] lg:top-[70%]">
							<img src={sideImage} alt="wwsProp" className="h-auto w-auto md:w-[70%] lg:w-[10%] xl:w-[50%]" />
						</div>
					</Col>

					<Col
						xs={24}
						sm={24}
						md={11}
						lg={9}
						xl={8}
						className="mb-[-1%] ml-[4.1%] flex items-center justify-center"
						style={{ marginTop: "2.5%" }}
					>
						<div className="break-words font-light font-mulish text-[#252525] text-sm">
							<h3>
								<p className="break-words font-light font-mulish text-[#252525] text-sm">
									We're glad you're here. Please sign in to access your account.
								</p>
							</h3>

							<div className="p-[5%]">
								{error && (
									<Alert
										message="Login Error"
										description={error}
										type="error"
										showIcon
										className={`mb-4 ${shake ? "animate-shake" : ""}`}
									/>
								)}
								<Form form={form} name="basic" autoComplete="off" size="large" onFinish={onFinish}>
									<Form.Item
										label={<div className="font-bold font-plus-jakarta-sans text-black text-xs-5">Email</div>}
										name="email"
										labelCol={{ span: 24, style: { marginBottom: "0%" } }}
										style={{ marginBottom: "4px" }}
										rules={[
											{
												required: true,
												message: "Please enter your email!",
											},
											{
												type: "email",
												message: "The input is not valid E-mail!",
											},
										]}
									>
										<Input className={`w-[85%] ${shake ? "animate-shake" : ""}`} placeholder="Enter your email" />
									</Form.Item>

									<Form.Item
										label={<div className="font-bold font-plus-jakarta-sans text-black text-xs-5">Password</div>}
										name="password"
										labelCol={{ span: 24, style: { marginBottom: "0%" } }}
										style={{ marginBottom: "1px" }}
										rules={[
											{
												required: true,
												message: "Please enter your password!",
											},
											{
												min: 6,
												message: "Password must be at least 6 characters long!",
											},
										]}
									>
										<Input.Password
											className={`w-[85%] ${shake ? "animate-shake" : ""}`}
											placeholder="Enter your Password"
										/>
									</Form.Item>
									<div className="grid grid-cols-2 grid-rows-1 gap-[15%] ">
										<Form.Item name="remember" valuePropName="checked">
											<Checkbox className="break-words text-start font-bold font-plus-jakarta-sans text-[11.53px] text-black">
												Remember me
											</Checkbox>
										</Form.Item>
										<Typography
											onClick={
												() =>
													navigate("/forGetPassword", {
														state: { from: window.location.pathname }, // Passing current path as the previous URL
													})
												// navigate("/forGetPassword")
											}
											className="translate-y-[15%] cursor-pointer justify-end break-words font-normal font-plus-jakarta-sans text-[#367AFF] text-[11.53px]"
										>
											Forgot Password
										</Typography>
									</div>
									<Form.Item>
										<button
											className="w-[85%] transform cursor-pointer rounded-lg border-none bg-blue-500 px-12 py-3 font-[700] font-plus-jakarta-sans text-sm text-white shadow-lg transition-transform duration-1000"
											htmltype="submit"
										>
											Sign In
										</button>
									</Form.Item>
								</Form>
							</div>
						</div>
					</Col>

					<Col xs={24} sm={12} md={8} lg={3.42} xl={8}></Col>
					<Col xs={24} sm={12} md={8} lg={3.42} xl={8}></Col>
					<Col xs={24} sm={12} md={8} lg={3.42} xl={8}></Col>
					<Col xs={24} sm={12} md={8} lg={3.42} xl={8}></Col>
					<Col xs={24} sm={12} md={8} lg={3.42} xl={8}></Col>
					<Col xs={24} sm={12} md={8} lg={3.42} xl={8}></Col>
					<Col xs={24} sm={12} md={8} lg={3.42} xl={8}></Col>
					<Col xs={24} sm={12} md={8} lg={3.42} xl={8}></Col>

					<Col
						xs={24}
						sm={24}
						md={24}
						lg={24}
						xl={24}
						className="relative mt-max-100vh flex max-h-screen flex-col justify-end"
					>
						<div className="flex flex-grow flex-col items-center">
							<Row gutter={24} className="flex-nowrap">
								<Col span={6} className="flex items-center justify-center whitespace-nowrap">
									<Typography
										className="cursor-pointer text-justify font-['Mulish'] font-semibold text-[#357aff] tracking-wider"
										onClick={() => openLinkInNewTab("https://wonderws.com/code-of-ethics-wws/")}
									>
										About Us
									</Typography>
								</Col>
								<Col span={6} className="flex items-center justify-center whitespace-nowrap">
									<Typography
										className="TermsOfService cursor-pointer text-justify font-['Mulish'] font-semibold text-[#357aff] tracking-wider"
										onClick={() => openLinkInNewTab("https://wonderws.com/terms-of-services/")}
									>
										Terms of Service
									</Typography>
								</Col>
								<Col span={6} className="flex items-center justify-center whitespace-nowrap">
									<Typography
										className="PrivacyPolicy cursor-pointer text-justify font-['Mulish'] font-semibold text-[#357aff] tracking-wider"
										onClick={() => navigate("/privacyPolicy")}
									>
										Privacy Policy
									</Typography>
								</Col>
								<Col span={6} className="flex items-center justify-center whitespace-nowrap">
									<Typography
										className="ContactSuppport cursor-pointer text-justify font-['Mulish'] font-semibold text-[#357aff] tracking-wider"
										onClick={() => openLinkInNewTab("https://wonderws.com/get-in-touch/")}
									>
										Contact Support
									</Typography>
								</Col>
							</Row>
							<div className="WonderWorthSolutionsLlc mt-4 text-justify font-['Mulish'] font-semibold text-gray-800 tracking-wider">
								© Wonder Worth Solutions LLC
							</div>
							<div className="VuesaxLinearCopyright relative h-3.5 w-3.5">
								<div className="Copyright absolute top-0 left-0 h-3.5 w-3.5"></div>
							</div>
						</div>
						<div className="absolute right-0 bottom-0 translate-x-[-50%] xs:translate-x-[20%] translate-y-[10%] transform sm:translate-x-[60%] md:translate-x-[5%] lg:translate-x-[60%]">
							<img src={BottomImage} alt="wwsProp" className="h-auto w-auto md:w-[70%] lg:w-[80%] xl:w-[20%]" />
						</div>
					</Col>
				</Row>
			)}
		</>
	);
};

export default SignIn;
