import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setLoading } from "../redux/loading/loadingSlice";
import { getRecentHistoryApi } from "../redux/recentHistory/recentHistoryService";

const usePaginatedRecentHistory = (
	initialPage = 1,
	initialPageSize = 10,
	initialSearchTerm = "",
	userId = "",
	projectId = "",
) => {
	const dispatch = useDispatch();
	const [history, setHistory] = useState([]);
	const [error, setError] = useState(null);
	const [recentHistoryCurrentPage, setRecentHistoryCurrentPage] = useState(initialPage);
	const [recentHistoryPageSize, setRecentHistoryPageSize] = useState(initialPageSize);
	const [totalHistory, setTotalHistory] = useState(0);
	const [searchTerm, setSearchTerm] = useState(initialSearchTerm);

	const fetchHistory = useCallback(async () => {
		dispatch(setLoading(true));
		try {
			const response = await dispatch(
				getRecentHistoryApi({
					userId,
					projectId,
					page: recentHistoryCurrentPage,
					pageSize: recentHistoryPageSize,
					searchTerm,
				}),
			).unwrap();
			setHistory(response?.history || []);
			setTotalHistory(response?.pagination?.totalRecords || 0);
		} catch (error) {
			setError(error);
		} finally {
			dispatch(setLoading(false));
		}
	}, [dispatch, projectId, recentHistoryCurrentPage, recentHistoryPageSize, searchTerm, userId]);

	const refreshRecentHistory = useCallback(() => {
		fetchHistory();
	}, [fetchHistory]);

	useEffect(() => {
		fetchHistory();
	}, [fetchHistory]);

	const handleSearchChange = (newSearchTerm) => {
		setSearchTerm(newSearchTerm);
		setRecentHistoryCurrentPage(1); // Reset to the first page when search term changes
	};

	return {
		history,
		error,
		recentHistoryCurrentPage,
		recentHistoryPageSize,
		totalHistory,
		refreshRecentHistory,
		setRecentHistoryCurrentPage,
		setRecentHistoryPageSize,
		setSearchTerm: handleSearchChange,
	};
};

export default usePaginatedRecentHistory;
