import { notification } from "antd";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

function ProtectorRoute({ children, allowedRoles }) {
	const userDetails = useSelector((state) => state.userDetails);
	const userRole = userDetails?.role;

	if (allowedRoles?.includes("PROVIDER")) {
		if (!userRole) {
			// Redirect unauthenticated users trying to access PROVIDER routes;
			return <Navigate to="/" replace />;
		}

		// Render children for authenticated PROVIDER users
		return children;
	}

	// Check user role and handle redirection and notification
	if (!userRole) {
		// Redirect to the login or home page if no role is present
		return <Navigate to="/internal" />;
	}

	if (!allowedRoles?.includes(userRole)) {
		// Show notification and redirect if the user does not have the required role
		notification.warning({
			message: "Access Denied",
			description: "You do not have the required permissions to access this page.",
			placement: "topRight",
		});
		return <Navigate to="/internal" />;
	}

	// Render the children if the user role is allowed
	return children;
}
export default ProtectorRoute;
