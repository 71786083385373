import { createAsyncThunk } from "@reduxjs/toolkit";
import { getPaginatedSubsequentDocuments } from "../../api/document/document";

// Async thunk to fetch documents
export const fetchPaginatedDocumentsApi = createAsyncThunk(
	"documents/fetchPaginatedDocuments",
	async ({ page, pageSize, searchTerm, project, userId, role, date }, { rejectWithValue }) => {
		try {
			const response = await getPaginatedSubsequentDocuments(page, pageSize, searchTerm, project, userId, role, date);
			return {
				documents: response?.data?.paginatedBatchNosWithCounts || [],
				totalDocuments: response?.data?.totalBatchNos || 0,
				pagedBatchNos: response?.data?.paginatedBatchNosWithCounts?.map((item) => item?.batchNo) || [],
			};
		} catch (error) {
			return rejectWithValue(error);
		}
	},
);
