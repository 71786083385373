import { Button, Layout } from "antd";
import { FaQuestion } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { globalFooterHeight } from "../constants/constants";

const { Footer } = Layout;

const AntFooter = () => {
	const navigate = useNavigate();
	const { role } = useSelector((state) => state.userDetails);
	const showHelpButton = role === "PROVIDER" || role === "USER";
	return (
		<Footer
			className="relative flex items-center justify-center bg-white text-center font-bold font-poppins text-xs"
			style={{ height: globalFooterHeight }}
		>
			<div>
				© {new Date().getFullYear()} : MYWWS Business Manager | Version V1.0.0 |
				<span
					className="cursor-pointer font-poppins text-blue-bm"
					onClick={() => window.open("/privacyPolicy", "_blank")}
				>
					&nbsp;Privacy policy
				</span>
			</div>
			{showHelpButton && (
				<Button
					type="primary"
					className="-translate-y-1/2 absolute top-1/2 right-6 transform px-2 py-1 text-xs transition-all duration-300 ease-in-out hover:scale-105 hover:bg-blue-600"
					onClick={() => navigate("/provider/faq")}
				>
					Need Help <FaQuestion />
				</Button>
			)}
		</Footer>
	);
};

export default AntFooter;
