import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import { Carousel } from "antd";
import { Button, DatePicker, Form, Input, Select } from "antd";
import dayjs from "dayjs";
import _, { isEmpty } from "lodash";
/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Gender, MaritalStatus, Relationship, ShortStates } from "../../assets/DispCodes.js";
import { updateChartApi } from "../../redux/chart/chartService.js";
import { setLoading } from "../../redux/loading/loadingSlice.js";
import {
	axiosSubmitErrorHandling,
	// formattedDate,
	getIpAddress,
	isEmptyObject,
	isObject,
	trimObjValues,
} from "../../utils/utility.js";

const { TextArea } = Input;
const dateFormat = "MM/DD/YYYY";

const initialPhoneAndMail = [
	{
		phoneNumber: "",
		mailId: "",
	},
];

const PatientDetails = ({
	chartDetail,
	setPatientDetailsSaved,
	setActiveSubTab,
	setBatchChanged,
	defaultSize,
	formRef,
	charts,
	setCharts,
	setChartDetailView,
	documentViewer,
	setEditPatientData,
	responsiveLayout,
}) => {
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const [showDobField, setShowDobField] = useState(false);
	const carouselRef = useRef(null);
	const [phoneMailEquipmentModel, setPhoneEquipmentModel] = useState([...initialPhoneAndMail]);
	const [showSubscriberDobField, setShowSubscriberDobField] = useState(false);
	const [dateOfBirth, setDateOfBirth] = useState(null);
	const [subscriberDateOfBirth, setSubscriberDateOfBirth] = useState(null);
	const role = useSelector((state) => state.userDetails.role);
	const EditAccess = role === "PROVIDER" || role === "USER" || role === "CODING" || role === "GUEST" ? false : true;
	const userId = useSelector((state) => state.userDetails.userId);
	const oldChartDetailView = useSelector((state) => state.chartViewManagement.chartDetailView);

	//state handling for Subsequent Scan Date
	const [showSubsequentScanField, setShowSubsequentScanField] = useState(false);
	const [subsequentScanDate, setSubsequentScanDate] = useState(null);

	// const colProps = documentViewer
	//   ? { xl: 24, lg: 12, md: 12, sm: 12, xs: 12 }
	//   : { xl: 8, lg: 8, md: 8, sm: 12, xs: 24 }; // Adjust based on your preferred layout

	const colProps = documentViewer
		? { xl: 24, lg: 12, md: 12, sm: 12, xs: 12 } // Adjust layout for documentViewer
		: responsiveLayout
			? { span: 24 } // Full width for dataCapture
			: { xl: 8, lg: 8, md: 8, sm: 12, xs: 24 }; // Regular layout

	const containerClass = responsiveLayout ? "my-2 overflow-auto max-h-[70vh] no-scrollbar" : "";

	useEffect(() => {
		const patientInitialValue = {
			/* Patient Detail Initial Values */
			// internalFtpDate: chartDetail?.internalFtpDate ? formattedDate(chartDetail?.internalFtpDate) : null,
			/* Parse internalFtpDate as an array of dayjs objects */
			internalFtpDate: chartDetail?.internalFtpDate
				? Array.isArray(chartDetail?.internalFtpDate)
					? chartDetail?.internalFtpDate?.map((date) => dayjs(date)) // Ensure they are dayjs objects
					: chartDetail?.internalFtpDate?.split(",")?.map((date) => dayjs(date?.trim()))
				: [],
			receivedOn: chartDetail?.receivedOn ? dayjs(chartDetail?.receivedOn) : null,
			initialSubsequentDate: chartDetail?.initialSubsequentDate ? dayjs(chartDetail?.initialSubsequentDate) : null,

			patientAcc: chartDetail?.chartId,
			patientName: chartDetail?.patientName,
			age: chartDetail?.age,
			patientPrimaryInsurance: chartDetail?.patientPrimaryInsurance,
			ssnNumber: chartDetail?.ssnNumber,
			gender: chartDetail?.gender,
			relationship: chartDetail?.relationship,
			subscriberName: chartDetail?.subscriberName,
			maritalStatus: chartDetail?.maritalStatus,
			receivedAddress: chartDetail?.receivedAddress,
			address: chartDetail?.address,
			city: chartDetail?.city,
			state: chartDetail?.state,
			zipCode: chartDetail?.zipCode,
		};

		if (
			chartDetail?.dob &&
			chartDetail?.dob !== "Invalid Date" &&
			chartDetail?.dob !== "U/A" &&
			chartDetail?.dob !== "N/A"
		) {
			const putDob = chartDetail?.dob ? dayjs(chartDetail?.dob) : "";
			setDateOfBirth(putDob);
			setShowDobField(true);
		} else {
			setShowDobField(false);
			setDateOfBirth(null);
		}

		if (chartDetail?.dob === "Invalid Date" || chartDetail?.dob === "U/A" || chartDetail?.dob === "N/A") {
			setShowDobField(false);
			setDateOfBirth(null);
		}

		if (
			chartDetail?.subscriberDob &&
			chartDetail?.subscriberDob !== "Invalid Date" &&
			chartDetail?.subscriberDob !== "U/A" &&
			chartDetail?.subscriberDob !== "N/A"
		) {
			const putSubscriberDob = chartDetail?.subscriberDob ? dayjs(chartDetail?.subscriberDob).format("MM/DD/YYYY") : "";
			setSubscriberDateOfBirth(putSubscriberDob);
			setShowSubscriberDobField(true);
		}

		if (
			chartDetail?.subscriberDob === "Invalid Date" ||
			chartDetail?.subscriberDob === "U/A" ||
			chartDetail?.subscriberDob === "N/A"
		) {
			setShowSubscriberDobField(false);
			setSubscriberDateOfBirth(null);
		}

		if (
			chartDetail?.initialSubsequentDate &&
			chartDetail?.initialSubsequentDate !== "Invalid Date" &&
			chartDetail?.initialSubsequentDate !== "U/A" &&
			chartDetail?.initialSubsequentDate !== "N/A"
		) {
			const initialSubsequentDate = chartDetail?.initialSubsequentDate
				? dayjs(chartDetail?.initialSubsequentDate).format("MM/DD/YYYY")
				: "";

			setShowSubsequentScanField(true);
			setSubsequentScanDate(initialSubsequentDate);
			// const putSubscriberDob = chartDetail?.subscriberDob ? dayjs(chartDetail?.subscriberDob).format("MM/DD/YYYY") : ''
			// setSubscriberDateOfBirth(putSubscriberDob);
			// setShowSubscriberDobField(true);
		}

		if (
			chartDetail?.initialSubsequentDate === "Invalid Date" ||
			chartDetail?.initialSubsequentDate === "U/A" ||
			chartDetail?.initialSubsequentDate === "N/A"
		) {
			setShowSubsequentScanField(false);
			setSubsequentScanDate(null);
		}

		form.setFieldsValue(patientInitialValue);
	}, [chartDetail, form]);

	// useEffect(() => {
	//   if (chartDetail && !isEmpty(chartDetail)) {
	//     if (Array.isArray(chartDetail?.phoneMailEquipmentModels) && chartDetail?.phoneMailEquipmentModels.length > 0) {
	//       setPhoneEquipmentModel(chartDetail.phoneMailEquipmentModels);
	//       form.setFieldsValue({
	//         mailID: chartDetail.phoneMailEquipmentModels?.mailID || "", // Adjust key as per your data structure
	//     })

	//     }
	//   }
	// }, [chartDetail])

	useEffect(() => {
		if (chartDetail && !isEmpty(chartDetail)) {
			if (Array.isArray(chartDetail?.phoneMailEquipmentModels) && chartDetail?.phoneMailEquipmentModels.length > 0) {
				setPhoneEquipmentModel(chartDetail.phoneMailEquipmentModels);
				form.setFieldsValue({
					phoneMailEquipmentModels: chartDetail.phoneMailEquipmentModels, // Ensure this matches the form structure
				});
			}
		}
	}, [chartDetail]);

	//Save Patient Details
	const onFinish = async (values) => {
		try {
			const items = {
				...values,
				phoneMailEquipmentModels: phoneMailEquipmentModel,
			};

			const hasEmptyMailId = phoneMailEquipmentModel.some((item) => !item.mailId || item.mailId.trim() === "");

			if (hasEmptyMailId) {
				toast.error("Mail ID cannot be empty.");
				return; // Prevent form submission
			}

			// if (subsequentScanDate === "Invalid Date") {
			//   items.initialSubsequentDate = "U/A"

			// }

			// if (showSubsequentScanField && !subsequentScanDate) {
			//   items.initialSubsequentDate = "N/A"
			// }

			// Set initialSubsequentDate if subsequentScanDate has a value
			if (subsequentScanDate) {
				items.initialSubsequentDate = dayjs(subsequentScanDate).format(dateFormat);
			} else {
				items.initialSubsequentDate = "N/A"; // Default to 'N/A' if no value
			}

			if (dateOfBirth === "Invalid Date") {
				items.dob = "U/A";
			}

			if (!showDobField && !dateOfBirth) {
				items.dob = "N/A";
			}

			if (dateOfBirth && dateOfBirth !== "Invalid Date") {
				items.dob = dayjs(dateOfBirth).format(dateFormat);
			}

			if (subscriberDateOfBirth === "Invalid Date") {
				items.subscriberDob = "U/A";
			}

			if (!showSubscriberDobField && !subscriberDateOfBirth) {
				items.subscriberDob = "N/A";
			}

			if (subscriberDateOfBirth && subscriberDateOfBirth !== "Invalid Date") {
				items.subscriberDob = dayjs(subscriberDateOfBirth).format("MM/DD/YYYY");
			}

			if (items?.patientName && items?.dob && items?.patientPrimaryInsurance) {
				items.isValidChart = true;
			}

			if (!items?.patientName && !items?.dob && !items?.patientPrimaryInsurance) {
				items.isValidChart = false;
			}

			if (items?.patientName?.toUpperCase() !== "U/A" || items?.patientName?.toUpperCase() !== "N/A") {
				items.isValidChart = true;
			}

			if (items?.patientName?.toUpperCase() === "U/A" || items?.patientName?.toUpperCase() === "N/A") {
				items.isValidChart = false;
			}

			// if (Array?.isArray(items.internalFtpDate)) {
			//   items.internalFtpDate = items.internalFtpDate
			//     ?.filter(date => date !== null) // Filter out null values
			//     ?.map(date => dayjs(date).format('MM/DD/YYYY')) // Format each date
			//     ?.join(', '); // Join into a single string separated by commas
			// } else {
			//   items.internalFtpDate = dayjs(items.internalFtpDate).format('MM/DD/YYYY');
			// }

			if (Array.isArray(items.internalFtpDate)) {
				// Validate and format the internalFtpDate array
				items.internalFtpDate = items.internalFtpDate
					.filter((date) => dayjs(date).isValid()) // Ensure valid dates
					.map((date) => dayjs(date).format(dateFormat)); // Format to MM/DD/YYYY
			} else {
				items.internalFtpDate = []; // Default to an empty array if not valid
			}

			// items.initialSubsequentDate = dayjs(items.initialSubsequentDate).format('MM/DD/YYYY');
			items.receivedOn = dayjs(items.receivedOn).format("MM/DD/YYYY");

			items.userRole = role;
			items.userId = userId;
			const ipAddress = await getIpAddress();
			items.ipAddress = ipAddress;

			/* check is object */
			if (!isObject(items)) {
				return toast.warning("This is not object data!");
			}

			/* check is empty object */
			if (isEmptyObject(items)) {
				return toast.warning("Empty object cannot accept!");
			}

			/* trim values */
			trimObjValues(items);
			dispatch(setLoading(true));
			setDateOfBirth(null);
			dispatch(setPatientDetailsSaved(true));

			setActiveSubTab("2");
			setBatchChanged(false);
			const updateId = chartDetail?.id || chartDetail?._id;
			const res = await dispatch(updateChartApi({ updateId, items })).unwrap();
			const rowIndex = _.findIndex(charts, { id: updateId });
			dispatch(setPatientDetailsSaved(true));

			if (rowIndex > -1) {
				const updatedCharts = [...charts];
				const updatedItem = { ...updatedCharts[rowIndex], ...res };
				updatedCharts[rowIndex] = updatedItem;
				setCharts(updatedCharts);
			}

			// Update patient details for users this state comes from UpdatePatient component under chart folder
			if (typeof setEditPatientData === "function") {
				setEditPatientData((prevData) => ({
					...prevData,
					...res, // Update editPatientData with the latest data from the API
				}));
			}

			if (typeof setChartDetailView === "function") {
				// Access the current ChartDetailView from the Redux store

				// Merge the old data with the new data
				const updatedData = {
					...oldChartDetailView.data, // Existing data
					...res, // New response data
				};

				// Dispatch the updated state
				dispatch(
					setChartDetailView({
						selected: true,
						data: updatedData, // Merged data
					}),
				);
			}

			// if (typeof setChartDetailView === 'function') {

			//   dispatch(setChartDetailView((prevView) => ({
			//     ...prevView,  // Keep the rest of the view intact

			//     data: {
			//       ...prevView.data, // Merge the current data
			//       ...res,  // Override with updated response from the API
			//     },
			//     selected: true,  // Ensure the chart is still selected
			//   })));
			// }

			toast.success("Patient details updated successfully!");
			dispatch(setLoading(false));
		} catch (error) {
			dispatch(setLoading(false));
			return axiosSubmitErrorHandling(error);
		}
	};

	const onFinishFailed = (errorInfo) => {
		toast.error("Invalid Mail ID!");
		console.error("Failed:", errorInfo);
	};

	const onChange = (value) => {};

	const onSearch = (value) => {};

	const stateFilter = (input, option) => {
		const inputValue = input?.trim()?.toLowerCase();
		const optionValue = option?.value?.toLowerCase();
		const optionLabel = option?.label?.toLowerCase();

		return (
			(inputValue.length === 2 && optionValue === inputValue) ||
			(inputValue.length > 2 && optionLabel.includes(inputValue))
		);
	};

	const filterOption = (input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

	const onChangeDatePatientReceivedOnPicker = (date, dateString) => {};

	// const onChangeInternalFTPPicker = (date, dateString) => {

	// };

	const onChangeInternalFTPPicker = (dates) => {
		// if (Array.isArray(dates)) {
		//   // Map selected dates to formatted strings
		//   const formattedDates = dates?.map((date) => date ? dayjs(date)?.format(dateFormat) : null);
		//   form.setFieldsValue({ internalFtpDate: formattedDates });
		// }
	};

	const onChangeInitialSudScanPicker = (date, dateString) => {
		setSubsequentScanDate(dateString);
	};

	const onChangeDatePicker = (date, dateString) => {
		setDateOfBirth(dayjs(date).format("MM/DD/YYYY"));
		const age = dayjs().diff(dayjs(date), "year");
		form.setFieldsValue({ age });
	};

	// Function to disable dates beyond the current date
	const disabledDate = (current) => {
		// Get the current date and year
		const currentDate = dayjs();
		const currentYear = currentDate.year();

		// Disable dates after the current year or after the current date
		return current && (dayjs(current).year() > currentYear || dayjs(current).isAfter(currentDate, "day"));
	};

	const onChangeSubDatePicker = (date, dateString) => {
		setSubscriberDateOfBirth(dayjs(date).format("MM/DD/YYYY"));
	};

	const formatPhoneNumber = (value) => {
		// Remove all non-numeric characters
		const cleaned = value.replace(/\D/g, "");

		// Limit the length to 10 digits
		const limited = cleaned.slice(0, 10);

		// Format the phone number according to (999) 999-9999 pattern
		const match = limited.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);

		if (match) {
			const [, areaCode, centralOfficeCode, lineNumber] = match;
			if (lineNumber) {
				return `(${areaCode}) ${centralOfficeCode}-${lineNumber}`;
			} else if (centralOfficeCode) {
				return `(${areaCode}) ${centralOfficeCode}`;
			} else if (areaCode) {
				return `(${areaCode}`;
			}
		}
		return value;
	};

	//Functions to handle add and remove Subsequent Scan Date

	const handleAddSubsequentScan = () => {
		setShowSubsequentScanField(true);
		setSubsequentScanDate(null);
	};

	const handleRemoveSubsequentScan = () => {
		setShowSubsequentScanField(false);
		setSubsequentScanDate(null);
		form.setFieldsValue({ initialSubsequentDate: "" });
	};

	const handleRemoveDob = () => {
		setShowDobField(false);
		setDateOfBirth(null);
		form.setFieldsValue({ dob: "" });
		form.setFieldsValue({ age: "" });
	};

	const handleAddDob = () => {
		setDateOfBirth(null);
		setShowDobField(true);
	};

	const handleRemoveSubscriberDob = () => {
		setShowSubscriberDobField(false);
		setSubscriberDateOfBirth(null);
		form.setFieldsValue({ subscriberDob: "" });
	};

	const handleAddSubscriberDob = () => {
		setSubscriberDateOfBirth(null);
		setShowSubscriberDobField(true);
	};

	const addPhoneMailModel = () => {
		const createObj = {};
		createObj.phoneNumber = "";
		createObj.mailId = "";
		setPhoneEquipmentModel((preValue) => {
			return [...preValue, createObj];
		});
		setTimeout(() => {
			carouselRef.current.goTo(phoneMailEquipmentModel.length);
		}, 0);
	};

	const removePhoneMailModel = (index) => {
		if (index > -1) {
			const items = [...phoneMailEquipmentModel];
			items.splice(index, 1);
			setPhoneEquipmentModel(items);
			carouselRef.current?.prev();
		}
	};

	const handleChangePhoneMailEquipment = (name, event, index) => {
		const targetValue = event.target.value;
		const items = [...phoneMailEquipmentModel];
		const item = { ...items[index] };

		item[name] = targetValue;

		// If the value is "U/A" or "N/A", update all relevant fields
		if (targetValue === "U/A" || targetValue === "N/A" || targetValue === "u/a" || targetValue === "n/a") {
			item.phoneNumber = targetValue.toUpperCase();
			item.mailId = targetValue.toUpperCase();
		}

		items[index] = item;
		setPhoneEquipmentModel(items);
	};

	return (
		<div className={containerClass}>
			<Form
				ref={formRef}
				layout="vertical"
				form={form}
				onFinish={onFinish}
				onFinishFailed={onFinishFailed}
				autoComplete="off"
				size={defaultSize === "dataCapture" ? "small" : ""}
			>
				<Row gutter={16}>
					<Col
						{...colProps}
						// xl={8} lg={8} md={8} sm={12} xs={24}
					>
						<Form.Item
							className="mb-2"
							label="Internal FTP Date"
							name="internalFtpDate"
							style={!EditAccess ? { pointerEvents: "none" } : {}}
						>
							<DatePicker
								multiple
								className="w-full"
								onChange={onChangeInternalFTPPicker}
								format={dateFormat}
								disabledDate={disabledDate}
								style={!EditAccess ? { pointerEvents: "none" } : {}}
							/>
						</Form.Item>
						<Form.Item
							className="mb-2"
							label="Patient Received On"
							name="receivedOn"
							style={!EditAccess ? { pointerEvents: "none" } : {}}
							rules={[{ required: true, message: "Please enter the Patient Received On!" }]}
						>
							<DatePicker
								className="w-full"
								onChange={onChangeDatePatientReceivedOnPicker}
								format={dateFormat}
								disabledDate={disabledDate}
								style={!EditAccess ? { pointerEvents: "none" } : {}}
							/>
						</Form.Item>
						{showSubsequentScanField ? (
							<Form.Item
								className="mb-2"
								label="Subsequent Scan Date"
								name="initialSubsequentDate"
								//style={!EditAccess ? { pointerEvents: "none" } : {}}
							>
								<DatePicker
									className="w-full"
									value={subsequentScanDate ? dayjs(subsequentScanDate) : null}
									onChange={onChangeInitialSudScanPicker}
									format={dateFormat}
									disabledDate={disabledDate}
									style={!EditAccess ? { pointerEvents: "none" } : {}}
								/>
								<Button type="link" onClick={handleRemoveSubsequentScan} disabled={!EditAccess}>
									Remove Subsequent Scan Date
								</Button>
							</Form.Item>
						) : (
							<Button type="link" onClick={handleAddSubsequentScan} disabled={!EditAccess}>
								Add Subsequent Scan Date
							</Button>
						)}

						<Form.Item className="mb-2" label="UUID" name="patientAcc">
							<Input className="cursor-pointer" type="text" readOnly />
						</Form.Item>
						<Form.Item
							className="mb-2"
							label="Patient Name"
							name="patientName"
							rules={[
								{ required: true, message: "Please enter the patient name!" },
								{
									validator: (_, value) => {
										// Regular expression to check for at least one alphanumeric character
										const hasAlphanumeric = /[a-zA-Z0-9]/.test(value);
										if (hasAlphanumeric) {
											return Promise.resolve();
										} else {
											return Promise.reject(new Error("Name cannot consist of only special characters!"));
										}
									},
								},
							]}
						>
							<Input
								type="text"
								maxLength={200}
								onInput={(e) => {
									const inputValue = e.target.value.toUpperCase();
									e.target.value = inputValue;
								}}
								readOnly={!EditAccess}
							/>
						</Form.Item>

						{showDobField ? (
							<Form.Item className="mb-2" label="Date of Birth" name="dob">
								<DatePicker
									className="w-full"
									// defaultValue={dateOfBirth ? dayjs(dateOfBirth) : null}
									value={dateOfBirth ? dayjs(dateOfBirth) : null}
									onChange={onChangeDatePicker}
									format={dateFormat}
									disabledDate={disabledDate}
									style={!EditAccess ? { pointerEvents: "none" } : {}}
								/>
								<Button type="link" onClick={handleRemoveDob} disabled={!EditAccess}>
									Remove Date of Birth
								</Button>
							</Form.Item>
						) : (
							<Button type="link" onClick={handleAddDob} disabled={!EditAccess}>
								Add Date of Birth
							</Button>
						)}

						{showDobField && (
							<Form.Item
								className="mb-2"
								label="Age"
								name="age"
								rules={[
									{
										validator: (_, value) => {
											if (value <= 120) {
												return Promise.resolve();
											}
											return Promise.reject("Age must be below 120!");
										},
									},
								]}
							>
								<Input readOnly />
							</Form.Item>
						)}
						<Form.Item
							className="mb-2"
							label="Primary Insurance Number"
							name="patientPrimaryInsurance"
							rules={[
								{
									pattern: /^(U\/A|N\/A|[A-Z\d]{4,16})$/,
									message:
										'Please enter the primary insurance number with 4 to 16 alphanumeric characters (e.g., XEK910553924) or "U/A" or "N/A".',
								},
							]}
						>
							<Input
								className="cursor-pointer"
								onInput={(e) => {
									const inputValue = e.target.value.toUpperCase();
									e.target.value = inputValue;
								}}
								readOnly={!EditAccess}
							/>
						</Form.Item>
						<Form.Item
							className="mb-2"
							label="SSN Number"
							name="ssnNumber"
							rules={[
								{
									validator: (_, value) => {
										if (value) {
											// Match exactly 9 digits, or allow "U/A" or "N/A"
											if (/^\d{9}$/.test(value) || value.toUpperCase() === "U/A" || value.toUpperCase() === "N/A") {
												return Promise.resolve();
											} else {
												return Promise.reject('SSN Number must be exactly 9 digits or be "U/A" or "N/A".');
											}
										}
										return Promise.resolve(); // Allow empty input if not required
									},
								},
							]}
						>
							<Input
								onInput={(e) => {
									let inputValue = e.target.value.toUpperCase();
									// Allow only digits, "U/A", or "N/A"
									if (/^\d*$/.test(inputValue)) {
										// Valid digit input
										e.target.value = inputValue;
									} else if (
										inputValue === "U" ||
										inputValue === "U/" ||
										inputValue === "U/A" ||
										inputValue === "N" ||
										inputValue === "N/" ||
										inputValue === "N/A"
									) {
										// Allow partial and complete "U/A" or "N/A"
										e.target.value = inputValue;
									} else {
										// Remove invalid characters
										inputValue = inputValue.replace(/[^0-9U\\/A]/g, "");
										if (inputValue.length <= 9 || inputValue === "U/A" || inputValue === "N/A") {
											e.target.value = inputValue;
										} else {
											e.target.value = inputValue.slice(0, 9); // Ensure max length is 9 digits
										}
									}
								}}
								maxLength={9} // Maximum length to ensure only 9 digits or "U/A" or "N/A"
								readOnly={!EditAccess}
							/>
						</Form.Item>
					</Col>
					<Col
						{...colProps}
						// xl={8} lg={8} md={8} sm={12} xs={24}
					>
						<Form.Item
							className="mb-2"
							label="Gender"
							name="gender"
							style={!EditAccess ? { pointerEvents: "none" } : {}}
						>
							<Select
								showSearch
								allowClear
								placeholder="Select a gender"
								optionFilterProp="children"
								onChange={onChange}
								onSearch={onSearch}
								filterOption={filterOption}
								options={Gender}
								style={!EditAccess ? { pointerEvents: "none" } : {}}
							/>
						</Form.Item>
						<Form.Item
							className="mb-2"
							label="Relationship"
							name="relationship"
							style={!EditAccess ? { pointerEvents: "none" } : {}}
						>
							<Select
								showSearch
								allowClear
								placeholder="Select a relationship"
								optionFilterProp="children"
								onChange={onChange}
								onSearch={onSearch}
								filterOption={filterOption}
								options={Relationship}
								style={!EditAccess ? { pointerEvents: "none" } : {}}
							/>
						</Form.Item>
						<Form.Item className="mb-2" label="Subscriber Name" name="subscriberName">
							<Input
								type="text"
								onInput={(e) => {
									const inputValue = e.target.value.toUpperCase();
									e.target.value = inputValue;
								}}
								readOnly={!EditAccess}
							/>
						</Form.Item>

						{showSubscriberDobField ? (
							<Form.Item className="mb-2" label="Subscriber Date of Birth" name="subscriberDob">
								<DatePicker
									className="w-full"
									value={subscriberDateOfBirth ? dayjs(subscriberDateOfBirth) : null}
									onChange={onChangeSubDatePicker}
									format={dateFormat}
									disabledDate={disabledDate}
									style={!EditAccess ? { pointerEvents: "none" } : {}}
								/>
								<Button type="link" onClick={handleRemoveSubscriberDob} disabled={!EditAccess}>
									Remove Subscriber of Birth
								</Button>
							</Form.Item>
						) : (
							<Button type="link" onClick={handleAddSubscriberDob} disabled={!EditAccess}>
								{" "}
								Add Subscriber of Birth
							</Button>
						)}
						<Form.Item
							className="mb-2"
							label="Marital Status"
							name="maritalStatus"
							style={!EditAccess ? { pointerEvents: "none" } : {}}
						>
							<Select
								showSearch
								allowClear
								placeholder="Select a marital status"
								optionFilterProp="children"
								onChange={onChange}
								onSearch={onSearch}
								filterOption={filterOption}
								options={MaritalStatus}
								style={!EditAccess ? { pointerEvents: "none" } : {}}
							/>
						</Form.Item>
						<Form.Item className="mb-2" label="Address Received in PRF/LMN" name="receivedAddress">
							<TextArea
								rows={1}
								onInput={(e) => {
									const inputValue = e.target.value.toUpperCase();
									e.target.value = inputValue;
								}}
								readOnly={!EditAccess}
							/>
						</Form.Item>
					</Col>
					<Col
						{...colProps}
						//  xl={8} lg={8} md={8} sm={12} xs={24}
					>
						<Form.Item className="mb-2" label="Address" name="address">
							<Input
								onInput={(e) => {
									const inputValue = e.target.value.toUpperCase();
									e.target.value = inputValue;
								}}
								readOnly={!EditAccess}
							/>
						</Form.Item>
						<Form.Item className="mb-2" label="City" name="city">
							<Input
								onInput={(e) => {
									const inputValue = e.target.value.toUpperCase();
									e.target.value = inputValue.replace(/[^A-Z\\/\s]/g, "");
								}}
								readOnly={!EditAccess}
							/>
						</Form.Item>
						<Form.Item className="mb-2" label="State" name="state" style={!EditAccess ? { pointerEvents: "none" } : {}}>
							<Select
								showSearch
								allowClear
								placeholder="Select a state"
								optionFilterProp="children"
								onChange={onChange}
								onSearch={onSearch}
								filterOption={stateFilter}
								options={ShortStates}
								style={!EditAccess ? { pointerEvents: "none" } : {}}
							/>
						</Form.Item>
						<Form.Item
							className="mb-2"
							label="Zip"
							name="zipCode"
							rules={[
								{
									validator: (_, value) => {
										// Check if the value is a valid 5-digit zip code or 'U/A' or 'N/A'
										if (value) {
											if (/^\d{5}$/.test(value)) {
												return Promise.resolve();
											} else if (value === "U/A" || value === "N/A") {
												return Promise.resolve();
											} else {
												return Promise.reject('Please enter a valid 5-digit zip code or "U/A" or "N/A".');
											}
										}
										return Promise.resolve(); // Allow empty input if not required
									},
								},
							]}
						>
							<Input
								className="cursor-pointer"
								type="text"
								onInput={(e) => {
									const trimmedValue = e.target.value.replace(/\D/g, "").slice(0, 5).toUpperCase();
									e.target.value = /^[0-9]/.test(e.target.value) ? trimmedValue : e.target.value.toUpperCase();
								}}
								readOnly={!EditAccess}
							/>
						</Form.Item>
						<Form.Item className="mb-2">
							<Carousel arrows ref={carouselRef} className="rounded bg-[#f5f5f5] p-6">
								{Array.isArray(phoneMailEquipmentModel) &&
									phoneMailEquipmentModel.length > 0 &&
									phoneMailEquipmentModel.map((ele, index) => {
										return (
											<React.Fragment key={index}>
												<Form.Item
													className="mb-2"
													label="Phone Number"
													rules={[
														{
															validator: (_, value) => {
																if (value === "U/A" || value === "N/A") {
																	return Promise.resolve();
																}
																const phonePattern = /^\(\d{3}\) \d{3}-\d{4}$/;
																if (phonePattern.test(value)) {
																	return Promise.resolve();
																}
																return Promise.reject(
																	'Please enter a valid phone number in the format (999) 153-5556 or "U/A" or "N/A".',
																);
															},
														},
													]}
												>
													<Input
														value={ele.phoneNumber}
														className="cursor-pointer"
														maxLength={14} // Setting the maximum length of the input field to 14 characters
														onInput={(e) => {
															e.target.value = formatPhoneNumber(e.target.value);
															handleChangePhoneMailEquipment("phoneNumber", e, index);
														}}
														onKeyPress={(e) => {
															// Allow only numbers, control keys, and the letters U, A, and slash
															if (
																!/[0-9UunaAN\\/]/.test(e.key) &&
																e.key !== "Backspace" &&
																e.key !== "Delete" &&
																e.key !== "ArrowLeft" &&
																e.key !== "ArrowRight"
															) {
																e.preventDefault();
															}
														}}
														readOnly={!EditAccess}
													/>
												</Form.Item>
												<Form.Item
													className="mb-2"
													label="Mail ID"
													name={["phoneMailEquipmentModels", index, "mailId"]}
													rules={[
														{
															validator: (_, value) => {
																if (!value) {
																	return Promise.resolve();
																}
																const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
																if (emailRegex.test(value) || value === "U/A" || value === "N/A") {
																	return Promise.resolve();
																}
																return Promise.reject(new Error('Enter a valid email or "U/A" or "N/A"!'));
															},
														},
													]}
												>
													<Input
														//value={ele?.mailId}
														onChange={(e) => {
															handleChangePhoneMailEquipment("mailId", e, index);
														}}
														readOnly={!EditAccess}
													/>
												</Form.Item>
												<div className="grid grid-cols-2 grid-rows-1 gap-0">
													<Button
														size="small"
														type="dashed"
														icon={<PlusOutlined />}
														onClick={addPhoneMailModel}
														className="mt-2"
														disabled={!EditAccess}
													>
														Add Details
													</Button>
													{phoneMailEquipmentModel.length > 1 ? (
														<Button
															size="small"
															type="dashed"
															danger
															warning
															icon={<MinusOutlined />}
															onClick={() => removePhoneMailModel(index)}
															className="mt-2"
															disabled={!EditAccess}
														>
															Remove Details
														</Button>
													) : (
														""
													)}
												</div>
											</React.Fragment>
										);
									})}
							</Carousel>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col xl={12} lg={12} md={12} sm={12} xs={12}>
						<Form.Item className="mb-2">{/* <Button className="w-fit mt-2 font-medium">Cancel</Button> */}</Form.Item>
					</Col>
					<Col xl={12} lg={12} md={12} sm={12} xs={12}>
						<Form.Item className="mb-2">
							<Button
								className="float-right mt-2 w-fit bg-blue-400 font-medium"
								type="primary"
								htmlType="submit"
								disabled={!EditAccess}
							>
								Save
							</Button>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</div>
	);
};

export default memo(PatientDetails);
