import { InfoCircleOutlined } from "@ant-design/icons";
import { Badge, Col, Row, Tooltip, Form } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import ChartInfo from "../../components/ChartInfo";
import ModalBox from "../../components/ModalBox";
import { resetChartViewState } from "../../redux/ChartViewManagement/ChartViewManagementSlice";
import UpdateTabPanel from "./UpdateTabPanel";
import { setNewDocument } from "../../redux/chartDocument/chartDocumentSlice";

const UpdatePatient = ({ editPatientViewStatus, setEditPatientViewStatus, editPatientData, setEditPatientData }) => {
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const [isInfoVisible, setIsInfoVisible] = useState(false);
	const getContent = () => {
		return (
			<Row gutter={16}>
				<Col xl={24} lg={24} md={24} sm={24} xs={24}>
					<UpdateTabPanel editPatientData={editPatientData} setEditPatientData={setEditPatientData} form={form} />
				</Col>
			</Row>
		);
	};

	return (
		<>
			{editPatientViewStatus && (
				<ModalBox
					open={editPatientViewStatus}
					width="xl"
					title={
						<div style={{ display: "flex", alignItems: "center" }}>
							Update Patient Details
							<Tooltip title="Chart Status">
								<Badge
									count={editPatientData?.vfCategory || ""}
									showZero
									color="#faad14"
									style={{
										marginLeft: 8,
										fontWeight: 500,
										paddingTop: "2px",
										paddingBottom: "2px",
										height: "auto",
										letterSpacing: 2,
										fontSize: 14,
										borderRadius: 5,
									}}
								/>
							</Tooltip>
							<Tooltip title="View Chart Info">
								<InfoCircleOutlined
									style={{ marginLeft: 8, cursor: "pointer" }}
									onClick={() => {
										setIsInfoVisible(!isInfoVisible);
									}}
								/>
							</Tooltip>
						</div>
					}
					content={getContent()}
					onCancel={() => {
						setEditPatientViewStatus(false);
						setEditPatientData([]);
						dispatch(resetChartViewState());
						dispatch(setNewDocument([]));
						form.resetFields();
					}}
				/>
			)}
			{isInfoVisible && (
				<ChartInfo isInfoVisible={isInfoVisible} setIsInfoVisible={setIsInfoVisible} chartData={editPatientData} />
			)}
		</>
	);
};

export default UpdatePatient;
