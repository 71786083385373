import { Popover, Spin, Switch, Tag, Tooltip } from "antd";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import _ from "lodash";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import NotificationWithRed from "../../assets/svg_images/Notifications with dot.svg";
import NotificationIcon from "../../assets/svg_images/notification.svg";
import { setLoading } from "../../redux/loading/loadingSlice";

dayjs.extend(relativeTime);
dayjs.locale("en");

const color = "#458ff6";

const tagClassName = "font-poppins text-md";

const countUnreadMessages = (notificationList, userId) => {
	const finalValue = notificationList?.filter(
		(notification) =>
			notification?.readStatus && notification?.readStatus?.userId === userId && !notification?.readStatus?.isRead,
	)?.length;
	return finalValue;
};

const Notification = ({ open, setOpen }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { notificationList } = useSelector((state) => state.notification);
	const role = useSelector((state) => state.userDetails.role);
	const userId = useSelector((state) => state.userDetails.userId);
	const userProject = useSelector((state) => state.userDetails.project);
	const roleId = useSelector((state) => state.userDetails.roleId);

	const [hasUnreadNotifications, setHasUnreadNotifications] = useState(false);
	const [isLoading, setIsLoading] = useState(false); // Changed from ref to state
	const [notificationListCopy, setNotificationListCopy] = useState([]);
	const switchToggler = useRef(false);
	const SortByStatus = useRef(0);
	const unReadCount = useRef(0);
	const isRequestInProgressRef = useRef(false); // Prevent simultaneous calls

	useEffect(() => {
		const fetchNotifications = async () => {
			if (isRequestInProgressRef.current) {
				return;
			}

			isRequestInProgressRef.current = true; // Set flag to indicate a call is in progress

			try {
				dispatch(setLoading(true));

				// Dynamically import the API service
				const { getTodayNotificationsApi } = await import("../../redux/notification/notificationService");
				await dispatch(getTodayNotificationsApi({ userProject, role, userId, roleId })).unwrap();
			} catch (error) {
			} finally {
				setIsLoading(false);
				// isRequestInProgressRef.current = false; // Reset the flag
				dispatch(setLoading(false));
			}
		};

		fetchNotifications();
	}, [dispatch, role, userId, userProject, roleId]);

	useEffect(() => {
		const testing = async () => {
			if (switchToggler.current) {
				const { default: _ } = await import("lodash");
				const filteredArray = _.filter(notificationList, (o) => o?.readStatus && !o?.readStatus?.isRead);
				// setNotificationListCopy(filteredArray);
				setNotificationListCopy(_.uniqBy(filteredArray, "_id"));
			} else {
				// setNotificationListCopy(notificationList);
				setNotificationListCopy(_.uniqBy(notificationList, "_id"));
			}
			unReadCount.current = countUnreadMessages(notificationList, userId);
			const unreadExists = notificationList?.some(
				(notification) =>
					notification?.readStatus && notification?.readStatus?.userId === userId && !notification?.readStatus?.isRead,
			);

			setHasUnreadNotifications(unreadExists);
		};
		testing();
	}, [notificationList, userId]);

	const handleNotificationClick = useCallback(
		async ({ id, type }) => {
			const updatedList = notificationListCopy?.map((notification) => {
				if (notification?._id === id) {
					return {
						...notification,
						readStatus: {
							...notification?.readStatus,
							isRead: true,
						},
					};
				}
				return notification;
			});

			setNotificationListCopy(updatedList);
			// switchToggler.current === true ? handleChecked() : setNotificationListCopy(updatedList);

			try {
				const { default: _ } = await import("lodash");

				// Find the notification in the list
				const result = _.find(notificationListCopy, { _id: id });

				// Example condition to update read status
				if (result && !result.readStatus?.isRead) {
					// Uncomment and use if needed to mark as read:
					const { markNotificationAsReadApi } = await import("../../redux/notification/notificationService");
					await dispatch(
						markNotificationAsReadApi({
							notificationId: id,
							readStatusId: result?.readStatus?._id,
							userId: userId,
							role: role,
							type: type,
						}),
					).unwrap();

					// setNotifications((prevNotifications) =>
					//   prevNotifications.map((notification) =>
					//     notification?.id === id ? { ...notification, isRead: true } : notification
					//   )
					// );
				}
			} catch (error) {}
		},
		[dispatch, notificationListCopy, role, userId],
	);

	const handleNotificationClickWithId = useCallback(
		(id, type) => {
			return () => {
				handleNotificationClick(id);
			};
		},
		[handleNotificationClick],
	);

	const tags = [
		{
			key: 0,
			name: "all",
			htmlContent: (
				<Tooltip title="View all notifications" placement="top">
					<Tag
						className="cursor-pointer rounded-2xl px-3 font-poppins text-md"
						color={SortByStatus?.current === 0 ? color : null}
					>
						All
					</Tag>
				</Tooltip>
			),
		},
		{
			key: 1,
			name: "byUser",
			htmlContent: (
				<Tooltip title="View notifications related to user activities" placement="top">
					{" "}
					<Tag
						className="cursor-pointer rounded-2xl px-3 font-poppins text-md"
						color={SortByStatus?.current === 1 ? color : null}
					>
						By User
					</Tag>
				</Tooltip>
			),
		},
		{
			key: 2,
			name: "bySystem",
			htmlContent: (
				<Tooltip title="View notifications generated by the system" placement="top">
					{" "}
					<Tag
						className="cursor-pointer rounded-2xl px-3 font-poppins text-md"
						color={SortByStatus?.current === 2 ? color : null}
					>
						By System
					</Tag>
				</Tooltip>
			),
		},
		{
			key: 3,
			name: "byAlerts",
			htmlContent: (
				<Tooltip title="View notifications related to the alerts" placement="top">
					{" "}
					<Tag
						className="cursor-pointer rounded-2xl px-3 font-poppins text-md"
						color={SortByStatus?.current === 3 ? color : null}
					>
						By Alerts
					</Tag>
				</Tooltip>
			),
		},
	];

	const handleFilter = useCallback(
		async (selectedKey, name) => {
			SortByStatus.current = selectedKey;

			// Filter notifications based on the selected tab
			let filteredArray = [];
			if (selectedKey === 0) {
				filteredArray = notificationList;
			} else if (selectedKey === 1) {
				filteredArray = notificationList.filter(
					(notification) =>
						notification?.type === "batchUpload" ||
						notification?.type === "Workflow" ||
						notification?.type === "User Account",
				);
			} else if (selectedKey === 2) {
				filteredArray = notificationList.filter((notification) => notification?.type === "System");
			} else if (selectedKey === 3) {
				filteredArray = notificationList.filter((notification) => notification?.type === "alerts");
			}

			// Further filter by unread if the switch is on
			if (switchToggler.current) {
				filteredArray = filteredArray.filter(
					(notification) => notification?.readStatus && !notification?.readStatus?.isRead,
				);
			}

			setNotificationListCopy(filteredArray);
		},
		[notificationList],
	);

	const handleSwitchChange = useCallback(
		async (checked) => {
			if (checked) {
				const { default: _ } = await import("lodash");
				switchToggler.current = true;

				// Filter notifications for unread only
				let filteredArray = _.filter(notificationList, (o) => o?.readStatus && !o?.readStatus?.isRead);

				// Apply additional filter based on the selected tab (SortByStatus)
				if (SortByStatus.current === 1) {
					filteredArray = filteredArray.filter(
						(notification) =>
							notification?.type === "batchUpload" ||
							notification?.type === "Workflow" ||
							notification?.type === "User Account",
					);
				} else if (SortByStatus.current === 2) {
					filteredArray = filteredArray.filter((notification) => notification?.type === "System");
				}

				setNotificationListCopy(filteredArray);
			} else {
				switchToggler.current = false;

				// Reset notification list based on the selected tab (SortByStatus)
				if (SortByStatus.current === 0) {
					setNotificationListCopy(notificationList);
				} else if (SortByStatus.current === 1) {
					const userNotifications = notificationList.filter(
						(notification) =>
							notification?.type === "batchUpload" ||
							notification?.type === "Workflow" ||
							notification?.type === "User Account",
					);
					setNotificationListCopy(userNotifications);
				} else if (SortByStatus.current === 2) {
					const systemNotifications = notificationList.filter((notification) => notification?.type === "System");
					setNotificationListCopy(systemNotifications);
				}
			}
		},
		[notificationList],
	);

	const handleShowAll = useCallback(() => {
		setOpen((prev) => !prev); // Toggle the Popover state
		switch (role) {
			case "ADMIN":
			case "GUEST":
				navigate("/admin/notification");
				break;
			case "PROVIDER":
				navigate("/provider/notification");
				break;
			case "USER":
				navigate("/provider/notification");
				break;
			case "SUPER ADMIN":
				navigate("/super-admin/notification");
				break;
			case "VERIFICATION CALLER":
			case "VERIFICATION":
			case "AUDIT":
			case "AUDIT CALLER":
			case "CODING":
				navigate("/users/notification");
				break;

			default:
				navigate("/");
				break;
		}
		// navigate("/Notification")
	}, [navigate, role, setOpen]);

	const getPriorityBadge = (priority) => {
		switch (priority?.toLowerCase()) {
			case "high":
				return (
					<Tag className={tagClassName} color="red">
						High
					</Tag>
				);
			case "medium":
				return (
					<Tag className={tagClassName} color="gold">
						Medium
					</Tag>
				);
			case "low":
				return (
					<Tag className={tagClassName} color="green">
						Low
					</Tag>
				);
			default:
				return null;
		}
	};

	const notificationContent = (
		<div className="relative w-[100%]">
			{isLoading && (
				<div className="absolute inset-0 z-10 flex items-center justify-center bg-white bg-opacity-70 backdrop-blur-sm">
					<Spin size="small" />
				</div>
			)}
			<div className="flex items-center justify-between p-2">
				<div className="flex items-center">
					<img src={NotificationIcon} alt="notificationIcon" className="mr-[0.031rem] h-7 w-7" />
					<span className="font-poppins text-md">Notifications</span>
				</div>
				<div className="flex items-center">
					<span className="text-sm">Show only unread </span>
					<span className="ml-1 text-sm ">({unReadCount?.current})</span>
					<Switch
						className="custom-switch ml-2"
						value={switchToggler?.current}
						size="small"
						onChange={handleSwitchChange}
					/>
				</div>
			</div>
			<hr className="mb-[2%] border-[#5e5e5e] opacity-5" />
			{role !== "PROVIDER" && role !== "USER" ? (
				<div className="flex">
					<span className="mr-[2%] font-poppins text-md">Sort By:</span>
					{tags?.map(({ name, htmlContent, key }) => (
						<div
							key={key}
							onKeyDown={(e) => {
								if (e.key === "Enter" || e.key === " ") {
									handleFilter(key, name);
								}
							}}
							onClick={() => handleFilter(key, name)}
						>
							{htmlContent}
						</div>
					))}
				</div>
			) : (
				<div className="mr-[2%] font-poppins">View notifications generated by the system</div>
			)}

			<div className="mt-[2%] mb-[2%] h-[2%] rounded-sm bg-[#ececec] p-2">
				<span className="font-poppins">Today</span>
			</div>
			<div
				className="h-[71vh] max-h-[48vh] overflow-y-auto "
				// style={{
				//   "scrollbarWidth": "thin",
				//   "scrollbarColor": "#D9D9D9 #ffffff",
				// }}
			>
				{notificationListCopy?.length > 0 ? (
					notificationListCopy?.map((notification, index) => {
						return (
							<ul
								key={`${notification?._id || "undefined"}-${index}`}
								// key={notification?._id}

								className={` list-disc rounded-md pl-6 font-poppins ${notification?.readStatus?.isRead ? "cursor-default" : "cursor-pointer"} w-full ${notification?.readStatus?.isRead ? "text-gray-400" : null}hover:bg-[#fafcff] hover:bg-opacity-100 `}
								onKeyDown={(e) => {
									if (e.key === "Enter" || e.key === " ") {
										handleNotificationClickWithId({
											id: notification?._id,
											readId: notification?.readStatus?._id,
											type: notification?.type,
										});
									}
								}}
								onClick={handleNotificationClickWithId({
									id: notification?._id,
									readId: notification?.readStatus?._id,
									type: notification?.type,
								})} // Use the pre-bound function here
							>
								<li className="mb-[1%] py-2 font-poppins " /*  key={notification?._id} */>
									<div className="flex items-center justify-between">
										{/* Title on the left */}
										<span
											className={`${
												notification?.readStatus?.isRead ? "text-gray-800" : "font-bold text-black text-md"
											}`}
										>
											{notification?.title}
										</span>

										{/* Priority tag on the right */}
										{notification?.type === "alerts" && <span>{getPriorityBadge(notification?.priority)}</span>}
									</div>
									{/* <span
										className={`${notification?.readStatus?.isRead ? "text-gray-800" : "font-bold text-black text-md"} flex items-end `}
									>
										{notification?.title}
										<span >{getPriorityBadge(notification?.priority)}</span>
									</span> */}
									<p>
										<span className="text-sm">
											{notification?.message?.split(/(\s|\d+)/)?.map((part, index) => {
												const uniqueKey = `${part}-${Math.random().toString(36).substr(2, 9)}`; // Generate a unique key
												if (/^\d+\s*charts$/?.test(part?.trim())) {
													return (
														<span key={uniqueKey} className="font-bold">
															{part}
														</span>
													);
												}
												if (part?.trim()?.toUpperCase() === "VERIFICATION.") {
													return (
														<span
															key={uniqueKey}
															className={`${notification?.readStatus?.isRead ? "text-gray-800" : "text-green-600"} font-medium`}
														>
															{" "}
															{part}
														</span>
													);
												}
												if (/\b(deleted)\b/i.test(part?.trim())) {
													return (
														<span
															key={uniqueKey}
															className={`${notification?.readStatus?.isRead ? "text-gray-800" : "font-medium text-red-600"}`}
														>
															{" "}
															{part}
														</span>
													);
												}
												if (/\d/?.test(part)) {
													return (
														<span
															key={uniqueKey}
															className={`${notification?.readStatus?.isRead ? "text-gray-800" : "text-[#3a89f7]"} `}
														>
															{" "}
															{part}
														</span>
													);
												}
												return (
													<span key={uniqueKey} className="text-gray-800">
														{part}
													</span>
												);
											})}
										</span>
										<br />
										<span className="text-end text-slate-600 text-xs">
											{/* {dayjs(notification?.createdAt).fromNow()} */}
											{dayjs(notification?.createdAt).locale("en").fromNow()}
										</span>
									</p>
								</li>
							</ul>
						);
					})
				) : (
					<p className="text-center text-gray-500">No notifications</p>
				)}
			</div>
			<div className="mt-[2%] h-[2%] rounded-sm p-2 text-center ">
				<Tooltip title="Click to view all notifications" placement="top">
					<span
						className="cursor-pointer border font-poppins text-blue-400 underline hover:border-l-royalblue-100"
						onClick={handleShowAll}
					>
						Show All
					</span>
				</Tooltip>
			</div>
		</div>
	);

	// const handleChangePopOver = useCallback(() => {

	//   switchToggler.current = false
	//   setOpen(prev => !prev)
	// }, [setOpen])

	const handleChangePopOver = useCallback(async () => {
		switchToggler.current = false; // Reset the toggle state
		setOpen((prev) => !prev); // Toggle the Popover state

		if (!open) {
			// Call the API only when the Popover is opened
			try {
				// dispatch(setLoading(true)); // Start loading indicator
				setIsLoading(true);
				if (userProject && role && userId) {
					const { getTodayNotificationsApi } = await import("../../redux/notification/notificationService");

					await dispatch(getTodayNotificationsApi({ userProject, role, userId, roleId })).unwrap();
				}

				setIsLoading(false);

				// dispatch(setLoading(false)); // Stop loading indicator
			} catch (error) {
				setIsLoading(false);
			} finally {
				setIsLoading(false);
				// dispatch(setLoading(false))
			}
		}
	}, [setOpen, open, dispatch, userProject, role, userId, roleId]);

	return (
		<Tooltip title="Notification" placement="top">
			<div className="flex items-center">
				<Popover
					content={notificationContent}
					trigger="click"
					placement="top"
					open={open}
					onOpenChange={handleChangePopOver}
					overlayStyle={{ width: "40vw", right: "0px" }}
					getPopupContainer={(trigger) => trigger.parentNode}
				>
					<img
						src={hasUnreadNotifications ? NotificationWithRed : NotificationIcon}
						alt="notificationIcon"
						className="mr-0 h-8 w-8 cursor-pointer" // Increase width and height to 10
						onClick={() => setOpen(!open)}
					/>
				</Popover>
			</div>
		</Tooltip>
	);
};

export default memo(Notification);
