import api from "../../api";

const baseUrl = "/api/exportFile";

export const getExportFile = (extractionData, chartData, fileName, password, format) => {
	return api.post(
		`${baseUrl}/export-file`,
		{
			extractionData,
			chartData,
			fileName,
			password,
			format,
		},
		{
			responseType: "blob",
		},
	);
};
