import { Modal } from "antd";
import React, { Suspense, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";

const PiChart = React.lazy(() => import("./PiChart"));

// const PiChart = React.lazy(() =>
//  new Promise((resolve) => {
//   setTimeout(() => {
//    resolve(import('./BarChart')); // Dynamically import the component after 20 minutes
//   }, 1 * 60 * 1000); // 20 minutes in milliseconds (1,200,000 ms)
//  })
// );

const modalBodyStyle = { padding: 0, height: "100vh" };

const PiChartComponent = ({ transformedData, modal1Open, setModal1Open, completedPercentage, fullScreen }) => {
	const role = useSelector((state) => state.userDetails.role);

	const modalContent = useMemo(
		() => (
			<Suspense
				fallback={
					<>
						<div className="flex h-full w-full flex-col items-center justify-center">
							<div className="text-center font-poppins text-black text-opacity-100">
								<span className="animate-fadeIn font-poppins text-black text-md">
									Hang on! chart's getting dressed<p className="inline animate-pulse font-semibold text-4xl"> . . .</p>
								</span>
							</div>
						</div>
					</>
				}
			>
				<div className="flex h-full w-full flex-col items-center justify-center">
					<PiChart data={transformedData} fullView={true} />
					{/* Below the chart: Total number of completed charts */}
					<div className="mt-4 translate-y-[1%] text-center font-bold text-[#151D48]">
						<div className="text-lg">Total No of Charts Completed:</div>
						<div className="font-extrabold text-2xl">
							{completedPercentage > 0 ? completedPercentage?.toFixed(2) : 0}%
						</div>
					</div>
				</div>
			</Suspense>
		),
		[completedPercentage, transformedData],
	);

	const handleModalToggle = useCallback(() => {
		setModal1Open(false);
	}, [setModal1Open]);

	return (
		<>
			{transformedData?.length > 0 ? (
				<>
					{modal1Open ? (
						<Modal
							title="Full Screen Pi Chart"
							style={{ top: 20, left: 20 }}
							styles={{
								body: modalBodyStyle, // Use this instead of bodyStyle
							}}
							open={modal1Open}
							onCancel={handleModalToggle}
							width="100vw" // Full width
							footer={null} // Optional: Hide default footer
							className="fixed h-[90vh] w-full overflow-hidden" // Full screen modal
						>
							{modalContent}
						</Modal>
					) : null}

					<Suspense
						fallback={
							<>
								<div className="flex h-full w-full flex-col items-center justify-center">
									<div className="text-center font-poppins text-black text-opacity-100">
										<span className="animate-fadeIn font-poppins text-black text-md">
											Hang on! charts's getting dressed
											<p className="inline animate-pulse font-semibold text-4xl"> . . .</p>
										</span>
									</div>
								</div>
							</>
						}
					>
						<div className="flex items-center justify-items-center">
							<div className="w-[60%]">
								<PiChart data={transformedData} />
							</div>
							<div className="translate-y-[30%] font-bold text-[#151D48]">
								{/* <div className='mq825:w-[100%] flex items-center w-[100%]  mq825:flex mq825:items-center mq825:relative mq825:right-5  1024:w-[100%] 1024:ms-2 1366:w-[70%] mq675:ms-3 mq675:w-[60%] mq1050:relative mq1050:right-6'> */}
								<div
									className={`mq1050:relative mq825:relative mq1050:right-6 mq825:right-5 1024:ms-2 mq675:ms-3 flex mq825:flex 1024:w-[100%] 1366:w-[70%] mq675:w-[60%] mq825:w-[100%] w-[100%] items-center mq825:items-center ${role === "PROVIDER" ? "provider-specific" : ""} ${role === "PROVIDER" ? "mini-text-move" : ""} ${role === "PROVIDER" ? "micro-text-move" : ""}`}
								>
									Total No of Charts Completed:
									{/* <div className='text-center font-extrabold text-lg ps-2 mq825:relative mq825:right-28 mq825:top-2 mq675:relative mq675:left-2 mq1050:relative mq1050:right-16'>*/}
									<div
										className={`mq1050:relative mq675:relative mq825:relative mq825:top-2 mq1050:right-16 mq825:right-28 mq675:left-2 ps-2 text-center font-extrabold text-lg ${role === "PROVIDER" ? "provider-specific-container" : ""} ${role === "PROVIDER" ? "move-value" : ""} ${role === "PROVIDER" ? "mini-percentage-move" : ""} ${role === "PROVIDER" ? "micro-percentage-move" : ""}`}
									>
										{completedPercentage > 0 ? completedPercentage?.toFixed(2) : 0}%
									</div>
								</div>
							</div>
						</div>
					</Suspense>
					<img
						src={fullScreen}
						alt="fullScreen"
						className="absolute top-2 right-2 z-10 cursor-pointer"
						onClick={() => setModal1Open(true)}
					/>
				</>
			) : (
				<div className="flex h-full w-full items-center justify-center">
					<div className="animate-fadeIn text-center font-poppins text-gray-500">
						<p className="font-semibold text-xl">No Data Available</p>
						<p className="text-sm">Please select a different filter or try again later.</p>
					</div>
				</div>
			)}
		</>
	);
};

export default React.memo(PiChartComponent);
