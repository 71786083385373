import { createSlice } from "@reduxjs/toolkit";
import { createDocumentApi, deleteDocumentApi, getDocumentsApi, updateDocumentApi } from "./documentService";

const initialState = {
	documentList: [],
	loading: "idle",
	currentRequestId: undefined,
	error: null,
};

export const documentSlice = createSlice({
	name: "document",
	initialState,
	reducers: {
		// add your non-async reducers here
		logout: (state) => {
			state.documentList = [];
			state.loading = "idle";
			state.currentRequestId = undefined;
			state.error = null;
		},
	},
	extraReducers: (builder) => {
		// extraReducers handles asynchronous requests, which is our main focus.
		// GET
		builder
			.addCase(getDocumentsApi.pending, (state, action) => {
				if (state.loading === "idle") {
					state.loading = "pending";
					state.currentRequestId = action.meta.requestId;
				}
			})
			.addCase(getDocumentsApi.fulfilled, (state, action) => {
				const { requestId } = action.meta;
				if (state.loading === "pending" && state.currentRequestId === requestId) {
					state.loading = "idle";
					state.documentList.length = 0;
					state.documentList.push(...action.payload);
					state.currentRequestId = undefined;
				}
			})
			.addCase(getDocumentsApi.rejected, (state, action) => {
				const { requestId } = action.meta;
				if (state.loading === "pending" && state.currentRequestId === requestId) {
					state.loading = "idle";
					// state.error = action.error;
					state.currentRequestId = undefined;
					if (action.payload) {
						state.error = action.payload.errorMessage;
					} else {
						state.error = action.error.message;
					}
				}
			});

		// POST
		builder
			.addCase(createDocumentApi.pending, (state, action) => {
				if (state.loading === "idle") {
					state.loading = "pending";
					state.currentRequestId = action.meta.requestId;
				}
			})
			.addCase(createDocumentApi.fulfilled, (state, action) => {
				const { requestId } = action.meta;
				if (state.loading === "pending" && state.currentRequestId === requestId) {
					state.loading = "idle";
					state.documentList.unshift(action.payload);
					state.currentRequestId = undefined;
				}
			})
			.addCase(createDocumentApi.rejected, (state, action) => {
				const { requestId } = action.meta;
				if (state.loading === "pending" && state.currentRequestId === requestId) {
					state.loading = "idle";
					// state.error = action.error;
					state.currentRequestId = undefined;
					if (action.payload) {
						state.error = action.payload.errorMessage;
					} else {
						state.error = action.error.message;
					}
				}
			});

		// PUT
		builder
			.addCase(updateDocumentApi.pending, (state, action) => {
				if (state.loading === "idle") {
					state.loading = "pending";
					state.currentRequestId = action.meta.requestId;
				}
			})
			.addCase(updateDocumentApi.fulfilled, (state, action) => {
				const { requestId } = action.meta;
				if (state.loading === "pending" && state.currentRequestId === requestId) {
					const updateItem = action.payload;
					const index = state.documentList.findIndex((item) => item.id === updateItem.id);
					if (index > -1) {
						state.documentList[index] = updateItem;
					}
					state.loading = "idle";
					state.currentRequestId = undefined;
				}
			})
			.addCase(updateDocumentApi.rejected, (state, action) => {
				const { requestId } = action.meta;
				if (state.loading === "pending" && state.currentRequestId === requestId) {
					state.loading = "idle";
					// state.error = action.error;
					state.currentRequestId = undefined;
					if (action.payload) {
						state.error = action.payload.errorMessage;
					} else {
						state.error = action.error.message;
					}
				}
			});

		// DELETE
		builder
			.addCase(deleteDocumentApi.pending, (state, action) => {
				if (state.loading === "idle") {
					state.loading = "pending";
					state.currentRequestId = action.meta.requestId;
				}
			})
			.addCase(deleteDocumentApi.fulfilled, (state, action) => {
				const { requestId } = action.meta;
				if (state.loading === "pending" && state.currentRequestId === requestId) {
					const index = state.documentList.findIndex((item) => item.id === action.payload);
					if (index > -1) {
						state.documentList.splice(index, 1);
					}
					state.loading = "idle";
					state.currentRequestId = undefined;
				}
			})
			.addCase(deleteDocumentApi.rejected, (state, action) => {
				const { requestId } = action.meta;
				if (state.loading === "pending" && state.currentRequestId === requestId) {
					state.loading = "idle";
					// state.error = action.error;
					state.currentRequestId = undefined;
					if (action.payload) {
						state.error = action.payload.errorMessage;
					} else {
						state.error = action.error.message;
					}
				}
			});
	},
});

// Action creators are generated for each case reducer function
export const { logout } = documentSlice.actions;

export default documentSlice.reducer;
