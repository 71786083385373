const SuspenseLoading = () => {
	return (
		<div className="flex h-full w-full items-center justify-center">
			<div className="text-center font-poppins text-black text-opacity-100">
				<span className="inline animate-fadeIn font-poppins text-black text-md">
					Loading<p className="inline animate-pulse font-semibold text-4xl"> . . .</p>
				</span>
			</div>
		</div>
	);
};

export default SuspenseLoading;
