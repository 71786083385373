import _ from "lodash";
import { memo } from "react";
import ModalBox from "../../../components/ModalBox";
import { setNewDocument } from "../../../redux/chartDocument/chartDocumentSlice";
import { useDispatch } from "react-redux";

const RemoveDocumentFile = ({
	removeDocumentFileStatus,
	setRemoveDocumentFileStatus,
	selectedData,
	setSelectedData,
	newDocument,
	// setNewDocument,
}) => {
	const dispatch = useDispatch();
	const getContent = () => {
		let content = "";
		if (selectedData) {
			content = (
				<p>
					Are you sure to remove this <strong>{selectedData.name}</strong> ?
				</p>
			);
		} else {
			content = <p>Are you sure to remove this document ?</p>;
		}
		return <div className="w-full text-center">{content}</div>;
	};

	//Handle Remove doc from drop container list
	const onSubmitRemoveDocumentFile = () => {
		const rowIndex = _.findIndex(newDocument, { key: selectedData.key });
		if (rowIndex > -1) {
			const items = [...newDocument];
			items.splice(rowIndex, 1);
			dispatch(setNewDocument(items));
			setRemoveDocumentFileStatus(false);
			setSelectedData(null);
		}
	};

	return (
		<>
			{removeDocumentFileStatus && (
				<ModalBox
					open={removeDocumentFileStatus}
					title="Confirmation"
					width="sm"
					content={getContent()}
					onSubmitTitle="Yes"
					onSubmit={onSubmitRemoveDocumentFile}
					onCancelTitle="No"
					onCancel={() => {
						setRemoveDocumentFileStatus(false);
						setSelectedData(null);
					}}
				/>
			)}
		</>
	);
};

export default memo(RemoveDocumentFile);
