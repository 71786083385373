import { Spin, Tabs } from "antd";
/* eslint-disable no-unused-vars */
import { Suspense, lazy, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SuspenseLoading from "../../components/SuspenseLoading";
import VersionHistory from "../../components/VersionHistory";
import {
	setActiveTab,
	setCallerSpaceToggle,
	setCallerSpaceVisible,
	setCallingDetailsSaved,
	setCoverageDetailsSaved,
	setInsuranceDetailsSaved,
	setPatientDetailsSaved,
	setProductDetailsSaved,
} from "../../redux/ChartViewManagement/ChartViewManagementSlice";
import CallingDetails from "./CallingDetails";
import CoverageDetails from "./CoverageDetails";
import InsuranceDetails from "./InsuranceDetails";
import PatientDetails from "./PatientDetails";
import ProductDetails from "./ProductDetails";
import RemoveDocumentFile from "../Admin/Document/RemoveDocumentFile";
import { setNewDocument } from "../../redux/chartDocument/chartDocumentSlice";

// import DocumentDocViewUpload from "../Admin/Document/DocumentDocViewUpload"

// import DocumentViewer from '../Admin/Document/DocumentViewer';

const DocumentDocViewUpload = lazy(() => import("../Admin/Document/DocumentDocViewUpload"));
const DocumentViewer = lazy(() => import("../Admin/Document/DocumentViewer"));

const { TabPane } = Tabs;

const UpdateTabPanel = ({
	editPatientData,

	setEditPatientData,
	form,
	// setCallerSpaceVisible
}) => {
	const dispatch = useDispatch();
	const role = useSelector((state) => state.userDetails.role);

	const { newDocument } = useSelector((state) => state.chartDocument);
	const { chartDocumentList } = useSelector((state) => state.chartDocument);

	const [viewStatus, setViewStatus] = useState(false);
	const [viewData, setViewData] = useState({});

	const [activeSubTab, setActiveSubTab] = useState("1");
	const [loadings, setLoadings] = useState(false);
	const [selectedData, setSelectedData] = useState(null);
	const [removeDocumentFileStatus, setRemoveDocumentFileStatus] = useState(false);

	const callerSpaceToggle = useSelector((state) => state.chartViewManagement.callerSpaceToggle);
	const callerSpaceVisible = editPatientData?.hasOwnProperty("callerSpaceVisible")
		? editPatientData?.callerSpaceVisible
		: false;
	// const callerSpaceVisible = useSelector((state) => state.chartViewManagement.callerSpaceVisible);

	const activeTab = useSelector((state) => state.chartViewManagement.activeTab);

	const [batchChanged, setBatchChanged] = useState(false);
	const isFromUpdate = true;

	useEffect(() => {
		if (!callerSpaceVisible) {
			if (
				editPatientData?.vfCategory?.toLowerCase() === "VF In-Process"?.toLowerCase() ||
				editPatientData?.vfCategory?.toLowerCase() === "Missing Demo/Insurance Info"?.toLowerCase() ||
				editPatientData?.vfCategory?.toLowerCase() === "In-Active"?.toLowerCase() ||
				editPatientData?.vfCategory?.toLowerCase() === "Subsequent VF In-Process"?.toLowerCase() ||
				editPatientData?.hasOwnProperty("callingBy")
			) {
				dispatch(setCallerSpaceToggle(true));
				dispatch(setCallerSpaceVisible(true));
			}
			if (
				((role === "AUDIT" || role === "AUDIT CALLER" || role === "VERIFICATION CALLER") &&
					editPatientData?.hasOwnProperty("callingBy")) ||
				editPatientData?.vfCategory?.toLowerCase() === "VF In-Process"?.toLowerCase() ||
				editPatientData?.vfCategory?.toLowerCase() === "Missing Demo/Insurance Info"?.toLowerCase() ||
				editPatientData?.vfCategory?.toLowerCase() === "In-Active"?.toLowerCase() ||
				editPatientData?.vfCategory?.toLowerCase() === "Subsequent VF In-Process"?.toLowerCase()
			) {
				dispatch(setCallerSpaceToggle(true));
				dispatch(setCallerSpaceVisible(true));
			} else {
				dispatch(setCallerSpaceToggle(false));
			}
		}
	}, [callerSpaceVisible, dispatch, editPatientData, role]);

	return (
		<div>
			<Tabs activeKey={activeTab && activeTab} size="small" onChange={(key) => dispatch(setActiveTab(key))}>
				<TabPane tab="Patient Details" key="1">
					<PatientDetails
						chartDetail={editPatientData}
						setActiveSubTab={setActiveSubTab}
						setLoadings={setLoadings}
						setEditPatientData={setEditPatientData}
						setPatientDetailsSaved={setPatientDetailsSaved}
						setBatchChanged={setBatchChanged}
					/>
				</TabPane>
				<TabPane tab="Insurance Information" key="2">
					<InsuranceDetails
						chartDetail={editPatientData}
						setActiveSubTab={setActiveSubTab}
						setLoadings={setLoadings}
						setEditPatientData={setEditPatientData}
						setInsuranceDetailsSaved={setInsuranceDetailsSaved}
					/>
				</TabPane>
				<TabPane tab="Order Summary" key="3">
					<ProductDetails
						chartDetail={editPatientData}
						setActiveSubTab={setActiveSubTab}
						setLoadings={setLoadings}
						setEditPatientData={setEditPatientData}
						setProductDetailsSaved={setProductDetailsSaved}
					/>
				</TabPane>
				<TabPane tab="Coverage Summary" key="4">
					<CoverageDetails
						chartDetail={editPatientData}
						setLoadings={setLoadings}
						setEditPatientData={setEditPatientData}
						setActiveSubTab={setActiveSubTab}
						setCallerSpaceToggle={setCallerSpaceToggle}
						setCoverageDetailsSaved={setCoverageDetailsSaved}
						isFromUpdate={isFromUpdate}
						callerSpaceToggle={callerSpaceToggle}
						callerSpaceVisible={callerSpaceVisible}
						setCallerSpaceVisible={setCallerSpaceVisible}
					/>
				</TabPane>
				{callerSpaceVisible && (
					<TabPane tab="Caller Space" key="5">
						<CallingDetails
							chartDetail={editPatientData}
							setLoadings={setLoadings}
							setEditPatientData={setEditPatientData}
							setCallingDetailsSaved={setCallingDetailsSaved}
							callerSpaceToggle={callerSpaceToggle}
							isFromUpdate={isFromUpdate}
						/>
					</TabPane>
				)}

				{role !== "PROVIDER" && role !== "USER" && (
					<TabPane tab="Related Documents" key="8">
						<Suspense fallback={<SuspenseLoading />}>
							<DocumentDocViewUpload
								// setChartDocViewUpload={setChartDocViewUpload}
								// chartDocViewUpload={chartDocViewUpload}
								// newDocument={newDocument}
								// setNewDocument={setNewDocument}
								setRemoveDocumentFileStatus={setRemoveDocumentFileStatus}
								setSelectedData={setSelectedData}
								// documentData={documentData}
								chartDocumentList={chartDocumentList}
								chartId={editPatientData?.id || editPatientData?._id}
								setViewStatus={setViewStatus}
								setViewData={setViewData}
								// setDeleteStatus={setDeleteStatus}
								// setDeleteData={setDeleteData}
								// chartList={charts}
								isFromTabPanel={true}
								chartDetail={editPatientData}
								form={form}
							/>
						</Suspense>
					</TabPane>
				)}

				{editPatientData?.versions?.length > 0 && (
					<TabPane tab="Version History" key="6">
						<VersionHistory currentChart={editPatientData.chartDetail} versions={editPatientData?.versions || []} />
					</TabPane>
				)}
			</Tabs>

			{role !== "PROVIDER" && role !== "USER" && (
				<Suspense fallback={<SuspenseLoading />}>
					{viewStatus && (
						<DocumentViewer
							viewData={viewData}
							viewStatus={viewStatus}
							setViewStatus={setViewStatus}
							setViewData={setViewData}
						/>
					)}
				</Suspense>
			)}
			{removeDocumentFileStatus && (
				<RemoveDocumentFile
					removeDocumentFileStatus={removeDocumentFileStatus}
					setRemoveDocumentFileStatus={setRemoveDocumentFileStatus}
					selectedData={selectedData}
					setSelectedData={setSelectedData}
					newDocument={newDocument}
					setNewDocument={setNewDocument}
				/>
			)}
			<Spin spinning={loadings} fullscreen />
		</div>
	);
};

export default UpdateTabPanel;
