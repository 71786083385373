import api from "../../api";

const baseUrl = "/api/repositorySend";

export const repositorySend = (postData) => {
	return api.post(baseUrl, postData, { withCredentials: true });
};

export const getRepositoryScheduleList = () => {
	return api.get(baseUrl, {
		withCredentials: true, // Ensures credentials (cookies, etc.) are sent with the request
	});
};

export const getScheduleListPagination = ({ page = 1, pageSize = 10 }) => {
	return api.get(`${baseUrl}/pagination`, {
		params: {
			page,
			pageSize,
		},
		withCredentials: true, // Include cookies for cross-origin requests
	});
};

export const updateRepositorySchedule = (updateId, putData) => {
	return api.put(`${baseUrl}/${updateId}`, putData, { withCredentials: true });
};

export const deleteRepositorySchedule = (deleteId) => {
	return api.delete(`${baseUrl}/${deleteId}`, { withCredentials: true });
};
