import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSearchHistory, saveSearchToHistory } from "../../api/chartSearchHistory/chartSearchHistory";

export const getSearchHistoryApi = createAsyncThunk("searchHistory/get", async ({ userId }, { rejectWithValue }) => {
	try {
		const response = await getSearchHistory(userId);
		return response.data.history; // Assuming the API response has the data in `response.data`
	} catch (error) {
		if (!error.response) {
			throw error;
		}
		return rejectWithValue(error.response.data.history);
	}
});

export const saveSearchToHistoryApi = createAsyncThunk(
	"searchHistory/save",
	async ({ userId, searchQuery }, { rejectWithValue }) => {
		try {
			const response = await saveSearchToHistory(userId, searchQuery);
			return response.data; // Assuming the API response has the data in `response.data`
		} catch (error) {
			if (!error.response) {
				throw error;
			}
			return rejectWithValue(error.response.data);
		}
	},
);
