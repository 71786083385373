import { createSlice } from "@reduxjs/toolkit";
import { createChartApi, deleteChartApi, getChartsApi, updateChartApi } from "./chartService";

const initialState = {
	chartList: [],
	loading: "idle",
	currentRequestId: undefined,
	error: null,
};
export const chartSlice = createSlice({
	name: "chart",
	initialState,
	reducers: {
		// add your non-async reducers here
		logout: (state) => {
			state.chartList = [];
			state.loading = "idle";
			state.currentRequestId = undefined;
			state.error = null;
		},
	},
	extraReducers: (builder) => {
		// extraReducers handles asynchronous requests, which is our main focus.
		// GET
		builder
			.addCase(getChartsApi.pending, (state, action) => {
				if (state.loading === "idle") {
					state.loading = "pending";
					state.currentRequestId = action.meta.requestId;
				}
			})
			.addCase(getChartsApi.fulfilled, (state, action) => {
				const { requestId } = action.meta;
				if (state.loading === "pending" && state.currentRequestId === requestId) {
					state.loading = "idle";
					state.chartList.length = 0;
					state.chartList.push(...action.payload);
					state.currentRequestId = undefined;
				}
			})
			.addCase(getChartsApi.rejected, (state, action) => {
				const { requestId } = action.meta;
				if (state.loading === "pending" && state.currentRequestId === requestId) {
					state.loading = "idle";
					// state.error = action.error;
					state.currentRequestId = undefined;
					if (action.payload) {
						state.error = action.payload.errorMessage;
					} else {
						state.error = action.error.message;
					}
				}
			});

		// POST
		builder
			.addCase(createChartApi.pending, (state, action) => {
				if (state.loading === "idle") {
					state.loading = "pending";
					state.currentRequestId = action.meta.requestId;
				}
			})
			.addCase(createChartApi.fulfilled, (state, action) => {
				const { requestId } = action.meta;
				if (state.loading === "pending" && state.currentRequestId === requestId) {
					state.loading = "idle";
					state.chartList.unshift(action.payload);
					state.currentRequestId = undefined;
				}
			})
			.addCase(createChartApi.rejected, (state, action) => {
				const { requestId } = action.meta;
				if (state.loading === "pending" && state.currentRequestId === requestId) {
					state.loading = "idle";
					// state.error = action.error;
					state.currentRequestId = undefined;
					if (action.payload) {
						state.error = action.payload.errorMessage;
					} else {
						state.error = action.error.message;
					}
				}
			});

		// PUT
		builder
			.addCase(updateChartApi.pending, (state, action) => {
				if (state.loading === "idle") {
					state.loading = "pending";
					state.currentRequestId = action.meta.requestId;
				}
			})
			.addCase(updateChartApi.fulfilled, (state, action) => {
				const { requestId } = action.meta;
				if (state.loading === "pending" && state.currentRequestId === requestId) {
					const updateItem = action.payload;
					const index = state.chartList.findIndex((item) => item.id === updateItem.id);
					if (index > -1) {
						state.chartList[index] = updateItem;
					}
					state.loading = "idle";
					state.currentRequestId = undefined;
				}
			})
			.addCase(updateChartApi.rejected, (state, action) => {
				const { requestId } = action.meta;
				if (state.loading === "pending" && state.currentRequestId === requestId) {
					state.loading = "idle";
					// state.error = action.error;
					state.currentRequestId = undefined;
					if (action.payload) {
						state.error = action.payload.errorMessage;
					} else {
						state.error = action.error.message;
					}
				}
			});

		// DELETE
		builder
			.addCase(deleteChartApi.pending, (state, action) => {
				if (state.loading === "idle") {
					state.loading = "pending";
					state.currentRequestId = action.meta.requestId;
				}
			})
			.addCase(deleteChartApi.fulfilled, (state, action) => {
				const { requestId } = action.meta;
				if (state.loading === "pending" && state.currentRequestId === requestId) {
					const index = state.chartList.findIndex((item) => item.id === action.payload);
					if (index > -1) {
						state.chartList.splice(index, 1);
					}
					state.loading = "idle";
					state.currentRequestId = undefined;
				}
			})
			.addCase(deleteChartApi.rejected, (state, action) => {
				const { requestId } = action.meta;
				if (state.loading === "pending" && state.currentRequestId === requestId) {
					state.loading = "idle";
					// state.error = action.error;
					state.currentRequestId = undefined;
					if (action.payload) {
						state.error = action.payload.errorMessage;
					} else {
						state.error = action.error.message;
					}
				}
			});
	},
});

// Action creators are generated for each case reducer function
export const { logout } = chartSlice.actions;

export default chartSlice.reducer;
