import { Button, Col, DatePicker, Form, Input, Row, Select, TimePicker } from "antd";
import dayjs from "dayjs";
// import TextArea from 'antd/es/input/TextArea';
import _ from "lodash";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { CallingDispositionCode, crDispositionCode } from "../../assets/DispCodes";
import { updateChartApi } from "../../redux/chart/chartService";
import { setLoading } from "../../redux/loading/loadingSlice";
import { axiosSubmitErrorHandling, getIpAddress, isEmptyObject, isObject, trimObjValues } from "../../utils/utility";

const { TextArea } = Input;
const timeFormat = "HH:mm";

const CallingDetails = ({
	chartDetail,
	setSubmitChartStatus,
	setSubmitChartData,
	setCallingDetailsSaved,
	defaultSize,
	isFromUpdate,
	formRef,
	charts,
	setCharts,
	setChartDetailView,
	setEditPatientData,
}) => {
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const role = useSelector((state) => state.userDetails.role);
	const userId = useSelector((state) => state.userDetails.userId);
	const EditAccess = role === "PROVIDER" || role === "USER" || role === "CODING" || role === "GUEST" ? false : true;

	const isPatientDetailsSaved = useSelector((state) => state.chartViewManagement.isPatientDetailsSaved);

	const isInsuranceDetailsSaved = useSelector((state) => state.chartViewManagement.isInsuranceDetailsSaved);

	const isProductDetailsSaved = useSelector((state) => state.chartViewManagement.isProductDetailsSaved);

	const isCoverageDetailsSaved = useSelector((state) => state.chartViewManagement.isCoverageDetailsSaved);

	const isCallingDetailsSaved = useSelector((state) => state.chartViewManagement.isCallingDetailsSaved);

	const dateFormat = "MM/DD/YYYY";

	const oldChartDetailView = useSelector((state) => state.chartViewManagement.chartDetailView);

	useEffect(() => {
		const callingInitialValues = {
			insurancePhoneNumber: chartDetail?.insurancePhoneNumber,
			pcb: chartDetail?.pcb,
			callingComments: chartDetail?.callingComments,
			callRefNumber: chartDetail?.callRefNumber,
			callingPayerId: chartDetail?.callingPayerId,
			callMode: chartDetail?.callMode,
			operator: chartDetail?.operator,
			startTime: chartDetail?.startTime ? dayjs(chartDetail?.startTime, timeFormat) : undefined,
			endTime: chartDetail?.endTime ? dayjs(chartDetail?.endTime, timeFormat) : undefined,
			ach: chartDetail?.ach,
			callDispositionCode: chartDetail?.callDispositionCode,
			crDispositionCode: chartDetail?.crDispositionCode,
			callingCompletedOn: chartDetail?.callingCompletedOn ? dayjs(chartDetail?.callingCompletedOn) : "",
			calledBy: chartDetail?.callingBy?.name || "N/A",
		};

		form.setFieldValue(callingInitialValues);
	}, [chartDetail, form]);

	const callingInitialValues = {
		/* Calling Detail Initial Values */
		insurancePhoneNumber: chartDetail?.insurancePhoneNumber,
		pcb: chartDetail?.pcb,
		callingComments: chartDetail?.callingComments,
		callRefNumber: chartDetail?.callRefNumber,
		callingPayerId: chartDetail?.callingPayerId,
		callMode: chartDetail?.callMode,
		operator: chartDetail?.operator,
		startTime: chartDetail?.startTime ? dayjs(chartDetail?.startTime, timeFormat) : null,
		endTime: chartDetail?.endTime ? dayjs(chartDetail?.endTime, timeFormat) : null,
		ach: chartDetail?.ach,
		callDispositionCode: chartDetail?.callDispositionCode,
		crDispositionCode: chartDetail?.crDispositionCode,
		callingCompletedOn: chartDetail?.callingCompletedOn ? dayjs(chartDetail?.callingCompletedOn) : "",
		calledBy: chartDetail?.callingBy?.name || "N/A",
	};

	const onFinish = async () => {
		try {
			const values = await form.validateFields();
			const items = { ...values };
			items.userRole = role;
			items.userId = userId;
			items.startTime = dayjs(items?.startTime, timeFormat).format(timeFormat);
			items.endTime = dayjs(items?.endTime, timeFormat).format(timeFormat);
			const ipAddress = await getIpAddress();
			items.ipAddress = ipAddress;
			/* check is object */

			if (!isObject(items)) {
				return toast.warning("This is not object data!");
			}

			/* check is empty object */
			if (isEmptyObject(items)) {
				return toast.warning("Empty object cannot accept!");
			}

			/* trim values */
			trimObjValues(items);

			dispatch(setLoading(true));

			const updateId = chartDetail?.id || chartDetail?._id;

			dispatch(setCallingDetailsSaved(true));

			setCallingDetailsSaved(true);
			items.callingCompletedOn = dayjs(items.callingCompletedOn).format("MM/DD/YYYY");
			const res = await dispatch(updateChartApi({ updateId, items })).unwrap();
			const rowIndex = _.findIndex(charts, { id: updateId });

			if (rowIndex > -1) {
				const updatedCharts = [...charts];
				const updatedItem = { ...updatedCharts[rowIndex], ...res };
				updatedCharts[rowIndex] = updatedItem;
				setCharts(updatedCharts);
			}
			if (typeof setEditPatientData === "function") {
				setEditPatientData((prevData) => ({
					...prevData,
					...res, // Update editPatientData with the latest data from the API
				}));
			}
			if (typeof setChartDetailView === "function") {
				// Access the current ChartDetailView from the Redux store

				// Merge the old data with the new data
				const updatedData = {
					...oldChartDetailView.data, // Existing data
					...res, // New response data
				};

				// Dispatch the updated state
				dispatch(
					setChartDetailView({
						selected: true,
						data: updatedData, // Merged data
					}),
				);
			}

			toast.success("Calling details updated successfully!");
			dispatch(setLoading(false));
		} catch (error) {
			dispatch(setLoading(false));
			return axiosSubmitErrorHandling(error);
		}
	};

	const onValuesChange = (changedValues, allValues) => {
		if ("startTime" in changedValues || "endTime" in changedValues) {
			const startTime = dayjs(allValues.startTime, timeFormat);
			const endTime = dayjs(allValues.endTime, timeFormat);
			const duration = endTime.diff(startTime, "minutes");
			const formattedDuration = `${Math.floor(duration / 60)}:${duration % 60}`;
			form.setFieldsValue({
				ach: formattedDuration,
			});
		}
	};

	const handleSubmitChart = async () => {
		if (
			isPatientDetailsSaved &&
			isInsuranceDetailsSaved &&
			isProductDetailsSaved &&
			isCoverageDetailsSaved &&
			isCallingDetailsSaved
		) {
			const updateId = chartDetail?.id || chartDetail?._id;
			setSubmitChartStatus(true);
			setSubmitChartData(updateId);
		} else if (!isPatientDetailsSaved) {
			toast.warning("Please save the Patient Details!");
		} else if (!isInsuranceDetailsSaved) {
			toast.warning("Please save the Insurance Information!");
		} else if (!isProductDetailsSaved) {
			toast.warning("Please save the Order Summary!");
		} else if (!isCoverageDetailsSaved) {
			toast.warning("Please save the Coverage Summary!");
		} else if (!isCallingDetailsSaved) {
			toast.warning("Please save the Calling Details!");
		}
	};

	const onFinishFailed = (errorInfo) => {};

	const onChange = (value) => {};

	const onSearch = (value) => {};

	const filterOption = (input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

	const formatPhoneNumber = (value) => {
		// Remove all non-numeric characters
		const cleaned = value.replace(/\D/g, "");

		// Limit the length to 10 digits
		const limited = cleaned.slice(0, 10);

		// Format the phone number according to (999) 999-9999 pattern
		const match = limited.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);

		if (match) {
			const [, areaCode, centralOfficeCode, lineNumber] = match;
			if (lineNumber) {
				return `(${areaCode}) ${centralOfficeCode}-${lineNumber}`;
			} else if (centralOfficeCode) {
				return `(${areaCode}) ${centralOfficeCode}`;
			} else if (areaCode) {
				return `(${areaCode}`;
			}
		}
		return value;
	};

	const onChangeTextArea = (e) => {};
	const onChangeDatePicker = (date, dateString) => {};

	const disabledDate = (current) => {
		// Get the current date and year
		const currentDate = dayjs();
		const currentYear = currentDate.year();

		// Disable dates after the current year or after the current date
		return current && (dayjs(current).year() > currentYear || dayjs(current).isAfter(currentDate, "day"));
	};

	return (
		<Form
			ref={formRef}
			layout="vertical"
			form={form}
			onFinish={onFinish}
			onFinishFailed={onFinishFailed}
			initialValues={callingInitialValues}
			autoComplete="off"
			onValuesChange={onValuesChange}
			size={defaultSize === "dataCapture" ? "small" : ""}
		>
			<Row gutter={16}>
				<Col xl={8} lg={8} md={8} sm={12} xs={24}>
					<Form.Item
						className="mb-2"
						label="Verify Calling Completed On "
						name="callingCompletedOn"
						style={!EditAccess ? { pointerEvents: "none" } : {}}
					>
						<DatePicker
							className="w-full"
							onChange={onChangeDatePicker}
							format={dateFormat}
							disabledDate={disabledDate}
							style={!EditAccess ? { pointerEvents: "none" } : {}}
						/>
					</Form.Item>
					<Form.Item
						className="mb-2"
						label="called by"
						name="calledBy"
						style={!EditAccess ? { pointerEvents: "none" } : {}}
					>
						<Input defaultValue={chartDetail?.callingBy?.name} readOnly />
					</Form.Item>

					<Form.Item
						className="mb-2"
						label="Insurance Phone Number"
						name="insurancePhoneNumber"
						rules={[
							{
								required: role === "VERIFICATION CALLER" || role === "AUDIT CALLER",
								message: "Please enter the insurance phone number or specify U/A or N/A!",
							},
							{
								pattern: /^(\(\d{3}\) \d{3}-\d{4})|(U\/A)|(N\/A)$/i,
								message:
									"Please enter the insurance phone number in the specified format (e.g., (999) 153-5556), or specify U/A or N/A.",
							},
						]}
					>
						<Input
							className="cursor-pointer"
							maxLength={14} // Setting the maximum length of the input field to 14 characters
							onInput={(e) => {
								e.target.value = formatPhoneNumber(e.target.value);
							}}
							onKeyPress={(e) => {
								// Allow only numbers, control keys, 'U', 'A', 'N', '/' and ' '
								if (
									!/[0-9]/.test(e.key) &&
									e.key !== "Backspace" &&
									e.key !== "Delete" &&
									e.key !== "ArrowLeft" &&
									e.key !== "ArrowRight" &&
									e.key !== "U" &&
									e.key !== "A" &&
									e.key !== "N" &&
									e.key !== "/" &&
									e.key !== " "
								) {
									e.preventDefault();
								}
							}}
							readOnly={!EditAccess}
						/>
					</Form.Item>
					<Form.Item className="mb-2" label="PCB" name="pcb">
						<Select
							showSearch
							allowClear
							placeholder="Select a PCB"
							optionFilterProp="children"
							onChange={onChange}
							onSearch={onSearch}
							filterOption={filterOption}
							options={[
								{
									value: "YES",
									label: "YES",
								},
								{
									value: "NO",
									label: "NO",
								},
								{
									value: "N/A",
									label: "N/A",
								},
							]}
							style={!EditAccess ? { pointerEvents: "none" } : {}}
						/>
					</Form.Item>
					<Form.Item showCount className="mb-2" label="Notes" name="callingComments">
						<TextArea showCount onChange={onChangeTextArea} readOnly={!EditAccess} />
					</Form.Item>
					<Form.Item
						className="mb-2"
						label="Reference Number"
						name="callRefNumber"
						rules={[
							{
								required: true,
								message: "Please enter the reference number!",
							},
							{
								pattern: /^(U\/A|N\/A|[A-Za-z0-9\s-]+)$/,
								message: 'Please enter alphanumeric characters, spaces, hyphens, "U/A" or "N/A" only!',
							},
						]}
					>
						<Input
							placeholder="Enter Reference Number"
							onInput={(e) => {
								e.target.value = e.target.value.replace(/[^A-Za-z0-9\s-\\/]/g, ""); // Replace non-alphanumeric characters except space and hyphen
							}}
							readOnly={!EditAccess}
						/>
					</Form.Item>

					<Form.Item className="mb-2" label="Payer ID" name="callingPayerId">
						<Input readOnly={!EditAccess} />
					</Form.Item>
					<Form.Item className="mb-2" label="Calling Mode" name="callMode">
						<Input readOnly={!EditAccess} />
					</Form.Item>
				</Col>
				<Col xl={8} lg={8} md={8} sm={12} xs={24}>
					<Form.Item className="mb-2" label="Operator" name="operator">
						<Input readOnly={!EditAccess} />
					</Form.Item>
					<Form.Item
						className="mb-2"
						label="Start Time"
						name="startTime"
						style={!EditAccess ? { pointerEvents: "none" } : {}}
					>
						<TimePicker
							format={timeFormat}
							readOnly={!EditAccess}
							style={!EditAccess ? { pointerEvents: "none", width: "100%" } : { width: "100%" }}
						/>
					</Form.Item>
					<Form.Item
						className="mb-2"
						label="End Time"
						name="endTime"
						style={!EditAccess ? { pointerEvents: "none" } : {}}
					>
						<TimePicker
							format={timeFormat}
							style={!EditAccess ? { pointerEvents: "none", width: "100%" } : { width: "100%" }}
						/>
					</Form.Item>
					<Form.Item className="mb-2" label="ACH" name="ach">
						<Input readOnly />
					</Form.Item>
					<Form.Item className="mb-2" label="Call Disposition Code" name="callDispositionCode">
						<Select
							showSearch
							allowClear
							placeholder="Select a Call disposition code"
							optionFilterProp="children"
							onChange={onChange}
							onSearch={onSearch}
							filterOption={filterOption}
							options={CallingDispositionCode}
							style={!EditAccess ? { pointerEvents: "none" } : {}}
						/>
					</Form.Item>
					<Form.Item className="mb-2" label="CR Disposition Code" name="crDispositionCode">
						<Select
							showSearch
							allowClear
							placeholder="Select a CR disposition code"
							optionFilterProp="children"
							onChange={onChange}
							onSearch={onSearch}
							filterOption={filterOption}
							options={crDispositionCode}
							style={!EditAccess ? { pointerEvents: "none" } : {}}
						/>
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={16}>
				<Col xl={12} lg={12} md={12} sm={12} xs={12}>
					<Form.Item className="mb-2">{/* <Button className="w-fit mt-2 font-medium">Cancel</Button> */}</Form.Item>
				</Col>
				<Col xl={12} lg={12} md={12} sm={12} xs={12}>
					<Form.Item className="mb-2">
						{(role === "VERIFICATION CALLER" || role === "AUDIT CALLER") && !isFromUpdate && (
							<Button
								className="float-right mt-2 mr-2 w-fit bg-blue-400 font-medium"
								type="primary"
								onClick={handleSubmitChart}
							>
								{role === "AUDIT" ? "Sign Off" : "Submit Chart"}
							</Button>
						)}
						<Button
							className="float-right mt-2 mr-2 w-fit bg-blue-400 font-medium"
							type="primary"
							htmlType="submit"
							disabled={!EditAccess}
						>
							Save
						</Button>
					</Form.Item>
				</Col>
			</Row>
		</Form>
	);
};

export default CallingDetails;
