import { createSlice } from "@reduxjs/toolkit";
import { fetchPaginatedDocumentsApi } from "./subsequentDocumentService";

const paginatedDocumentsSlice = createSlice({
	name: "subsequentDocument",
	initialState: {
		documents: [],
		totalDocuments: 0,
		pagedBatchNos: [],
		currentPage: 1,
		pageSize: 10,
		searchTerm: "",
		selectedDate: null, // Add selectedDate to the slice
		error: null,
		loading: false,
	},
	reducers: {
		setPage: (state, action) => {
			state.currentPage = action.payload;
		},
		setPageSize: (state, action) => {
			state.pageSize = action.payload;
		},
		setSearchTerm: (state, action) => {
			state.searchTerm = action.payload;
			state.currentPage = 1; // Reset to first page when search term changes
		},
		setSelectedDate: (state, action) => {
			state.selectedDate = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchPaginatedDocumentsApi.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(fetchPaginatedDocumentsApi.fulfilled, (state, action) => {
				state.loading = false;
				state.documents = action?.payload?.documents;
				state.totalDocuments = action?.payload?.totalDocuments;
				state.pagedBatchNos = action?.payload?.pagedBatchNos;
			})
			.addCase(fetchPaginatedDocumentsApi.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			});
	},
});

export const { setPage, setPageSize, setSearchTerm, setSelectedDate } = paginatedDocumentsSlice.actions;

export default paginatedDocumentsSlice.reducer;
