import { CloseOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createOrUpdateRecentHistoryApi } from "../redux/recentHistory/recentHistoryService";

const screenResolutions = {
	xxl: "1600px",
	xl: "1200px",
	lg: "992px",
	md: "768px",
	sm: "576px",
	xs: "400px",
};

const ModalBox = ({
	open,
	title,
	width,
	content,
	onSubmitTitle,
	onSubmit,
	onCancelTitle,
	onCancel,
	onSubmitDisabled,
	isDcSubmitChart,
}) => {
	const userId = useSelector((state) => state.userDetails.userId);
	const projectId = useSelector((state) => state.userDetails.project);
	const dispatch = useDispatch();

	const handleCreateOrUpdate = useCallback(
		(historyData) => {
			dispatch(createOrUpdateRecentHistoryApi(historyData));
		},
		[dispatch],
	);

	useEffect(() => {
		const data = content?.props?.ChartDetailView?.data;
		if (data && open) {
			const chartId = data?.id || data?._id;
			handleCreateOrUpdate({ userId, chartId, projectId, isActive: true, timeZone: "UTC" });
		}
	}, [open, content, handleCreateOrUpdate, userId, projectId]);

	return (
		<Modal
			keyboard={false}
			closable={false}
			open={open}
			width={screenResolutions[width]}
			title={
				<div className="flex items-center justify-between">
					<span>{title}</span>
					<CloseOutlined className="cursor-pointer text-xl" onClick={onCancel} />
				</div>
			}
			centered
			footer={[
				<div key="footer-details" className="flex w-full items-center justify-between">
					{onCancelTitle && (
						<Button type="primary" danger disabled={isDcSubmitChart ? true : false} onClick={onCancel}>
							{onCancelTitle}
						</Button>
					)}
					{onSubmitTitle && (
						<Button
							loading={isDcSubmitChart ? isDcSubmitChart : null}
							className="bg-blue-500"
							type="primary"
							key="submit"
							htmlType="submit"
							disabled={onSubmitDisabled ? onSubmitDisabled : false}
							onClick={onSubmit}
						>
							{onSubmitTitle}
						</Button>
					)}
				</div>,
			]}
		>
			<div className="h-full w-full">{content}</div>
		</Modal>
	);
};

export default ModalBox;
