import { createSlice } from "@reduxjs/toolkit";
import {
	deleteScheduleApi,
	getScheduleListApi,
	getScheduleListPaginationApi,
	scheduleSendApi,
	updateScheduleApi,
} from "./reportRepositoryService.js";
const initialState = {
	reportRepositoryList: [],
	reportRepositoryListPagination: [],
	pagination: {
		currentPage: 1,
		pageSize: 10,
		totalPages: 0,
		totalRecords: 0,
	},
	loading: "idle",
	currentRequestId: undefined,
	error: null,
};
export const scheduleSlice = createSlice({
	name: "reportRepository",
	initialState,
	reducers: {
		// add your non-async reducers here
		logout: (state) => {
			state.reportRepositoryList = [];
			state.reportRepositoryListPagination = [];
			state.loading = "idle";
			state.currentRequestId = undefined;
			state.error = null;
			state.pagination = {
				currentPage: 1,
				pageSize: 10,
				totalPages: 0,
				totalRecords: 0,
			};
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(scheduleSendApi.pending, (state, action) => {
				if (state.loading === "idle") {
					state.loading = "pending";
					state.currentRequestId = action.meta.requestId;
				}
			})
			.addCase(scheduleSendApi.fulfilled, (state, action) => {
				const { requestId } = action.meta;
				if (state.loading === "pending" && state.currentRequestId === requestId) {
					state.loading = "idle";
					state.reportRepositoryList.unshift(action.payload);
					state.currentRequestId = undefined;
				}
			})
			.addCase(scheduleSendApi.rejected, (state, action) => {
				const { requestId } = action.meta;
				if (state.loading === "pending" && state.currentRequestId === requestId) {
					state.loading = "idle";
					// state.error = action.error;
					state.currentRequestId = undefined;
					if (action.payload) {
						state.error = action.payload.errorMessage;
					} else {
						state.error = action.error.message;
					}
				}
			});

		// getScheduleListPaginationApi

		builder
			.addCase(getScheduleListPaginationApi.pending, (state, action) => {
				state.loading = "pending";
				state.currentRequestId = action.meta.requestId;
			})
			.addCase(getScheduleListPaginationApi.fulfilled, (state, action) => {
				const { requestId } = action.meta;
				if (state.loading === "pending" && state.currentRequestId === requestId) {
					state.loading = "idle";
					state.reportRepositoryListPagination = action.payload.reports;
					state.pagination = action.payload.pagination;
					state.currentRequestId = undefined;
				}
			})
			.addCase(getScheduleListPaginationApi.rejected, (state, action) => {
				const { requestId } = action.meta;
				if (state.loading === "pending" && state.currentRequestId === requestId) {
					state.loading = "idle";
					// state.error = action.error;
					state.currentRequestId = undefined;
					if (action.payload) {
						state.error = action.payload.errorMessage;
					} else {
						state.error = action.error.message;
					}
				}
			});
		builder
			.addCase(getScheduleListApi.pending, (state, action) => {
				if (state.loading === "idle") {
					state.loading = "pending";
					state.currentRequestId = action.meta.requestId;
				}
			})
			.addCase(getScheduleListApi.fulfilled, (state, action) => {
				const { requestId } = action.meta;
				if (state.loading === "pending" && state.currentRequestId === requestId) {
					state.loading = "idle";
					state.reportRepositoryList.length = 0;
					state.reportRepositoryList.push(...action.payload);
					state.currentRequestId = undefined;
				}
			})
			.addCase(getScheduleListApi.rejected, (state, action) => {
				const { requestId } = action.meta;
				if (state.loading === "pending" && state.currentRequestId === requestId) {
					state.loading = "idle";
					// state.error = action.error;
					state.currentRequestId = undefined;
					if (action.payload) {
						state.error = action.payload.errorMessage;
					} else {
						state.error = action.error.message;
					}
				}
			});
		builder
			.addCase(updateScheduleApi.pending, (state, action) => {
				if (state.loading === "idle") {
					state.loading = "pending";
					state.currentRequestId = action.meta.requestId;
				}
			})
			.addCase(updateScheduleApi.fulfilled, (state, action) => {
				const { requestId } = action.meta;
				if (state.loading === "pending" && state.currentRequestId === requestId) {
					const updateItem = action.payload;

					const index = state.reportRepositoryList.findIndex((item) => item.id === updateItem.id);
					if (index > -1) {
						state.reportRepositoryList[index] = updateItem;
					}
					state.loading = "idle";
					state.currentRequestId = undefined;
				}
			})
			.addCase(updateScheduleApi.rejected, (state, action) => {
				const { requestId } = action.meta;
				if (state.loading === "pending" && state.currentRequestId === requestId) {
					state.loading = "idle";
					// state.error = action.error;
					state.currentRequestId = undefined;
					if (action.payload) {
						state.error = action.payload.errorMessage;
					} else {
						state.error = action.error.message;
					}
				}
			});
		builder
			.addCase(deleteScheduleApi.pending, (state, action) => {
				if (state.loading === "idle") {
					state.loading = "pending";
					state.currentRequestId = action.meta.requestId;
				}
			})
			.addCase(deleteScheduleApi.fulfilled, (state, action) => {
				const { requestId } = action.meta;
				if (state.loading === "pending" && state.currentRequestId === requestId) {
					const index = state.reportRepositoryList.findIndex((item) => item.id === action.payload?.result?.id);

					if (index > -1) {
						state.reportRepositoryList.splice(index, 1);
					}
					state.loading = "idle";
					state.currentRequestId = undefined;
				}
			})
			.addCase(deleteScheduleApi.rejected, (state, action) => {
				const { requestId } = action.meta;
				if (state.loading === "pending" && state.currentRequestId === requestId) {
					state.loading = "idle";
					// state.error = action.error;
					state.currentRequestId = undefined;
					if (action.payload) {
						state.error = action.payload.errorMessage;
					} else {
						state.error = action.error.message;
					}
				}
			});
	},
});
export const { logout } = scheduleSlice.actions;

export default scheduleSlice.reducer;
