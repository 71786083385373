import { createAsyncThunk } from "@reduxjs/toolkit";
import {
	addProject,
	deleteProject,
	getProjects,
	getProjectsPagination,
	updateProject,
} from "../../api/project/project";

export const getProjectsApi = createAsyncThunk("project/get", async (data, { rejectWithValue }) => {
	try {
		const response = await getProjects();

		return response.data;
	} catch (error) {
		if (!error.response) {
			throw error;
		}
		return rejectWithValue(error.response.data);
	}
});

export const getProjectsPaginationApi = createAsyncThunk(
	"project/getPagination",
	async ({ page = 1, pageSize = 10 }, { rejectWithValue }) => {
		try {
			const response = await getProjectsPagination({ page, pageSize });

			return response.data;
		} catch (error) {
			if (!error.response) {
				throw error;
			}
			return rejectWithValue(error.response.data);
		}
	},
);

export const createProjectApi = createAsyncThunk("project/create", async (data, { rejectWithValue }) => {
	try {
		const response = await addProject(data);
		return response.data;
	} catch (error) {
		if (!error.response) {
			throw error;
		}
		return rejectWithValue(error.response.data);
	}
});

export const updateProjectApi = createAsyncThunk("project/update", async (data, { rejectWithValue }) => {
	const { updateId, items } = data;
	try {
		const response = await updateProject(updateId, items);
		return response.data;
	} catch (error) {
		if (!error.response) {
			throw error;
		}
		return rejectWithValue(error.response.data);
	}
});

export const deleteProjectApi = createAsyncThunk("project/delete", async (deleteId, { rejectWithValue }) => {
	try {
		const { status } = await deleteProject(deleteId);
		if (status === 202) return deleteId;
	} catch (error) {
		if (!error.response) {
			throw error;
		}
		return rejectWithValue(error.response.data);
	}
});
