import api from "../../api";

const baseUrl = "/api/chartSearchHistory";

export const getSearchHistory = (userId) => {
	return api.get(baseUrl, {
		params: { userId },
		withCredentials: true, // Ensure it is inside the second object
	});
};

export const saveSearchToHistory = (userId, searchQuery) => {
	return api.post(
		`${baseUrl}/saveSearch`,
		{
			userId,
			searchQuery,
		},
		{
			withCredentials: true, // Include cookies if required by the server
		},
	);
};
