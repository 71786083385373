import { EditOutlined, PieChartOutlined, RightOutlined, SearchOutlined } from "@ant-design/icons";
import { Avatar, Breadcrumb, Button, Dropdown, Image, Layout, Menu, Select, Tooltip, Upload, notification, Spin} from "antd";
/* eslint-disable jsx-a11y/alt-text */
import { Suspense, lazy, useCallback, useEffect, useMemo, useState } from "react";
import { Link, Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";

import { HomeOutlined } from "@ant-design/icons";
import { DownOutlined } from "@ant-design/icons";
import { ToolOutlined } from "@ant-design/icons";
import { AiOutlineFileSearch } from "react-icons/ai";
import { CgPathExclude } from "react-icons/cg";
import { FaQuestion } from "react-icons/fa";
import { FiUserCheck } from "react-icons/fi";
import { IoIosHelpCircleOutline } from "react-icons/io";
import { LuCalculator, LuGanttChartSquare } from "react-icons/lu";
import { MdOutlineBook } from "react-icons/md";
import { RiGuideLine } from "react-icons/ri";
import { RiFeedbackLine } from "react-icons/ri";
// import Profile from "./Profile/Profile";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../assets/svg_images/BM.svg";
import AntFooter from "../../components/AntFooter.js";
import InactivityProvider from "../../components/Inactivity/InactivityProvider.js";
import ModalBox from "../../components/ModalBox";
import { globalHeaderHeight } from "../../constants/constants.js";
import ProtectorRoute from "../../utils/ProviderProtectedRoute/ProviderProtectorRoute.js";
import UpdatePatient from "../Chart/UpdatePatient.js";
import Dashboard from "../CommonDashboard/Dashboard";
import Home from "../CommonHome/Home.js";
import Notification from "../Notification/Notification.js";
import ProviderDirectoryTabs from "../UserDirectoryTabs/ProviderDirectoryTabs.js";
import { RecentHistory } from "./RecentHistory.js";
import { GrSearchAdvanced } from "react-icons/gr";

import { AiOutlineInteraction } from "react-icons/ai";
import { FaRegFile } from "react-icons/fa";
import { FaRegUser } from "react-icons/fa";
import { IoSettingsOutline } from "react-icons/io5";
import { IoNotificationsOutline } from "react-icons/io5";
import { IoTimeOutline } from "react-icons/io5";
import { MdOutlineScheduleSend } from "react-icons/md";
import useResponsiveState from "../../hooks/useResponsiveState.js";
import AppGuard from "../AppGuard/AppGuard.js";
import { getSearchHistoryApi, saveSearchToHistoryApi } from "../../redux/chartSearchHistory/chartSearchHistoryService.js";
import useSearchBasedCharts from "../../pagination/useSearchBasedCharts.js";
import { debounce } from "lodash";
import ChartSearchResultsTable from "../ChartSearchResults/ChartSearchResultsTable.js";

const { Header, Sider, Content } = Layout;

const Chart = lazy(() => import("../Admin/Chart/Chart.js"));
const Batch = lazy(() => import("../SuperAdmin/Batch/Batch"));
const Document = lazy(() => import("../Admin/Document/Document"));
const Exclusion = lazy(() => import("../SuperAdmin/ExclusionList/Exclusion.js"));
const UserGuide = lazy(() => import("../UserGuide/UserGuide.js"));
const GlossaryTermsComponent = lazy(() => import("./GlossaryTermsComponent.js"));
const FAQComponent = lazy(() => import("./FAQComponent.js"));
// Lazy-load the BarCharts component with a delay of 20 minutes
// const Document = React.lazy(() =>
//   new Promise((resolve) => {
//     setTimeout(() => {
//       resolve(import("../Admin/Document/Document")); // Dynamically import the component after 20 minutes
//     }, 1 * 60 * 1000); // 20 minutes in milliseconds (1,200,000 ms)
//   })
// );
const Profile = lazy(() => import("./Profile/Profile"));
const ExclusionTableView = lazy(() => import("../SuperAdmin/ExclusionList/ExclusionTableView.js"));
const DMEPaymentCalculator = lazy(() => import("../../components/PaymentCalculator.js"));
const RepositoryDirectoryTabs = lazy(() => import("../CommonReportRepository/RepositoryDirectoryTabs.js"));
const PhysicianDetails = lazy(() => import("../../components/PhysicainDetails"));
const PhysicianNpiLookup = lazy(() => import("../../components/PhysicianNpiLookup.js"));
const NotificationCenter = lazy(() => import("../Notification/NotificationCenter.js"));
const FeedbackRedirect = lazy(() => import("./FeedBack.js"));

const layoutStyle = {
	minHeight: "100vh",
	overflowY: "hidden",
};

const siderStyle = {
	background: "white",
	padding: "16px 0",
	height: "100vh",
	overflow: "auto",
};

const headerStyle = {
	padding: "0 16px",
	background: "white",
	display: "flex",
	justifyContent: "space-between",
	alignItems: "center",
	color: "#000",
	height: globalHeaderHeight,
};

const contentStyle = {
	margin: "16px",
	padding: "8px",
	background: "white",
	color: "#fff",
	borderRadius: "8px",
	flexGrow: 1,
	overflow: "hidden",
	border: "1px solid #E6E6E6",
};

const toolTipPosition = "rightTop";

const ProviderIndex = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	// const isMobile = !screens.md; // Checks if the screen size is below medium breakpoint
	// const [collapsed, setCollapsed] = useState(true);
	const [logOut, SetLogOut] = useState(false);
	const [tabName, setTabName] = useState("Users");
	const [reportTabName, setReportTabName] = useState("Report Viewer & Scheduler");
	const { firstName, lastName, projectName, role, userAccess, avatarUrl } = useSelector((state) => state.userDetails);
	const [editPatientData, setEditPatientData] = useState("");

	const { isMobile, collapsed, setCollapsed } = useResponsiveState();
	const [open, setOpen] = useState(false);
	// const [breadcrumbItems, setBreadcrumbItems] = useState([]);

	const roleMapping = {
		PROVIDER: "Client Administrator",
		USER: `${userAccess === "VIEW" ? "Visitor Viewer" : "Report Manager"}`,
		DOWNLOAD: "Report Manager",
	};

	const getMappedRoleName = (name) => roleMapping[name] || name;

	const toggleCollapsed = () => {
		setCollapsed(!collapsed);
	};

	const [iconVisible, setIconVisible] = useState(false);
	const { providerList } = useSelector((state) => state.provider);
	const project = useSelector((state) => state.userDetails.project);
	const userId = useSelector((state) => state.userDetails.userId);

	// const [fileList, setFileList] = useState([]);
	const [previewOpen, setPreviewOpen] = useState(false);
	const [previewImage, setPreviewImage] = useState("");
	const [editPatientViewStatus, setEditPatientViewStatus] = useState(false);
	const { chartList } = useSelector((state) => state.chart);
	const [selectedValue, setSelectedValue] = useState([]);
	const [isSearching, setIsSearching] = useState(false);
	const [abortController] = useState(null);
	const searchHistory = useSelector((state) => state.searchHistory.data || []);
	const [isPaste, setIsPaste] = useState(false);

	const {
		charts: searchResults,
		searchTerm,
		setSearchTerm,
		chartsLoading,
		setFilters,
	} = useSearchBasedCharts({
		page: 1,
		limit: 10,
		project,
		search: "",
	});

	// Fetch search history when the component mounts
	useEffect(() => {
		if (userId) {
			dispatch(getSearchHistoryApi({ userId }))
				.unwrap()
				.catch(() => {
					notification.error({ message: "Failed to fetch search history" });
				});
		}
	}, [dispatch, userId]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const debouncedSearch = useCallback(
		debounce((value) => {
			setSearchTerm(value.trim());
			setIsSearching(true);
		}, 800),
		[],
	);

	const handleSearch = (value) => {
		if (isPaste) {
			setIsPaste(false); 
			return;
		}
		debouncedSearch(value);
	};

	const handlePaste = (e) => {
			const pastedText = e.clipboardData.getData('Text');
			setSearchTerm(pastedText);
			setSelectedValue(pastedText);

			if (pastedText.trim() && !searchHistory.some((h) => h.searchQuery === pastedText.trim())) {
				dispatch(saveSearchToHistoryApi({ userId, searchQuery: pastedText.trim() }))
					.unwrap()
					.then(() => {
						// Re-fetch search history to update options
						dispatch(getSearchHistoryApi({ userId }))
							.unwrap()
							.catch(() => {
								console.error({ message: "Failed to update search history" });
							});
					})
					.catch((error) => {
						console.error("Failed to save search to history:", error);
					});
			}

			// Update URL with the pasted value
			setTimeout(() => {
				const params = new URLSearchParams({ q: pastedText});
				navigate(`/provider/search-charts?${params.toString()}`);
				setIsPaste(true);
			}, 0);
	};

	const handleBlur = () => {
		setIsSearching(false);
	};

	const handleChange = (value) => {
		if (!value) return;

		setSelectedValue(value);

		// Find the selected result from searchResults or searchHistory
		const selectedResult =
			searchResults.find((result) => result.patientName === value) ||
			searchHistory.find((h) => h.searchQuery === value);

		if (!selectedResult) {
			setSelectedValue(null);
			return;
		}

		// Save the search to Redux if not already in history
		if (value.trim() && !searchHistory.some((h) => h.searchQuery === value.trim())) {
			dispatch(saveSearchToHistoryApi({ userId, searchQuery: value.trim() }))
				.unwrap()
				.then(() => {
					// Re-fetch search history to update options
					dispatch(getSearchHistoryApi({ userId }))
						.unwrap()
						.catch(() => {
							console.error({ message: "Failed to update search history" });
						});
				})
				.catch((error) => {
					console.error("Failed to save search to history:", error);
				});
		}

		// Navigate to the search results page
		const params = new URLSearchParams({ q: value.trim() });
		navigate(`/provider/search-charts?${params.toString()}`);

		setSearchTerm(value);
		setIsSearching(false);
		setSelectedValue(null);
	};
	const handleClear = () => {
		setSearchTerm("");
		setSelectedValue(null);
		const params = new URLSearchParams(location.search);
		params.delete("q");
		navigate(`${location.pathname}?${params.toString()}`);
		setFilters((prevFilters) => ({ ...prevFilters }));
	};

	const handleSearchResultSelect = () => {
		if (abortController) {
			abortController.abort();
		}

		// Construct query parameters
		const params = new URLSearchParams({ q: searchTerm.trim() });
		if (searchResults.length > 0) {
			navigate(`/provider/search-charts?${params.toString()}`);
		}
	};

	const handleKeyDown = (e) => {
		if (e.key === "Enter" && searchTerm.trim()) {
			handleSearchResultSelect();
			setSelectedValue(searchTerm.trim());

			// Save the search term to history
			if (searchTerm.trim() && !searchHistory.some((h) => h.searchQuery === searchTerm.trim())) {
				dispatch(saveSearchToHistoryApi({ userId, searchQuery: searchTerm.trim() }))
					.unwrap()
					.then(() => {
						// Re-fetch search history to update options
						dispatch(getSearchHistoryApi({ userId }))
							.unwrap()
							.catch(() => {
								console.error({ message: "Failed to update search history" });
							});
					})
					.catch((error) => {
						console.error("Failed to save search to history:", error);
					});
			}
		}
	};

	const navigationItems = useMemo(
		() => [
			{
				key: "/provider/home",
				icon: <HomeOutlined />,
				label: (
					<>
						<Tooltip title="Access the main hub to manage tasks and navigate the system" placement={toolTipPosition}>
							<span>Home</span>
						</Tooltip>
					</>
				),
			},
			{
				key: "/provider/dashboard",
				icon: <PieChartOutlined />,
				label: (
					<>
						<Tooltip title="Access a summary of system insights and performance indicators" placement={toolTipPosition}>
							<span>Dashboard</span>
						</Tooltip>
					</>
				),
			},
			{
				key: "e&b",
				icon: <AiOutlineInteraction />,
				label: "Eligibility & Benefits",
				children: [
					{
						key: "/provider/document",
						icon: <FaRegFile />,
						label: (
							<>
								<Tooltip
									title="View a categorized collection of patient information as received"
									placement={toolTipPosition}
								>
									<span>Data Groups</span>
								</Tooltip>
							</>
						),
					},
					{
						key: "/provider/chart",
						icon: <LuGanttChartSquare />,
						label: (
							<>
								<Tooltip
									title="Access in-depth chart data, including patient metrics, equipment needs, and case details"
									placement={toolTipPosition}
								>
									<span>Charts Insights</span>
								</Tooltip>
							</>
						),
					},
					{
						key: `/provider/projectDetails/${project}`,
						icon: <AiOutlineFileSearch />,
						label: (
							<>
								<Tooltip title="Access every chart from a single, unified view." placement={toolTipPosition}>
									<span>All Chart View</span>
								</Tooltip>
							</>
						),
					},
				],
			},
			{
				key: "settings",
				icon: <IoSettingsOutline />,
				label: "Settings",
				children: [
					...(role !== "USER" && role !== "DOWNLOAD"
						? [
								{
									key: "/provider/user-directory",
									icon: <FaRegUser />,
									label: (
										<>
											<Tooltip
												title="Access the complete list of users to update profiles or control access."
												placement={toolTipPosition}
											>
												<span>User Directory</span>
											</Tooltip>
										</>
									),
								},
							]
						: []),

					{
						key: "/provider/notification",
						icon: <IoNotificationsOutline />,
						label: (
							<>
								<Tooltip title="Get notified about essential updates and system actions." placement={toolTipPosition}>
									<span> Notifications</span>
								</Tooltip>
							</>
						),
					},

					{
						key: "/provider/exclusionList",
						icon: <CgPathExclude />,
						label: (
							<>
								<Tooltip
									title="Manage payers excluded from the facility due to non-payment or other specified reasons"
									placement={toolTipPosition}
								>
									<span> Payer Exclusion List</span>
								</Tooltip>
							</>
						),
					},
					{
						key: "/provider/repository-directory",
						icon: <MdOutlineScheduleSend />,
						label: (
							<>
								<Tooltip title="View, manage, and download all saved reports in one place" placement={toolTipPosition}>
									<span> Report Repository</span>
								</Tooltip>
							</>
						),
					},
					{
						key: "/provider/2fa",
						icon: <EditOutlined />,
						label: (
							<>
								<Tooltip title="Get notified about essential updates and system actions." placement={toolTipPosition}>
									<span> Edit Profile</span>
								</Tooltip>
							</>
						),
					},
					{
						key: "/provider/recentHistory",
						icon: <IoTimeOutline />,
						label: (
							<>
								<Tooltip
									title="Access detailed records of activities logged over the last 7 days."
									placement={toolTipPosition}
								>
									<span> Recent History</span>
								</Tooltip>
							</>
						),
					},
				],
			},
			{
				key: "tools",
				icon: <ToolOutlined />,
				label: "Tools",
				children: [
					{
						key: "/provider/paymentCalculator",
						icon: <LuCalculator />,
						label: (
							<>
								<Tooltip
									title="Estimate out-of-pocket costs by factoring in deductibles, copays, and coinsurance"
									placement={toolTipPosition}
								>
									<span> Payment Calculator</span>
								</Tooltip>
							</>
						),
					},
					{
						key: "/provider/npiValidation",
						icon: <FiUserCheck />,
						label: (
							<>
								<Tooltip
									title="Validate NPI numbers using real-time data from the official NPPES system"
									placement={toolTipPosition}
								>
									<span> NPI Validation</span>
								</Tooltip>
							</>
						),
					},
				],
			},
			{
				icon: <IoIosHelpCircleOutline />,
				label: "Help Center",
				children: [
					{
						key: "/provider/faq",
						icon: <FaQuestion />,
						label: (
							<>
								<Tooltip title="Access helpful FAQs to navigate the system with ease." placement={toolTipPosition}>
									<span> FAQ</span>
								</Tooltip>
							</>
						),
					},
					{
						key: "/provider/glossary",
						icon: <MdOutlineBook />,
						label: (
							<>
								<Tooltip
									title="Access a comprehensive list of terms and definitions relevant to the system"
									placement={toolTipPosition}
								>
									<span> Glossary</span>
								</Tooltip>
							</>
						),
					},
					{
						key: "/provider/userGuide",
						icon: <RiGuideLine />,
						label: (
							<>
								<Tooltip
									title="Access the user guide for navigation tips and system usage instructions."
									placement={toolTipPosition}
								>
									<span> User Guide</span>
								</Tooltip>
							</>
						),
					},
					{
						key: "/provider/feedBack",
						icon: <RiFeedbackLine />,
						label: (
							<>
								<Tooltip
									title="Help us improve by sharing your feedback and recommending changes"
									placement={toolTipPosition}
								>
									<span> Feedback & Suggestions</span>
								</Tooltip>
							</>
						),
					},
				],
			},
		],
		[project, role],
	);

	const fileList = avatarUrl
		? [
				{
					uid: "-1",
					name: "profile.png",
					status: "done",
					url: avatarUrl,
				},
			]
		: [];

	const breadcrumbItems = useMemo(() => {
		const pathnames = location.pathname?.split("/")?.filter(Boolean);

		return pathnames
			?.map((_, index) => {
				const fullPath = `/${pathnames?.slice(0, index + 1)?.join("/")}`;

				if (fullPath === "/provider/document") {
					return null; // Exclude this breadcrumb
				}

				// Check for exact match in navigationItems
				let breadcrumb = navigationItems.find((item) => item?.key === fullPath);

				if (!breadcrumb) {
					// Handle dynamic or nested routes
					navigationItems.forEach((item) => {
						if (item.children) {
							const child = item?.children?.find((child) => child?.key === fullPath);
							if (child) breadcrumb = child;
						}
					});
				}

				if (breadcrumb) {
					return {
						key: fullPath,
						title: (
							<Link to={fullPath} style={{ display: "flex", alignItems: "center" }}>
								{breadcrumb.icon}
								{breadcrumb.label && <span style={{ marginLeft: 8 }}>{breadcrumb.label}</span>}
								{/* <span style={{ marginLeft: 8 }}>{breadcrumb.label}</span> */}
							</Link>
						),
					};
				}

				// Handle specific cases for tabs or dynamic titles
				if (fullPath.includes("/repository-directory")) {
					return {
						key: fullPath,
						title: reportTabName,
					};
				}

				if (fullPath.includes("/user-directory")) {
					return {
						key: fullPath,
						title: tabName,
					};
				}

				return null; // Exclude unmatched paths
			})
			.filter(Boolean); // Exclude null values
	}, [location.pathname, navigationItems, reportTabName, tabName]);

	const menuItems = useMemo(
		() => [
			{
				key: "1",
				label: (
					<div
						className="rounded-xl bg-white p-2 text-center font-plus-jakarta-sans font-semibold text-[#458FF6] text-xs shadow"
						onClick={() => navigate("/provider/2fa")}
					>
						Edit Profile{" "}
					</div>
				),
			},

			{
				key: "2",
				label: (
					<div
						className="rounded-xl bg-[#458FF6] p-2 text-center font-plus-jakarta-sans font-semibold text-white text-xs"
						onClick={() => SetLogOut(true)}
					>
						Log Out
					</div>
				),
			},
		],
		[navigate],
	);

	const handleCancel = () => {
		SetLogOut(false);
	};

	const handleLogOutFinish = async () => {
		try {
			const { getLogOut } = await import("../../api/logout/logOut.js");
			getLogOut();
			const { clearUserDetails } = await import("../../redux/userDetails/userDetailsSlice");
			dispatch(clearUserDetails());
			navigate("/provider");
		} catch (error) {
			// Optional: Show a notification or alert to the user
			notification.info({
				message: "You have been logged out",
				description: "Please sign in again to continue.",
				placement: "topRight",
				duration: 2, // Display notification for 3 seconds
			});
			navigate("/provider");
		}
	};

	const handlePreview = async (file) => {
		setPreviewImage(file?.url || file?.preview);
		setPreviewOpen(true);
	};

	// const handleMouseEnter = () => setIconVisible(true)
	// const handleMouseLeave = () => setIconVisible(false)

	const onMenuClick = useCallback(
		({ key }) => {
			if (key !== location?.pathname) {
				navigate(key); // Navigate only if the selected key differs from the current path
			}
		},
		[location?.pathname, navigate],
	);

	return (
		<Layout style={layoutStyle}>
			<Sider
				collapsed={collapsed}
				onCollapse={toggleCollapsed}
				breakpoint="md"
				collapsedWidth={isMobile ? 0 : 80}
				width={250}
				style={siderStyle}
			>
				<div className="relative left-[1.2vw] flex items-center justify-start ">
					<img className={`mx-1 h-auto w-9`} src={logo} alt="Logo" />
					{!collapsed && <div className="mx-1 text-nowrap font-bold font-poppins">MYWWS</div>}
				</div>
				<Menu
					className="mt-3 bg-white"
					selectedKeys={[
						location?.pathname.startsWith("/provider/repository-directory/") ||
						location?.pathname.startsWith("/provider/user-directory/")
							? location?.pathname.startsWith("/provider/repository-directory/")
								? "/provider/repository-directory"
								: "/provider/user-directory"
							: location?.pathname, // Dynamically set selected key
					]}
					mode="inline"
					items={navigationItems}
					onClick={onMenuClick} // Handle navigation on click
					style={{ border: "none" }}
				/>
			</Sider>
			<Layout>
				<Header style={headerStyle}>
					<div className="flex items-center">
						{collapsed ? (
							<RightOutlined
								className=" mt-3 rotate-180 transform text-black transition-transform "
								onClick={() => setCollapsed(false)}
								style={{ fontSize: "100%", fontWeight: "medium" }}
							/>
						) : (
							<RightOutlined
								className=" mt-3 transform text-black transition-transform"
								onClick={() => setCollapsed(true)}
								style={{ fontSize: "100%", fontWeight: "medium" }}
							/>
						)}
					</div>
					<div className="mt-3 flex w-full items-center p-16">
						<Select
							className="w-full"
							showSearch
							allowClear
							value={selectedValue || searchTerm || undefined}
							placeholder={<span className="font-poppins text-xs">Search Charts</span>}
							defaultActiveFirstOption={false}
							suffixIcon={<SearchOutlined />}
							filterOption={false}
							onBlur={handleBlur}
							onPaste={handlePaste}
							onSearch={handleSearch}
							onChange={handleChange}
							onClear={handleClear}
							onKeyDown={handleKeyDown}
							dropdownRender={(menu) => (
								<>
									{menu}
									<Tooltip title="Advanced Search">
										<div
											className="ml-auto flex w-5 cursor-pointer items-center justify-end"
											onClick={() => navigate("/provider/search-charts")}
										>
											<GrSearchAdvanced className="mr-1 mb-1 h-4 w-5 text-blue-500" />
										</div>
									</Tooltip>
								</>
							)}
							notFoundContent={
								chartsLoading ? (
									<Spin size="small" />
								) : isSearching && searchResults.length === 0 ? (
									<div className="flex items-center justify-between">
										<span>No results found</span>
									</div>
								) : null
							}
							options={[
								...(searchTerm === ""
									? [
											// Show search history if search input is empty
											...searchHistory.map((h) => ({
												key: h.id,
												value: h.searchQuery,
												label: <span className="text-gray-500">{h.searchQuery}</span>,
											})),
										]
									: // Show search results if there is input
										(isSearching && searchResults.length > 0) && !isPaste
										? searchResults.map((d) => ({
												key: d.id,
												value: d.patientName,
												label: (
													<div className="grid grid-cols-2 grid-rows-1 gap-4">
														<div>
															<small>Name: {d.patientName || "N/A"}</small>
															<br />
															<small>Phone No: {d?.phoneMailEquipmentModels?.[0]?.phoneNumber || "N/A"}</small>
															<br />
															<small>Mail ID: {d?.phoneMailEquipmentModels?.[0]?.mailId || "N/A"}</small>
															<br />
															<small>
																HCPCS Codes: {d?.vfRequestedEquipmentModelDetails?.[0]?.hcpcsCodes || "N/A"}
															</small>
															<br />
															<small>
																Equipment Model: {d?.vfRequestedEquipmentModelDetails?.[0]?.equipmentName || "N/A"}
															</small>
														</div>
													</div>
												),
											}))
										: []),
							]}
						/>
					</div>
					<div className="flex items-center">
						{projectName ? (
							<span className="mr-6 font-bold font-poppins text-sm md:text-xs lg:text-xs">{projectName}</span>
						) : null}
						<Notification open={open} setOpen={setOpen} />
						<div onMouseEnter={() => setIconVisible(true)} onMouseLeave={() => setIconVisible(false)}>
							<Dropdown
								menu={{
									items: menuItems,
								}}
							>
								<Button style={{ display: "flex" }} type="link">
									<Avatar style={{ display: "flex", padding: "0px" }} size="small">
										{fileList?.length > 0 ? (
											<Upload
												listType="picture-circle"
												fileList={fileList}
												showUploadList={{ showRemoveIcon: false }}
												onPreview={handlePreview}
												// style={{ display: 'flex', alignItems: 'center' }}
											/>
										) : (
											firstName?.charAt(0)?.toUpperCase()
										)}
									</Avatar>
									<div className="ml-2 flex flex-col ">
										{firstName && lastName !== undefined ? (
											<span className="font-bold font-poppins text-xs">{`${firstName} ${lastName}`}</span>
										) : (
											"Loading..."
										)}
										<span className="font-poppins text-gray-500 text-xs">{getMappedRoleName(role)}</span>
									</div>
									{iconVisible ? (
										<DownOutlined
											className="ml-2 rotate-180 transform text-black transition-transform "
											style={{ fontSize: "70%", fontWeight: "medium" }}
										/>
									) : (
										<DownOutlined
											className="ml-2 transform text-black transition-transform "
											style={{ fontSize: "70%", fontWeight: "medium" }}
										/>
									)}
								</Button>
							</Dropdown>
						</div>
					</div>
				</Header>
				<Content style={contentStyle}>
					{location.pathname !== "/provider/dashboard" && (
						<Breadcrumb className="font-bold text-base text-gray-800" items={breadcrumbItems} />
					)}
					{/* {location.pathname !== "/provider/dashBoard" && <Breadcrumb style={{ margin: '10px 0' }} className="font-bold text-gray-800 text-base">
            {getBreadcrumbs()}
          </Breadcrumb>} */}
					<InactivityProvider>
						<Suspense
							fallback={
								<div className="flex h-[50%] w-full items-center justify-center">
									<div className="text-center font-poppins text-black text-opacity-100">
										<span className="inline animate-fadeIn font-poppins text-black text-md">
											Loading<p className="inline animate-pulse font-semibold text-4xl"> . . .</p>
										</span>
									</div>
								</div>
							}
						>
							<AppGuard>
								<Routes>
									<Route
										path="/home"
										element={
											<ProtectorRoute allowedRoles={["PROVIDER", "USER"]}>
												<Home />
											</ProtectorRoute>
										}
									/>
									<Route
										path="/dashBoard"
										element={
											<ProtectorRoute allowedRoles={["PROVIDER", "USER"]}>
												{" "}
												<Dashboard providerList={providerList} />
											</ProtectorRoute>
										}
									/>
									<Route
										path="user-directory/*"
										element={
											<ProtectorRoute allowedRoles={["is", "PROVIDER"]}>
												{" "}
												<ProviderDirectoryTabs isProvider={true} setTabName={setTabName} />
											</ProtectorRoute>
										}
									/>
									<Route
										path="repository-directory/*"
										element={<RepositoryDirectoryTabs setReportTabName={setReportTabName} />}
									/>
									<Route path="/2fa" element={<Profile userName={firstName} />} />
									<Route path="/document" element={<Document />} />
									<Route path="/projectDetails/:id" element={<Batch />} />
									<Route path="/chart" element={<Chart />} />
									<Route path="/exclusionList" element={<Exclusion />} />
									<Route path="exclusionList/view/:id" element={<ExclusionTableView />} />
									<Route path="/paymentCalculator" element={<DMEPaymentCalculator />} />
									<Route path="/npiValidation" element={<PhysicianNpiLookup />} />
									<Route path="/physicianDetails/:id" element={<PhysicianDetails />} />
									<Route
										path="recentHistory/"
										element={
											<RecentHistory
												setEditPatientViewStatus={setEditPatientViewStatus}
												setEditPatientData={setEditPatientData}
											/>
										}
									/>
									<Route path="*" element={<Navigate to="/" />} />
									<Route path="/notification" element={<NotificationCenter />} />
									<Route path="/faq" element={<FAQComponent />} />
									<Route path="/glossary" element={<GlossaryTermsComponent />} />
									<Route path="/userGuide" element={<UserGuide />} />
									<Route path="/feedBack" element={<FeedbackRedirect />} />
									<Route
										path="search-charts/"
										element={
											<ProtectorRoute allowedRoles={["PROVIDER", "USER", "is"]}>
												<ChartSearchResultsTable />
											</ProtectorRoute>
										}
									/>
								</Routes>
							</AppGuard>
						</Suspense>
					</InactivityProvider>
				</Content>
				<AntFooter />
			</Layout>

			{logOut && (
				<ModalBox
					open={logOut}
					onCancel={handleCancel}
					footer={null}
					content={
						<div className="ml-[10%] flex flex-col justify-center p-3">
							<span className="text-start font-poppins font-semibold text-black ">Just confirming !</span>
							<span className="text-start font-poppins text-black">are you sure you want to end your session?</span>
						</div>
					}
					onCancelTitle={"Cancel"}
					onSubmitTitle={"Confirm"}
					onSubmit={handleLogOutFinish}
				></ModalBox>
			)}
			{previewImage && (
				<Image
					wrapperStyle={{
						display: "none",
					}}
					preview={{
						visible: previewOpen,
						onVisibleChange: (visible) => setPreviewOpen(visible),
						afterOpenChange: (visible) => !visible && setPreviewImage(""),
					}}
					src={previewImage}
				/>
			)}
			{editPatientViewStatus && (
				<UpdatePatient
					editPatientViewStatus={editPatientViewStatus}
					setEditPatientViewStatus={setEditPatientViewStatus}
					editPatientData={editPatientData}
					setEditPatientData={setEditPatientData}
					chartList={chartList}
				/>
			)}
		</Layout>
	);
};

export default ProviderIndex;
