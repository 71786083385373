import { useCallback, useEffect, useState } from "react";
import { getSearchBasedChartApi } from "../api/chart/chart";

const useSearchBasedCharts = ({ initialPage = 1, initialPageSize = 10, initialFilters = {}, projectId = "" }) => {
	// const dispatch = useDispatch();
	const [charts, setCharts] = useState([]);
	const [totalCharts, setTotalCharts] = useState(0);
	const [currentPage, setCurrentPage] = useState(initialPage);
	const [pageSize, setPageSize] = useState(initialPageSize);
	const [searchTerm, setSearchTerm] = useState("");
	const [filters, setFilters] = useState(initialFilters);
	const [chartsLoading, setChartsLoading] = useState(false);
	const [error, setError] = useState(null);

	const fetchCharts = useCallback(async () => {
		if (!projectId && !searchTerm.trim() && Object.keys(filters).length === 0) {
			setCharts([]);
			setTotalCharts(0);
			return;
		}
		try {
			setChartsLoading(true);
			const response = await getSearchBasedChartApi(
				currentPage,
				pageSize,
				projectId,
				searchTerm.trim() || null,
				filters,
			);
			setCharts(response?.data?.charts || []);
			setTotalCharts(response?.data?.totalCharts || 0);
		} catch (error) {
			setError(error);
		} finally {
			setChartsLoading(false);
		}
	}, [currentPage, pageSize, projectId, searchTerm, filters]);

	useEffect(() => {
		fetchCharts();
	}, [fetchCharts]);

	return {
		charts,
		totalCharts,
		currentPage,
		pageSize,
		searchTerm,
		filters,
		chartsLoading,
		setCurrentPage,
		setPageSize,
		setSearchTerm,
		setFilters,
		setCharts,
		error,
	};
};

export default useSearchBasedCharts;
