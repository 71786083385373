import api from "../../api";

const baseUrl = "/api/physicianNpi";

// export const getPhysicianNpi = (physicianNpi) => {
//   return api.get(baseUrl, { params: { physicianNpi } });
// };

export const getPhysicianNpi = (physicianNpi) => {
	return api.get(baseUrl, {
		params: { physicianNpi },
		withCredentials: true, // Ensures credentials are included
	});
};
