import { Tooltip } from "antd";
import { memo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { axiosErrorHandling, getPathNameByNavigationLabel, getPathNameByRole } from "../../utils/utility";

const InfoCard = ({ icon, label, tooltipText = "", buttonText }) => {
	const navigate = useNavigate();
	const { role } = useSelector((state) => state.userDetails);

	const onClickLabel = async (event, label) => {
		event.preventDefault();
		try {
			const roleResponse = await getPathNameByRole(role);
			const navigationResponse = await getPathNameByNavigationLabel(label);
			const navigationURL = `/${roleResponse}/${navigationResponse}`;

			if (navigationURL) {
				navigate(navigationURL);
			} else {
				navigate("/");
			}
		} catch (error) {
			axiosErrorHandling(error);
		}
	};

	return (
		<>
			<Tooltip title={tooltipText}>
				<div
					className="flex h-[27vh] w-[95%] flex-col items-center justify-between rounded-[1.5vw] bg-blue-50 p-4"
					style={{
						boxShadow: "-8px 8px 15px rgba(0, 0, 0, 0.2)", // Shadow bottom and left
					}}
				>
					{/* Icon Section */}
					<div
						className="flex h-16 w-16 items-center justify-center rounded-full"
						style={{ backgroundColor: "#D4E7FF" }}
					>
						<span className="text-3xl">{icon}</span>
					</div>

					{/* Label Button */}
					<button
						type="button"
						className="relative w-full cursor-pointer whitespace-nowrap rounded-full border-0 bg-blue-bm px-4 py-2 font-medium text-white text-xs shadow-none transition-all duration-100 hover:bg-blue-400"
						onClick={(e) => onClickLabel(e, label)}
					>
						{buttonText}
					</button>
				</div>
			</Tooltip>
		</>
	);
};

export default memo(InfoCard);
