import { Col, Form, Input, Row, Select } from "antd";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { runes } from "runes2";
import ModalBox from "../../components/ModalBox";
import { setLoading } from "../../redux/loading/loadingSlice";
import { updateProviderApi } from "../../redux/provider/providerService";
import { axiosErrorHandling, compareTwoObjects, isEmptyObject, isObject, trimObjValues } from "../../utils/utility";

const roleMapping = [
	{ value: "VIEW", label: "Visitor Viewer" },
	{ value: "DOWNLOAD", label: "Report Manager" },
];

const EditProviderUser = ({
	editStatus,
	setEditStatus,
	editData,
	setEditData,
	providerList,
	selectTimeZoneList,
	refreshUsers,
}) => {
	const dispatch = useDispatch();
	const [form] = Form.useForm();

	// const handlePasswordChange = (value) => {
	//   // Password validation checks
	//   const lowercaseRegex = /[a-z]/;
	//   const uppercaseRegex = /[A-Z]/;
	//   const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
	//   const numberRegex = /[0-9]/;

	//   // Check if all criteria are met
	//   const isValid =
	//     lowercaseRegex.test(value) &&
	//     uppercaseRegex.test(value) &&
	//     specialCharRegex.test(value) &&
	//     numberRegex.test(value) &&
	//     value.length >= 8;

	//   // Update password validity state
	//   setIsPasswordValid(isValid);
	// };

	// const validatePassword = (_, value) => {
	//   if (isPasswordValid) {
	//     return Promise.resolve();
	//   }
	//   return Promise.reject(
	//     'Password must be 8 characters or more, include at least one lowercase character, one uppercase character, one special character, and one number.'
	//   );
	// };

	const getStatusOptions = (status) => {
		if (status === "ACTIVE") {
			return [{ value: "SUSPEND", label: "SUSPEND" }];
		} else if (status === "SUSPEND") {
			return [{ value: "ACTIVE", label: "ACTIVE" }];
		}
		return [];
	};

	const getAccountUnlockOption = (accountLocked) => {
		if (accountLocked === true) {
			return [{ value: false, label: "Unlock" }];
		}
		return [];
	};

	const formatPhoneNumber = (value) => {
		// Remove all non-numeric characters
		const cleaned = value.replace(/\D/g, "");

		// Limit the length to 10 digits
		const limited = cleaned.slice(0, 10);

		// Format the phone number according to (999) 999-9999 pattern
		const match = limited.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);

		if (match) {
			const [, areaCode, centralOfficeCode, lineNumber] = match;
			if (lineNumber) {
				return `(${areaCode}) ${centralOfficeCode}-${lineNumber}`;
			} else if (centralOfficeCode) {
				return `(${areaCode}) ${centralOfficeCode}`;
			} else if (areaCode) {
				return `(${areaCode}`;
			}
		}
		return value;
	};

	const getContent = () => {
		const filterOption = (input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

		return (
			<Row gutter={16}>
				<Col xl={12} lg={12} md={12} sm={24} xs={24}>
					<Form
						layout="vertical"
						form={form}
						onFinish={onSubmitEditUser}
						initialValues={{
							...editData,
							timeZone: editData?.timeZone?.id || null,
						}}
						autoComplete="off"
					>
						<Form.Item
							className="mb-2"
							label="First Name"
							name="firstName"
							rules={[
								{ required: true, message: "Please enter the first name!" },
								{
									pattern: /^[a-zA-Z0-9_\-()' ]+$/,
									message: "Special characters other than -, _, (, ), ' and space are not allowed in names!",
								},
							]}
						>
							<Input
								count={{
									show: true,
									max: 25,
									strategy: (txt) => runes(txt).length,
									exceedFormatter: (txt, { max }) => runes(txt).slice(0, max).join(""),
								}}
							/>
						</Form.Item>
						<Form.Item
							className="mb-2"
							label="Last Name"
							name="lastName"
							rules={[
								{ required: true, message: "Please enter the last name!" },
								{
									pattern: /^[a-zA-Z0-9_\-()' ]+$/,
									message: "Special characters other than -, _, (, ), ' and space are not allowed in names!",
								},
							]}
						>
							<Input
								count={{
									show: true,
									max: 25,
									strategy: (txt) => runes(txt).length,
									exceedFormatter: (txt, { max }) => runes(txt).slice(0, max).join(""),
								}}
							/>
						</Form.Item>
						<Form.Item
							className="mb-2"
							label="Email"
							name="email"
							rules={[
								{
									type: "email",
									message: "This is not a valid email!",
								},
								{ required: true, message: "Please enter the email!" },
								{ whitespace: true },
							]}
						>
							<Input />
						</Form.Item>
						{/* <Form.Item
              className="mb-2"
              label="Password"
              name="password"
              rules={[
                { required: true, message: 'Please enter the password!' },
                { validator: validatePassword },
              ]}
            >
              <Input.Password
                onChange={(e) => handlePasswordChange(e.target.value)}
              />
            </Form.Item> */}
						<Form.Item
							className="mb-2"
							label="User Role"
							name="access"
							rules={[{ required: true, message: "Please select the user access!" }]}
						>
							<Select
								showSearch
								allowClear
								placeholder="Select user access"
								optionFilterProp="children"
								filterOption={filterOption}
								options={roleMapping}
							/>
						</Form.Item>
						<Form.Item
							className="mb-2"
							label="Status"
							name="status"
							rules={[{ required: true, message: "Please select the user status!" }]}
						>
							<Select
								showSearch
								allowClear
								placeholder="Select user status"
								optionFilterProp="children"
								filterOption={filterOption}
								options={getStatusOptions(editData.status)}
							/>
						</Form.Item>
					</Form>
				</Col>
				<Col xl={12} lg={12} md={12} sm={24} xs={24}>
					<Form layout="vertical" form={form} onFinish={onSubmitEditUser} autoComplete="off">
						<Form.Item
							className="mb-2"
							label="Professional Credentials"
							name="credentials"
							rules={[{ required: true, message: "Please enter the professional credentials!" }]}
						>
							<Input />
						</Form.Item>
						<Form.Item
							className="mb-2"
							label="Phone Number"
							name="phoneNumber"
							rules={[
								{
									required: true,
									message: 'Please enter the phone number or specify "or "N/A" or "N/A"',
								},
								{
									pattern: /^(\(\d{3}\) \d{3}-\d{4})|(U\/A)|(N\/A)$/i,
									message:
										'Please enter the phone number in the  format (e.g., (999) 153-5556), or specify "U/A" or "N/A"',
								},
							]}
						>
							<Input
								className="cursor-pointer"
								maxLength={14} // Setting the maximum length of the input field to 14 characters
								onInput={(e) => {
									e.target.value = formatPhoneNumber(e.target.value);
								}}
								onKeyPress={(e) => {
									// Allow only numbers, control keys, 'U', 'A', 'N', '/' and ' '
									if (
										!/[0-9]/.test(e.key) &&
										e.key !== "Backspace" &&
										e.key !== "Delete" &&
										e.key !== "ArrowLeft" &&
										e.key !== "ArrowRight" &&
										e.key !== "U" &&
										e.key !== "A" &&
										e.key !== "N" &&
										e.key !== "/" &&
										e.key !== " "
									) {
										e.preventDefault();
									}
								}}
							/>
						</Form.Item>
						<Form.Item
							className="mb-2"
							label="Job Role/Title"
							name="jobTitle"
							rules={[{ required: true, message: "Please enter the job title!" }]}
						>
							<Input />
						</Form.Item>
						<Form.Item
							className="mb-2"
							label="Organization Name"
							name="organizationName"
							rules={[{ required: true, message: "Please enter the organization name!" }]}
						>
							<Input />
						</Form.Item>
						<Form.Item
							className="mb-2"
							label="Time Zone"
							name="timeZone"
							rules={[{ required: true, message: "Please select the time zone!" }]}
						>
							<Select
								showSearch
								allowClear
								placeholder="Select a time zone"
								optionFilterProp="children"
								filterOption={filterOption}
								options={selectTimeZoneList}
							/>
						</Form.Item>
						{editData?.accountLocked && (
							<Form.Item
								className="mb-2"
								label="Account Lock Status"
								name="accountLocked"
								rules={[{ required: true, message: "Please select the account lock/unlock option!" }]}
							>
								<Select
									showSearch
									allowClear
									placeholder="Select Lock/Unlock"
									optionFilterProp="children"
									filterOption={filterOption}
									options={getAccountUnlockOption(editData.accountLocked)}
								/>
							</Form.Item>
						)}
					</Form>
				</Col>
			</Row>
		);
	};

	const onSubmitEditUser = async () => {
		try {
			const values = await form.validateFields();
			const items = { ...values };
			const updateId = editData.id;
			// Combine first name and last name into full name
			items.name = `${items.firstName} ${items.lastName}`;

			/* check update id */
			if (!updateId) {
				return toast.warning("Invalid update id");
			}

			/* check is object */
			if (!isObject(items)) {
				return toast.warning("This is not object data!");
			}

			/* check is empty object */
			if (isEmptyObject(items)) {
				return toast.warning("Empty object cannot accept!");
			}

			/* trim values */
			trimObjValues(items);

			/* get previous object from database */
			const findPreviousObject = _.find(providerList, { id: updateId });

			const previousObject = {
				firstName: findPreviousObject.firstName,
				lastName: findPreviousObject.lastName,
				email: findPreviousObject.email,
				// password: findPreviousObject.password,
				project: findPreviousObject?.project?.id,
				access: findPreviousObject.access,
				status: findPreviousObject.status,
				credentials: findPreviousObject?.credentials,
				phoneNumber: findPreviousObject?.phoneNumber,
				jobTitle: findPreviousObject?.jobTitle,
				organizationName: findPreviousObject?.organizationName,
				timeZone: findPreviousObject?.timeZone?.id,
				accountLocked: findPreviousObject?.accountLocked,
			};

			/* selected data */

			const currentObject = {
				firstName: items.firstName,
				lastName: items.lastName,
				email: items.email,
				// password: items.password,
				project: findPreviousObject?.project?.id,
				access: items.access,
				status: items.status,
				credentials: items?.credentials,
				phoneNumber: items?.phoneNumber,
				jobTitle: items?.jobTitle,
				organizationName: items?.organizationName,
				timeZone: items?.timeZone,
				accountLocked: items.status === "ACTIVE" ? false : true,
			};

			/* compare two objects for same or not returns boolean value */
			const compareResult = compareTwoObjects(previousObject, currentObject);

			const emailResult = providerList.filter((item) => {
				return item["email"].toString().toLowerCase() === items.email.toString().toLowerCase();
			});

			if (compareResult) {
				toast.info("No changes detected, update skipped!");
				setEditStatus(false);
				setEditData({});
				return;
			}

			const previousEmail = findPreviousObject.email;
			const updateEmail = currentObject.email;

			if (previousEmail.toLowerCase() !== updateEmail.toLowerCase()) {
				if (emailResult.length > 0) {
					return toast.warning("The email already exists!");
				}
			}

			if (!items.accountLocked) {
				items.attempts = 0;
				items.lockDate = null;
			}
			setEditStatus(false);
			dispatch(setLoading(true));
			items.project = editData?.project?.id;
			// Remove the password key
			delete items.password;

			await dispatch(updateProviderApi({ updateId, items })).unwrap();
			await refreshUsers();
			dispatch(setLoading(false));
			toast.success("User updated successfully!");
			setEditData({});
		} catch (error) {
			dispatch(setLoading(false));
			return axiosErrorHandling(error);
		}
	};
	return (
		<>
			{editStatus && (
				<ModalBox
					open={editStatus}
					title="Modify User Information"
					width="md"
					content={getContent()}
					onSubmitTitle="Update"
					onSubmit={onSubmitEditUser}
					onCancelTitle="Cancel"
					onCancel={() => {
						setEditStatus(false);
					}}
				/>
			)}
		</>
	);
};

export default EditProviderUser;
