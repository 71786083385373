import { createAsyncThunk } from "@reduxjs/toolkit";
import { createChart, deleteChart, getCharts, getTodayCharts, updateChart } from "../../api/chart/chart";

export const getChartsApi = createAsyncThunk("chart/get", async (data, { rejectWithValue }) => {
	try {
		const response = await getCharts();
		return response.data;
	} catch (error) {
		if (!error.response) {
			throw error;
		}
		return rejectWithValue(error.response.data);
	}
});

export const getTodayChartsApi = createAsyncThunk("chart/get", async (data, { rejectWithValue }) => {
	try {
		const response = await getTodayCharts();
		return response.data;
	} catch (error) {
		if (!error.response) {
			throw error;
		}
		return rejectWithValue(error.response.data);
	}
});

export const createChartApi = createAsyncThunk("chart/create", async (data, { rejectWithValue }) => {
	try {
		const response = await createChart(data);
		return response.data;
	} catch (error) {
		if (!error.response) {
			throw error;
		}
		return rejectWithValue(error.response.data);
	}
});

export const updateChartApi = createAsyncThunk("chart/update", async (data, { rejectWithValue }) => {
	const { updateId, items } = data;
	try {
		const response = await updateChart(updateId, items);
		return response.data;
	} catch (error) {
		if (!error.response) {
			throw error;
		}
		return rejectWithValue(error.response.data);
	}
});

export const deleteChartApi = createAsyncThunk("chart/delete", async (deleteId, { rejectWithValue }) => {
	try {
		const { status } = await deleteChart(deleteId);
		if (status === 202) return deleteId;
	} catch (error) {
		if (!error.response) {
			throw error;
		}
		return rejectWithValue(error.response.data);
	}
});
