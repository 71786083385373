import { createAsyncThunk } from "@reduxjs/toolkit";
import {
	addChartDocument,
	deleteChartDocument,
	getChartDocuments,
	updateChartDocument,
} from "../../api/chartDocument/chartDocument";

export const getChartDocumentsApi = createAsyncThunk("chartDocument/get", async (data, { rejectWithValue }) => {
	try {
		const response = await getChartDocuments();
		return response.data;
	} catch (error) {
		if (!error.response) {
			throw error;
		}
		return rejectWithValue(error.response.data);
	}
});

export const createChartDocumentApi = createAsyncThunk("chartDocument/create", async (data, { rejectWithValue }) => {
	try {
		const response = await addChartDocument(data);
		return response.data;
	} catch (error) {
		if (!error.response) {
			throw error;
		}
		return rejectWithValue(error.response.data);
	}
});

export const updateChartDocumentApi = createAsyncThunk("chartDocument/update", async (data, { rejectWithValue }) => {
	const { updateId, items } = data;
	try {
		const response = await updateChartDocument(updateId, items);
		return response.data;
	} catch (error) {
		if (!error.response) {
			throw error;
		}
		return rejectWithValue(error.response.data);
	}
});

export const deleteChartDocumentApi = createAsyncThunk(
	"chartDocument/delete",
	async (deleteId, { rejectWithValue }) => {
		try {
			const { status } = await deleteChartDocument(deleteId);
			if (status === 202) return deleteId;
		} catch (error) {
			if (!error.response) {
				throw error;
			}
			return rejectWithValue(error.response.data);
		}
	},
);
