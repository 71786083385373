import { createContext, useContext } from "react";
import { idealLogOutTime } from "../../api";
import useIdleTimer from "../../utils/useIdleTimer"; // Adjust the path based on your project structure

const IdleContext = createContext();

export const useIdle = () => {
	return useContext(IdleContext);
};

const IdleTimerProvider = ({ children }) => {
	const isIdle = useIdleTimer(idealLogOutTime || 1000 * 60 * 15); //default log out time is 15 minutes

	return <IdleContext.Provider value={isIdle}>{children}</IdleContext.Provider>;
};

export default IdleTimerProvider;
